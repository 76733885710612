import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@common/components/';
import PageHeader from '@icoach/components/layout/PageHeader';
import TabsWithActions from '@icoach/components/layout/TabsWithActions';

const PageLayout = ({ className, children, ...rest }) => {
    return (
        <Box className={clsx('container main-container-spacing', className)} {...rest}>
            {children}
        </Box>
    );
};

PageLayout.Header = PageHeader;
PageLayout.Tabs = TabsWithActions;

PageLayout.prototype = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default PageLayout;
