import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStepperContext } from '../stepper/StepperContext';
import { useStepContext } from '../step/StepContext';

const StepConnector = forwardRef((props, ref) => {
    const { className, ...rest } = props;
    const { alternativeLabel, orientation = 'horizontal' } = useStepperContext();
    const { active, disabled, completed } = useStepContext();

    const rootClasses = clsx(
        'step-connector',
        {
            'step-connector--active': active,
            'step-connector--disabled': disabled,
            'step-connector--completed': completed,
            'step-connector--alternative-label': alternativeLabel,
        },
        className,
    );

    const stepConnectorLineClasses = clsx('step-connector__line', {
        'step-connector__line--horizontal': orientation === 'horizontal',
        'step-connector__line--vertical': orientation === 'vertical',
    });

    return (
        <div className={rootClasses} {...rest}>
            <span className={stepConnectorLineClasses} />
        </div>
    );
});

StepConnector.propTypes = {
    className: PropTypes.string,
    sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
};

export default StepConnector;
