import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from '@common/components/';
import { getVersionData } from '@redux/version/selector';
import useAuthUserApi from '@apis/useAuthUserApi';
import useAuthApi from '@apis/useAuthApi';
import { parseDate } from '@util/moment';
import { isEmpty, localUser } from '@util/utils';

const NavBottom = React.memo(() => {
    const versionData = useSelector(getVersionData);
    const [version, setVersion] = useState('');
    const { deleteEmployeeAuthPwdApi } = useAuthUserApi();
    const { getLogoutApi } = useAuthApi();
    const employee = localUser.get('employee');
    const handleLogoutClick = (e) => {
        logOutApi();
    };

    const logOutApi = async () => {
        if (!isEmpty(employee)) {
            await deleteEmployeeAuthPwdApi(employee.token);
        } else {
            await getLogoutApi();
        }
    };

    useEffect(
        () => {
            if (!isEmpty(versionData)) {
                const { env, version, utc } = versionData || {};
                const text = `${env} :: ${version} | ${parseDate(utc, 'YYYY-MM-DD HH:mm:ss')} `;
                setVersion(text);
            }
        },
        // eslint-disable-next-line
        [versionData]
    );

    return (
        <div className="nav-bottom">
            <Tooltip title={version} disableFocusListener>
                <Button variant="contained" size="large" onClick={handleLogoutClick} square>
                    {isEmpty(employee) ? `登出` : `切換使用者`}
                </Button>
            </Tooltip>
        </div>
    );
});

export default NavBottom;
