import React from 'react';
import { Grid } from '@mui/material';
import { MenuItem, SelectField, TextField } from '@common/components/';
import {
    moreSheet,
    contactSheet,
    rowLabelCheckboxSheet,
    SpecialConditionBox,
    getMemberSheet,
    getCellPhoneSheet,
} from '@icoach/components/';
import { refIsRequiredError, isNumber, findIndexFn } from '@util/utils';

export const FilterHundredTShirtSearch = React.forwardRef((props, ref) => {
    const { resource = {}, paramsData } = props;
    const { hundredTShirtSizeTextOption = [] } = resource;
    const timesRef = React.useRef(null);
    const hundredTShirtTypeRef = React.useRef(null);
    const hundredTShirtSizeRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            isError: () => refIsRequiredError(timesRef, hundredTShirtTypeRef, hundredTShirtSizeRef),
            getResult: () => {
                let result = {};
                if (timesRef.current && timesRef.current.getResult) {
                    result = Object.assign(result, {
                        times: parseInt(timesRef.current.getResult(), 10) || 0,
                    });
                }
                if (hundredTShirtTypeRef.current && hundredTShirtTypeRef.current.getResult) {
                    result = Object.assign(result, {
                        hundredTShirtType: hundredTShirtTypeRef.current.getResult() || 0,
                    });
                }
                if (hundredTShirtSizeRef.current && hundredTShirtSizeRef.current.getResult) {
                    result = Object.assign(result, {
                        hundredTShirtSize: hundredTShirtSizeRef.current.getResult(),
                    });
                }
                return result;
            },
        };
    });

    const times = isNumber(paramsData.times) ? paramsData.times * 1 : 20;
    const hundredTShirtType = paramsData.hundredTShirtType;
    const hundredTShirtSize = findIndexFn(hundredTShirtSizeTextOption, 'value', paramsData.hundredTShirtSize) !== -1 ? paramsData.hundredTShirtSize : 99;

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <TextField
                    ref={timesRef}
                    type={'number'}
                    label={'距離滿百次數'}
                    defaultValue={times}
                    inputProps={{ min: 1, max: 99, placeholder: '請輸入1-99的整數' }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField defaultValue={hundredTShirtType} ref={hundredTShirtTypeRef} type={'number'} label={'百T種類'} fullWidth />
            </Grid>
            <Grid item xs={2}>
                <SelectField label={'尺寸'} defaultValue={hundredTShirtSize} ref={hundredTShirtSizeRef} fullWidth>
                    {Array.isArray(hundredTShirtSizeTextOption) &&
                        hundredTShirtSizeTextOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

//********************** 百T名單 **********************//
export const getFilterHundredTShirtLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (cellValue, { memberID }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
            },
        },
        {
            cellKey: 'weeklyWorkoutQuantity',
            headerLabel: '本週WO',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'monthlyWorkoutFrequency',
            headerLabel: 'WO頻率',
            align: 'center',
        },
        {
            cellKey: 'totalWorkoutQuantity',
            headerLabel: '目前總WO次數',
            width: 15,
            align: 'center',
        },
        {
            cellKey: 'predictHundredDate',
            headerLabel: '預估滿百日期',
            width: 15,
            align: 'center',
        },
        {
            cellKey: 'hundredTShirtType',
            headerLabel: '百T種類',
            align: 'center',
        },
        {
            cellKey: 'hundredTShirtSize',
            headerLabel: '最新上衣尺寸',
            width: 15,
            align: 'center',
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
