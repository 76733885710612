import React, {useRef, useImperativeHandle, useState, useEffect, useCallback} from 'react';
import {Grid} from '@mui/material';
import {TextField, Button, Box} from '@common/components/';
import {SignBox} from '@icoach/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import {getErrorModuleStatus, isEmpty, refIsRequiredError} from '@util/utils';

const ACHCard = React.forwardRef((props, ref) => {
    const {
        className,
        defaultValue = {},
        errorModule = {},
        identifierID = 0,
        userName = '',
        isSameBtn = false,
        readOnly: readOnlyProp = false,
        isDisplaySignature = false,
        bankAccountProps = {},
    } = props;
    const setMessageDialog = useMessageDialog();
    const initAchDataRef = useRef({
        bankCode: '',
        bankName: '',
        bankAccount: '',
        bankAccountName: '',
        bankAccountID: ''
    });
    const [achInfo, setAchInfo] = useState(initAchDataRef.current);

    const bankCodeRef = useRef(null);
    const bankNameRef = useRef(null);
    const bankAccountRef = useRef(null);
    const bankAccountNameRef = useRef(null);
    const bankAccountIDRef = useRef(null);
    const signImgRef = useRef(null);

    const isClearedRef = useRef(false);

    const handleSameId = () => {
        if (!identifierID || !userName) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setAchInfo({
                bankAccountID: identifierID,
                bankAccountName: userName,
            });
        }
    };

    const handleFieldChange = useCallback((fieldName, value) => {
        setAchInfo((prev) => {
            let newState = {
                ...prev,
                [fieldName]: value
            };

            // 如果欄位被清空，則清空其他欄位
            if (!isClearedRef.current) {
                newState = {
                    ...newState,
                    bankCode: '',
                    bankAccount: '',
                    bankAccountID: '',
                    [fieldName]: value,
                };
            }
            return newState;
        });

        isClearedRef.current = true; // 在欄位被清空後，設置為已清空狀態
    }, []);

    const handleBankCodeChange = (e) => {
        handleFieldChange('bankCode', e.target.value);
    }
    const handleBankAccountChange = (e) => {
        handleFieldChange('bankAccount', e.target.value);
    }
    const handleBankAccountIDChange = (e) => {
        handleFieldChange('bankAccountID', e.target.value);
    }

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {
                    ...initAchDataRef.current
                }

                if (bankCodeRef.current && bankCodeRef.current.getResult) {
                    result.bankCode = bankCodeRef.current.getResult();
                }

                if (bankNameRef.current && bankNameRef.current.getResult) {
                    result.bankName = bankNameRef.current.getResult();
                }

                if (bankAccountRef.current && bankAccountRef.current.getResult) {
                    result.bankAccount = bankAccountRef.current.getResult();
                }

                if (bankAccountNameRef.current && bankAccountNameRef.current.getResult) {
                    result.bankAccountName = bankAccountNameRef.current.getResult();
                }

                if (bankAccountIDRef.current && bankAccountIDRef.current.getResult) {
                    result.bankAccountID = bankAccountIDRef.current.getResult();
                }

                if (signImgRef.current && signImgRef.current.getResult) {
                    result.signImg = signImgRef.current.getResult();
                }

                return result;
            },
            isError: () => {
                return refIsRequiredError(bankCodeRef, bankNameRef, bankAccountRef, bankAccountNameRef, bankAccountIDRef, signImgRef);
            },
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            const {bankCode, bankAccount, bankAccountID} = defaultValue;
            setAchInfo({bankCode, bankAccount, bankAccountID});
        }
    }, [defaultValue]);

    return (
        <Box className={className}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label={'銀行代碼'}
                        ref={bankCodeRef}
                        value={achInfo?.bankCode}
                        {...getErrorModuleStatus(errorModule, 'bankCode')}
                        readOnly={readOnlyProp}
                        onChange={handleBankCodeChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'銀行名稱'}
                        ref={bankNameRef}
                        key={defaultValue?.bankName}
                        defaultValue={defaultValue?.bankName}
                        {...getErrorModuleStatus(errorModule, 'bankName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'銀行帳號'}
                        ref={bankAccountRef}
                        value={achInfo.bankAccount}
                        {...getErrorModuleStatus(errorModule, 'bankAccount')}
                        readOnly={readOnlyProp}
                        onChange={handleBankAccountChange}
                        fullWidth
                        required
                        {...bankAccountProps}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'銀行帳戶'}
                        ref={bankAccountNameRef}
                        key={defaultValue.bankAccountName}
                        defaultValue={defaultValue.bankAccountName}
                        {...getErrorModuleStatus(errorModule, 'bankAccountName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'帳戶身分證字號'}
                        ref={bankAccountIDRef}
                        value={achInfo.bankAccountID}
                        {...getErrorModuleStatus(errorModule, 'bankAccountID')}
                        onChange={handleBankAccountIDChange}
                        readOnly={readOnlyProp}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    {!readOnlyProp && isSameBtn && (
                        <Button variant="contained" color="secondary" className="mt-4" onClick={handleSameId}>
                            同基本資料
                        </Button>
                    )}
                </Grid>
                {!readOnlyProp && isDisplaySignature && (
                    <Grid item xs={6}>
                        <SignBox
                            title={'帳戶持有人簽名'}
                            ref={signImgRef}
                            defaultValue={defaultValue.signImg}
                            {...getErrorModuleStatus(errorModule, 'signImg')}
                            readOnly={readOnlyProp}
                            required
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default ACHCard;
