import React from 'react';
import { HUMAN_RESOURCES_EMPLOYEES_OVERVIEW, HUMAN_RESOURCES_ROLES_PERMISSION_OVERVIEW } from '@icoach/router/routerPath';
import { HumanResource as HumanResourceIcon } from '@common/SvgIcon/';
import EmployeesOverviewContent from '@icoach/employees/overview/EmployeesOverviewContent';
import RolePermissionPage from '@icoach/club/rolePermission/RolePermissionPage';

export const TABS_CONFIG = [
    {
        key: 'employees-overview',
        label: '員工總覽',
        icon: <HumanResourceIcon />,
        frontKey: 'C10X02',
        to: HUMAN_RESOURCES_EMPLOYEES_OVERVIEW,
        component: EmployeesOverviewContent,
    },
    {
        key: 'roles-permissions-overview',
        label: '店舖權限',
        icon: <HumanResourceIcon />,
        frontKey: 'C10X07',
        to: HUMAN_RESOURCES_ROLES_PERMISSION_OVERVIEW,
        component: RolePermissionPage,
    },
];
