import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getIsEnabled } from '@redux/inactivityTimer/selector';
import { getVersionData } from '@redux/version/selector';

/**
 * useInactivityTimer
 * @param {Object} config 配置對象
 * @param {number} config.firstTimeout 第一階段計時時間（毫秒），默認 10 秒。
 * @param {number} config.secondTimeout 第二階段計時時間（毫秒），默認 10 秒。
 * @param {Function} config.onFirstTimeout 第一階段超時回調函數。
 * @param {Function} config.onSecondTimeout 第二階段超時回調函數。
 */
const useInactivityTimer = ({ firstTimeout = 10000, secondTimeout = 10000, onFirstTimeout, onSecondTimeout }) => {
    const reduxVersion = useSelector(getVersionData);
    const isDevEnv = reduxVersion?.env?.toUpperCase() === 'DEV';
    const isEnabled = useSelector(getIsEnabled);
    const firstTimerRef = useRef(null);
    const secondTimerRef = useRef(null);
    const activityTimeoutRef = useRef(null);

    const clearTimers = useCallback(
        () => {
            isDevEnv && console.log('計時器已停用');
            if (firstTimerRef.current) clearTimeout(firstTimerRef.current);
            if (secondTimerRef.current) clearTimeout(secondTimerRef.current);
            if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);
        },
        // eslint-disable-next-line
        [isDevEnv],
    );

    const startSecondTimer = useCallback(
        () => {
            if (secondTimerRef.current) clearTimeout(secondTimerRef.current);

            secondTimerRef.current = setTimeout(() => {
                if (typeof onSecondTimeout === 'function') onSecondTimeout(resetFirstTimer);
            }, secondTimeout);
        },
        // eslint-disable-next-line
        [secondTimeout, onSecondTimeout],
    );

    const resetFirstTimer = useCallback(
        () => {
            clearTimers();
            isDevEnv && console.log(`重置計時器，當前時間：${new Date().toLocaleString()}`);

            firstTimerRef.current = setTimeout(() => {
                if (typeof onFirstTimeout === 'function') onFirstTimeout(resetFirstTimer);
                startSecondTimer();
            }, firstTimeout);
        },
        // eslint-disable-next-line
        [firstTimeout, onFirstTimeout, startSecondTimer, clearTimers, isDevEnv],
    );

    const handleVisibilityChange = useCallback(
        () => {
            isDevEnv && console.log(`頁面可見性改變：${document.visibilityState}`);
            if (document.visibilityState === 'visible' && isEnabled) {
                resetFirstTimer();
            } else {
                clearTimers();
            }
        },
        // eslint-disable-next-line
        [isEnabled, resetFirstTimer, clearTimers, isDevEnv],
    );

    const handleThrottledActivity = useCallback(
        () => {
            if (activityTimeoutRef.current) return;

            activityTimeoutRef.current = setTimeout(() => {
                resetFirstTimer();
                activityTimeoutRef.current = null;
            }, secondTimeout / 4); // 節流
        },
        // eslint-disable-next-line
        [resetFirstTimer, secondTimeout],
    );

    useEffect(
        () => {
            if (!isEnabled) {
                clearTimers();
                return;
            }

            const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
            events.forEach((e) => window.addEventListener(e, handleThrottledActivity));

            document.addEventListener('visibilitychange', handleVisibilityChange);
            if (document.visibilityState === 'visible') {
                resetFirstTimer();
            } else {
                clearTimers();
            }

            resetFirstTimer();

            return () => {
                events.forEach((event) => window.removeEventListener(event, handleThrottledActivity));
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                clearTimers();
            };
        },
        // eslint-disable-next-line
        [isEnabled, resetFirstTimer, handleVisibilityChange, handleThrottledActivity, clearTimers],
    );

    return null;
};

export default useInactivityTimer;
