const FOCUS_MODE_KEY = 'focusMode';

/**
 * 獲取 FocusMode 狀態
 * @returns {boolean} 當前 FocusMode 狀態，預設為 false
 */
export const getFocusMode = () => {
    const focusMode = sessionStorage.getItem(FOCUS_MODE_KEY);
    return focusMode === 'true';
};

/**
 * 設置 FocusMode 狀態
 * @param {boolean} isEnabled 是否啟用 FocusMode
 */
export const setFocusMode = (isEnabled) => {
    sessionStorage.setItem(FOCUS_MODE_KEY, isEnabled.toString());
};

/**
 * 清除 FocusMode 狀態
 */
export const clearFocusMode = () => {
    sessionStorage.removeItem(FOCUS_MODE_KEY);
};
