import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { Card, Form, Button, Stack, AutocompleteField, Box, DateIntervalField, SelectField, MenuItem } from '@common/components/';
import { MultipleSelectCheckedBox } from '@icoach/components/';
import useAppointmentApi from '@apis/useAppointmentApi';
import { parseDate } from '@util/moment';
import { initBookmark } from '../staticData';
import useAppointment from '../components/useAppointment';

const NormalSearchBox = React.forwardRef((props, ref) => {
    const { onToggleShow, options = [], referenceID = '' } = props;
    const referenceIDRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const { id, no } = referenceIDRef.current.getResult();
                const peopleID = id || 0;
                const peopleType = peopleID ? (no ? 2 : 1) : 0;
                return { peopleID, peopleType, appointmentResultType: 99, appointmentTypes: [], startDate: '', endDate: '' };
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <Stack spacing={3} alignItems={'center'}>
            <AutocompleteField
                className={'flex-auto'}
                options={options}
                optionKeys={['name', 'nickName', 'mobile', 'no']}
                optionMaskKeys={['maskName', 'nickName', 'maskMobile', 'no']}
                primaryKey={'id'}
                ref={referenceIDRef}
                label={'預約者'}
                defaultValue={referenceID}
                InputProps={{ placeholder: '請輸入預約者姓名或手機號碼或會員編號' }}
            />
            <Box className="mt-2">
                <Button type={'submit'} variant={'contained'}>
                    搜尋
                </Button>
                <Button onClick={onToggleShow}>進階篩選</Button>
            </Box>
        </Stack>
    );
});

const AdvancedSearchBox = React.forwardRef((props, ref) => {
    const { onToggleShow, referenceID, options } = props;
    const referenceIDRef = useRef(null);
    const resultTypeRef = useRef(null);
    const dateRangeRef = useRef(null);
    const apoItemTypeRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const { startDate, endDate } = dateRangeRef.current.getResult();
                const appointmentResultType = resultTypeRef.current.getResult() || 99;
                const appointmentTypes = apoItemTypeRef.current.getResult();
                const { id, no } = referenceIDRef.current.getResult();
                const peopleID = id || 0;
                const peopleType = peopleID ? (no ? 2 : 1) : 0;
                return { startDate, endDate, appointmentResultType, appointmentTypes, peopleID, peopleType };
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <AutocompleteField
                    options={options.peopleOptions}
                    optionKeys={['name', 'nickName', 'mobile', 'no']}
                    optionMaskKeys={['maskName', 'maskMobile', 'no']}
                    primaryKey={'id'}
                    ref={referenceIDRef}
                    label={'預約者'}
                    defaultValue={referenceID}
                    InputProps={{ placeholder: '請輸入預約者姓名或手機號碼或會員編號' }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={8}>
                <DateIntervalField
                    label={'預約日期'}
                    ref={dateRangeRef}
                    startDateProps={{
                        defaultValue: parseDate(),
                    }}
                    endDateProps={{
                        defaultValue: parseDate(),
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <SelectField ref={resultTypeRef} label="預約單狀態" defaultValue="99" fullWidth>
                    <MenuItem disabled value="">
                        <em>請選擇</em>
                    </MenuItem>
                    {Array.isArray(options.appointmentResultOption) &&
                        options.appointmentResultOption.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.text}
                            </MenuItem>
                        ))}
                </SelectField>
            </Grid>
            <Grid item xs={4}>
                <MultipleSelectCheckedBox ref={apoItemTypeRef} label="預約項目" options={options.appointmentTypeOption} showSelectAll fullWidth />
            </Grid>
            <Grid item xs={12} textAlign={'right'}>
                <div className="btn-group">
                    <Button onClick={onToggleShow}>取消進階</Button>
                    <Button type={'submit'} variant={'contained'}>
                        搜尋
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
});

const AppointmentOverviewSearchBar = React.forwardRef((props, ref) => {
    const { bookmark, setSourceData } = useAppointment();
    const [isShowAdvance, setIsShowAdvance] = useState(false);
    const [options, setOptions] = useState({});
    const { getAppointmentInitOverviewOptionsApi, postAppointmentOverviewDataApi } = useAppointmentApi();
    const searchRef = useRef(null);
    const preloadRef = useRef(false);
    const paramsRef = useRef(null);

    /**
     * 搜尋
     * @param {{ pageIndex: number|null }} pageObject
     */
    const handleSearchOnSubmit = (pageObject = { pageIndex: null }) => {
        let params = initBookmark();
        if (pageObject && pageObject.pageIndex * 1) {
            // 更多
            Object.assign(params, paramsRef.current);
            Object.assign(params, { pageIndex: pageObject.pageIndex });
        } else {
            Object.assign(params, searchRef.current.getResult());
            paramsRef.current = _.cloneDeep(params);
        }
        // 更多
        doGetAppointmentOverviewApi(params);
    };

    const handleAdvancedShowOnClick = () => {
        setIsShowAdvance((prev) => !prev);
    };

    const doGetAppointmentOverviewApi = async (params) => {
        preloadRef.current = false;
        const resp = await postAppointmentOverviewDataApi(params);
        if (resp) {
            setSourceData(({ list = [] }) => {
                let resource = {};
                if (list.length === 0 || resp.pageIndex === 1) {
                    Object.assign(resource, resp);
                } else {
                    let oldList = _.cloneDeep(list);
                    oldList.push(...resp.list);
                    Object.assign(resource, resp);
                    Object.assign(resource, { list: oldList });
                }
                return resource;
            });
        }
        preloadRef.current = true;
    };

    const getInitDataApi = async () => {
        const resp = await getAppointmentInitOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
        preloadRef.current = true;
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                handleSearchOnSubmit({ pageIndex: bookmark.pageIndex });
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    useEffect(
        () => {
            getInitDataApi();
        },
        // eslint-disable-next-line
        []
    );

    const { peopleOptions = [], appointmentResultOption = [], appointmentTypeOption = [] } = options;
    return (
        <Card className={'search-bar-light p-3'}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnSubmit}>
                    {isShowAdvance ? (
                        <AdvancedSearchBox
                            ref={searchRef}
                            onToggleShow={handleAdvancedShowOnClick}
                            options={{ peopleOptions, appointmentResultOption, appointmentTypeOption }}
                        />
                    ) : (
                        <NormalSearchBox ref={searchRef} onToggleShow={handleAdvancedShowOnClick} options={peopleOptions} />
                    )}
                </Form>
            </Card.CardContent>
        </Card>
    );
});

export default AppointmentOverviewSearchBar;
