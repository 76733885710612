import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Button, Card, DateIntervalField, Form, Stack } from '@common/components/';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import useOrderStatisticsApi from '@apis/useOrderStatisticsApi';
import useOrderStatisticsExportApi from '@apis/useOrderStatisticsExportApi';
import useExportActionDialog from "@util/hook/useExportActionDialog";
import { ACCOUNT_BOOK_ALL_ORDER_STAT_URL } from '@icoach/router/AccountBookRouter';
import { isBefore, isAfter, getAddMonths, getSubMonths, getSubDays, getAddDays, parseDate } from '@util/moment';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';

const AccountBookOrderStatOnAllSearchBar = (props, ref) => {
    const { className } = props;
    const history = useHistory();
    const { getOrderStatisticsAllApi } = useOrderStatisticsApi();
    const { postOrderStatisticsExportAllApi } = useOrderStatisticsExportApi();
    const { tabIndex, setTableData, setRefresh } = useAccountBookContext();
    const { showExportConfirmDialog, showExportPolicyDialog } = useExportActionDialog();
    const [paramsData, setParamsData] = useState({});
    const { start = '', end = '' } = paramsData || {};
    const dateRangeRef = useRef(null);
    const preloadRef = useRef(false);

    const updateUrlWithSearchQuery = (params) => {
        let url = ACCOUNT_BOOK_ALL_ORDER_STAT_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getParams = (tabIndex) => {
        const result = {};

        if (tabIndex) {
            Object.assign(result, { tabIndex: tabIndex });
        }

        if (dateRangeRef && dateRangeRef.current && dateRangeRef.current.getResult) {
            const { startDate: start, endDate: end } = dateRangeRef.current.getResult();
            Object.assign(result, { start, end });
        }

        return result;
    };

    const checkError = () => dateRangeRef.current.isError();

    const getOverviewApi = async (params) => {
        preloadRef.current = false;
        const resp = await getOrderStatisticsAllApi(params);
        if (resp) {
            setTableData(resp);
        }
        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
    };

    const handleSearchOnClick = async () => {
        const isError = checkError();
        if (!isError) {
            const params = getParams(tabIndex);
            getOverviewApi(params);
        }
    };

    const getExcelApi = async (params) => {
        preloadRef.current = false;
        const resp = await postOrderStatisticsExportAllApi(params);
        
        showExportPolicyDialog(resp);
        preloadRef.current = true;
    };

    const handleExcelOnClick = () => {
        const isError = checkError();
        if (!isError) {
            showExportConfirmDialog(getParams(tabIndex),
                (params) => getExcelApi(params));
        }
    };

    const handleChangeStartDate = (date, endDate) => {
        let maxData = getSubDays(getAddMonths(date, 3), 1);
        let isError = false;
        let helperText = '';
        if (endDate) {
            if (isAfter(endDate, maxData)) {
                isError = true;
                helperText = '日期區間不可大於三個月';
            }
            dateRangeRef.current.setError(isError, helperText);
        }
    };

    const handleChangeEndDate = (date, startDate) => {
        let minData = getAddDays(getSubMonths(date, 3), 1);
        let isError = false;
        let helperText = '';
        if (startDate) {
            if (isBefore(startDate, minData)) {
                isError = true;
                helperText = '日期區間不可大於三個月';
            }
            dateRangeRef.current.setError(isError, helperText);
        }
    };

    useEffect(() => {
        const params = getParams(tabIndex);
        updateUrlWithSearchQuery(params);
        // eslint-disable-next-line
    }, [tabIndex]);

    useEffect(
        () => {
            setRefresh(handleSearchOnClick);
            const { location } = history;
            const search = location.search;
            const params = search ? paramsToObject(search) : getParams(tabIndex);
            if (!isEmpty(params)) {
                setParamsData(params);
            }
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('search-bar-light px-4 py-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnClick}>
                    <Stack justifyContent={'start'} alignItems={'start'} spacing={2}>
                        <DateIntervalField
                            key={`${start}_${end}`}
                            ref={dateRangeRef}
                            label={'建立日期'}
                            className={'flex-auto'}
                            direction={'row'}
                            startDateProps={{
                                defaultValue: start || parseDate(),
                                onChange: handleChangeStartDate,
                            }}
                            endDateProps={{
                                defaultValue: end || parseDate(),
                                onChange: handleChangeEndDate,
                            }}
                            fullWidth
                        />
                        <Button type={'submit'} className={'white-space-nowrap'} variant={'contained'}>
                            搜尋
                        </Button>
                        <Button className={'white-space-nowrap'} variant={'contained'} color="success" onClick={handleExcelOnClick}>
                            下載Excel
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default AccountBookOrderStatOnAllSearchBar;
