import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const EyeSlash = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1.5,2.6c-0.4-0.3-1-0.2-1.3,0.2S0,3.7,0.3,4l22.2,17.4c0.4,0.3,1,0.2,1.3-0.2s0.2-1-0.2-1.3l-3.9-3.1
	c1.5-1.5,2.5-3.2,3-4.4c0.1-0.3,0.1-0.6,0-0.9c-0.6-1.3-1.7-3.3-3.5-4.9c-1.8-1.6-4.2-3-7.2-3c-2.6,0-4.7,1-6.3,2.3L1.5,2.6z M8.4,8
	c1-0.9,2.2-1.4,3.6-1.4c3,0,5.4,2.4,5.4,5.4c0,0.9-0.2,1.8-0.7,2.6l-1.4-1.1c0.3-0.7,0.4-1.6,0.2-2.4c-0.4-1.6-1.8-2.6-3.3-2.7
	c-0.2,0-0.3,0.2-0.3,0.4C12,9.1,12,9.3,12,9.6c0,0.4-0.1,0.7-0.2,1.1L8.4,8L8.4,8z M14,17c-0.6,0.2-1.3,0.4-2,0.4
	c-3,0-5.4-2.4-5.4-5.4c0-0.3,0-0.5,0.1-0.8L3.1,8.5c-0.9,1.1-1.5,2.2-1.8,3.1c-0.1,0.3-0.1,0.6,0,0.9c0.6,1.3,1.7,3.3,3.5,4.9
	c1.8,1.6,4.2,3,7.2,3c1.8,0,3.4-0.5,4.7-1.2L14,17z"
            />
        </SvgIcon>
    );
});

export default EyeSlash;
