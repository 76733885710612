import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import { CHOOSE_TARGET } from '@icoach/documents/membership/staticData';
import { Card, Button, SelectField, MenuItem, Form, AutocompleteField, Stack, Box } from '@common/components/';
import { refIsRequiredError, isEmpty } from '@util/utils';
import usePeopleApi, { peopleType as peopleTypeMap } from '@apis/usePeopleApi';

const memberGroup = [peopleTypeMap.member, peopleTypeMap.invalidMember, peopleTypeMap.all, peopleTypeMap.transferIn];

export const getAutocompleteFieldOptionKeys = (peopleType) => {
    if (memberGroup.includes(String(peopleType))) {
        return ['name', 'nickName', 'mobile', 'no'];
    }
    return ['name', 'mobile'];
};

export const getAutocompleteFieldOptionMaskKeys = (peopleType) => {
    if (memberGroup.includes(String(peopleType))) {
        return ['maskName', 'nickName', 'maskMobile', 'no'];
    }
    return ['maskName', 'maskMobile'];
};

const SearchBar = React.forwardRef((props, ref) => {
    const {
        className,
        sourceOption: targetOptions = [],
        onResetEvent,
        onCreateEvent,
        onChangeTargetEvent,
        onChangePeopleEvent,
        onSearchHistoryEvent,
        routerPath,
        TabsComponentProps: TabsComponent = null,
        skipPeopleOptions = [],
        notRequirePeopleOptions = [peopleTypeMap.onlyJoin, peopleTypeMap.transferManually],
        notSearchButtonOptions = [peopleTypeMap.onlyJoin, peopleTypeMap.transferInMember, peopleTypeMap.transferManually],
        notAddDocumentButtons = [
            peopleTypeMap.transferTemporary,
            peopleTypeMap.transferIn,
            peopleTypeMap.unConfirmTransferOutMember,
            peopleTypeMap.alreadyTransferOutMember,
        ],
    } = props;

    const { peopleType: peopleTypeRouter, referenceID: referenceIDRouter, page: pageProps } = useParams();
    if (!routerPath) {
        console.error('要設定router!!!!!!!!');
        return null;
    }

    const history = useHistory();
    let [isLock, setLock] = useState(false);
    let [peopleType, setPeopleType] = useState(peopleTypeRouter);
    let [options, setOptions] = useState([]);
    const peopleTypeRef = useRef(null);
    const referenceIDRef = useRef(null);
    const firstLoadRef = useRef(true);
    const { people: peopleOptions = [] } = usePeopleApi(peopleType);

    // 重設
    const handleReset = () => {
        onResetEvent && onResetEvent(peopleTypeRouter);
    };

    // 新增按鈕
    const handleCreate = () => {
        if (ref.current.isError()) return false;

        onCreateEvent && onCreateEvent();
    };

    // 搜尋歷史
    const handleSearchHistory = () => {
        if (ref.current.isError()) return false;

        onSearchHistoryEvent && onSearchHistoryEvent();
    };

    // 對象更新
    const handleTargetOnChange = (e, target) => {
        const pType = target.props.value;
        setPeopleType(pType);
        history.replace(`${routerPath}${pType}/`);

        onChangeTargetEvent && onChangeTargetEvent(pType);
    };

    // 對象id更新
    const handlePeopleOnChange = (newValue) => {
        const { id } = newValue || {};
        if (id) history.replace(`${routerPath}${peopleType}/${id}/`);
        else history.replace(`${routerPath}${peopleType}/`);

        onChangePeopleEvent && onChangePeopleEvent();
    };

    // 第一次載入觸發檢查peopleID,後續不會被觸發到
    // 透過觸發state 影響第一次autocomplete初始值
    const checkReferenceID = (opt) => {
        const isCurrentPeopleID = opt.find(({ id }) => String(id) === String(referenceIDRouter));
        if (!isCurrentPeopleID && skipPeopleOptions.indexOf(peopleType) === -1) {
            history.replace(`${routerPath}${peopleType}/`);
        }
        firstLoadRef.current = false;
        setOptions(opt);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                targetType: peopleType,
                targetID: referenceIDRef.current?.getResult('id') ?? 0,
            }),
            isError: () => refIsRequiredError(peopleTypeRef, referenceIDRef),
        }),
        // eslint-disable-next-line
        [peopleType],
    );

    useEffect(
        () => {
            if (!firstLoadRef.current) {
                setOptions(peopleOptions);
            } else if (firstLoadRef.current && !isEmpty(peopleOptions)) {
                checkReferenceID(peopleOptions);
            }
        },
        // eslint-disable-next-line
        [peopleOptions],
    );

    useEffect(() => {
        if (peopleTypeRouter) setPeopleType(peopleTypeRouter);
    }, [peopleTypeRouter]);

    useEffect(
        () => {
            if (['history', 'contract', 'readonly-contract'].includes(pageProps)) {
                setLock(true);
            } else {
                setLock(false);
            }
        },
        // eslint-disable-next-line
        [history.location.pathname],
    );

    return (
        <Card className={clsx('search-bar', className)}>
            {TabsComponent ? <Card.CardContent className="tabs-bottom-line">{TabsComponent}</Card.CardContent> : null}
            <Card.CardContent>
                <Form name="create" onSubmit={handleCreate}>
                    <Stack className="px-4 py-3" spacing={3} alignItems={'end'}>
                        <Grid container spacing={3}>
                            {!_.isEmpty(targetOptions) && (
                                <Grid item xs={6}>
                                    <SelectField
                                        label="對象"
                                        ref={peopleTypeRef}
                                        value={peopleType}
                                        onChange={handleTargetOnChange}
                                        disabled={isLock}
                                        required
                                        fullWidth
                                    >
                                        {targetOptions.map(({ text, value }) => (
                                            <MenuItem value={value} key={value}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                {skipPeopleOptions.indexOf(String(peopleType)) === -1 && (
                                    <AutocompleteField
                                        key={`${peopleType}${!!referenceIDRouter}${options}`}
                                        options={options}
                                        optionKeys={getAutocompleteFieldOptionKeys(peopleType)}
                                        optionMaskKeys={getAutocompleteFieldOptionMaskKeys(peopleType)}
                                        primaryKey={'id'}
                                        ref={referenceIDRef}
                                        label={CHOOSE_TARGET[peopleType]?.label}
                                        defaultValue={referenceIDRouter}
                                        onChange={handlePeopleOnChange}
                                        InputProps={{ disabled: isLock }}
                                        inputProps={{
                                            placeholder: CHOOSE_TARGET[peopleType]?.placeholder,
                                        }}
                                        fullWidth
                                        required={notRequirePeopleOptions.indexOf(String(peopleType)) === -1}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Box className="doc-searchbar-layout-button">
                            <Box className="btn-group mt-4 white-space-nowrap">
                                {isLock ? (
                                    <Button name="reset" color="secondary" variant="contained" onClick={handleReset}>
                                        重設
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        {notSearchButtonOptions.indexOf(String(peopleTypeRouter)) === -1 && (
                                            <Button name="history" color="secondary" variant="outlined" onClick={handleSearchHistory}>
                                                查詢歷史
                                            </Button>
                                        )}
                                        {notAddDocumentButtons.indexOf(String(peopleTypeRouter)) === -1 && (
                                            <Button type="submit" color="secondary" variant="contained">
                                                新增
                                            </Button>
                                        )}
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
});

export default SearchBar;
