import React from 'react';
import { Fullscreen as FullscreenIcon, Minus as MinusIcon } from '@common/SvgIcon/';
import { Button } from '@common/components/';
import clsx from 'clsx';

const FocusModeToggleButton = ({ className, isFocusMode, onClick, ...rest }) => {
    return (
        <Button
            className={clsx('focus-mode-button', className)}
            variant={isFocusMode ? 'contained' : 'outlined'}
            color={'secondary'}
            startIcon={isFocusMode ? <MinusIcon /> : <FullscreenIcon />}
            onClick={onClick}
            {...rest}
        >
            {isFocusMode ? '退出專注' : '啟用專注'}
        </Button>
    );
};

export default FocusModeToggleButton;
