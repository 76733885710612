import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip } from '@common/components/';

function DismissibleTooltip({ children, open: controlledOpen, onClose, onToggle, tip, ...rest }) {
    const [open, setOpen] = useState(controlledOpen || false);
    const isControlled = controlledOpen !== undefined && onClose !== undefined && onToggle !== undefined;

    const handleTooltipClose = () => {
        if (isControlled) {
            onClose();
        } else {
            setOpen(false);
        }
    };

    const handleTooltipToggle = () => {
        if (isControlled) {
            onToggle();
        } else {
            setOpen((prev) => !prev);
        }
    };

    const handleClick = (e) => {
        if (children.props.onClick) {
            children.props.onClick(e);
        }
        handleTooltipToggle();
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={tip || ''}
                PopperProps={{
                    disablePortal: true,
                }}
                open={isControlled ? controlledOpen : open}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                {...rest}
            >
                {cloneElement(children, {
                    onClick: handleClick,
                })}
            </Tooltip>
        </ClickAwayListener>
    );
}

DismissibleTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    tip: PropTypes.string,
};

DismissibleTooltip.defaultProps = {
    tip: '',
};

export default DismissibleTooltip;
