import { useHistory, useLocation } from 'react-router-dom';

const useQueryManager = () => {
    const history = useHistory();
    const location = useLocation();

    const getQuery = () => new URLSearchParams(location.search);

    const updateQuery = (newParams, replace = true) => {
        const searchParams = new URLSearchParams(location.search);

        Object.entries(newParams).forEach(([key, value]) => {
            if (value === undefined || value === null) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, value);
            }
        });

        const newSearch = `${location.pathname}?${searchParams.toString()}`;
        replace ? history.replace(newSearch) : history.push(newSearch);
    };

    return { getQuery, updateQuery };
};

export default useQueryManager;
