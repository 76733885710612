import React from 'react';
import { Box, Typography } from '@common/components/';
import clsx from 'clsx';
import PrivacyDataToggle from '@icoach/components/PrivacyDataToggle';

const CustomerNameLabel = ({ sourceData, isRevealed }) => {
    const { displayName, maskDisplayName, status, cellPhone, maskCellPhone } = sourceData;
    const invalidClass = String(status) === '2' ? 'invalid' : '';

    return (
        <React.Fragment>
            <Box className="customer-brief-name">
                <PrivacyDataToggle fullData={displayName} maskedData={maskDisplayName} isRevealed={isRevealed} />
                <Typography component="span" className={clsx('state', invalidClass)} />
            </Box>
            <PrivacyDataToggle fullData={cellPhone} maskedData={maskCellPhone} isRevealed={isRevealed} />
        </React.Fragment>
    );
};

export default CustomerNameLabel;
