import React, { useLayoutEffect, useState, useRef, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { CreditCard, ACHCard } from '../../components';
import { FieldGroup } from '../../components/Layout';

const keyMappings = {
    creditCard: {
        paymentCreditCardBankName: 'creditCardBankName',
        paymentCreditCardType: 'creditCardType',
        paymentCreditCardNo: 'creditCardNo',
        paymentCardExpiryDate: 'cardExpiryDate',
        paymentCardName: 'cardName',
        paymentCardSign: 'signImg',
        paymentCardship: 'cardRelation',
    },
    bank: {
        paymentBankCode: 'bankCode',
        paymentBankName: 'bankName',
        paymentBankAccount: 'bankAccount',
        paymentBankAccountName: 'bankAccountName',
        paymentBankAccountID: 'bankAccountID',
        bankCardSignImg: 'signImg',
    },
};

const MembershipTransferInPaymentModeItem = React.forwardRef((props, ref) => {
    const { isReadOnly = false, temporarySourceDataRef, errorModule } = props;
    const [, setRefreshKey] = useState(uuid());
    const {
        paymentType,
        paymentCreditCardNo,
        paymentCardExpiryDate,
        paymentCreditCardBankName,
        paymentCreditCardType,
        paymentCardName,
        paymentCardship,
        paymentBankCode,
        paymentBankName,
        paymentBankAccount,
        paymentBankAccountName,
        paymentBankAccountID,
        paymentCardSign,
        bankCardSignImg,
    } = temporarySourceDataRef.current;
    // 信用卡
    const paymentCreditCardRef = useRef(null);
    // 銀行
    const paymentBankRef = useRef(null);

    const clearParams = () => {
        temporarySourceDataRef.current.paymentCreditCardNo = null;
        temporarySourceDataRef.current.paymentCardExpiryDate = null;
        temporarySourceDataRef.current.paymentCreditCardBankName = null;
        temporarySourceDataRef.current.paymentCreditCardType = null;
        temporarySourceDataRef.current.paymentCardName = null;
        temporarySourceDataRef.current.paymentCardship = null;

        temporarySourceDataRef.current.paymentBankCode = null;
        temporarySourceDataRef.current.paymentBankName = null;
        temporarySourceDataRef.current.paymentBankAccount = null;
        temporarySourceDataRef.current.paymentBankAccountName = null;
        temporarySourceDataRef.current.paymentBankAccountID = null;

        // 銀行/信用卡共用
        temporarySourceDataRef.current.signImg = null;
    };

    const setParams = () => {
        if (isReadOnly) return;
        // 由於畫面都是defaultValue 不被清空temporary影響
        clearParams();
        if (paymentCreditCardRef.current && paymentCreditCardRef.current.getResult) {
            const { ...others } = paymentCreditCardRef.current.getResult();
            temporarySourceDataRef.current.paymentCreditCardNo = others.creditCardNo;
            temporarySourceDataRef.current.paymentCardExpiryDate = others.cardExpiryDate;
            temporarySourceDataRef.current.paymentCreditCardBankName = others.creditCardBankName;
            temporarySourceDataRef.current.paymentCreditCardType = others.creditCardType;
            temporarySourceDataRef.current.paymentCardName = others.cardName;
            temporarySourceDataRef.current.paymentCardSign = others.signImg;
            temporarySourceDataRef.current.paymentCardship = others.cardRelation;
        }
        if (paymentBankRef.current && paymentBankRef.current.getResult) {
            const { ...others } = paymentBankRef.current.getResult();
            temporarySourceDataRef.current.paymentBankCode = others.bankCode;
            temporarySourceDataRef.current.paymentBankName = others.bankName;
            temporarySourceDataRef.current.paymentBankAccount = others.bankAccount;
            temporarySourceDataRef.current.paymentBankAccountName = others.bankAccountName;
            temporarySourceDataRef.current.paymentBankAccountID = others.bankAccountID;
            temporarySourceDataRef.current.bankCardSignImg = others.signImg;
        }
    };

    const handleIDNumberCopy = () => {
        setParams();
        temporarySourceDataRef.current.paymentBankAccountID = temporarySourceDataRef.current.identifierID;
        setRefreshKey(uuid());
    };

    const mapErrorMessages = (errorModule, keyMapping) => {
        return Object.keys(keyMapping).reduce((result, keyName) => {
            const componentKey = keyMapping[keyName];
            const errorMsg = errorModule[keyName];
            if (errorMsg) {
                result[componentKey] = errorMsg;
            }
            return result;
        }, {});
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            return () => {
                setParams();
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            {String(paymentType) === '1' && (
                <FieldGroup label="信用卡資訊">
                    <Grid item xs={12}>
                        <CreditCard
                            ref={paymentCreditCardRef}
                            defaultValue={{
                                creditCardBankName: paymentCreditCardBankName,
                                creditCardType: paymentCreditCardType,
                                creditCardNo: paymentCreditCardNo,
                                cardExpiryDate: paymentCardExpiryDate,
                                cardName: paymentCardName,
                                signImg: paymentCardSign,
                                cardRelation: paymentCardship,
                            }}
                            errorModule={mapErrorMessages(errorModule, keyMappings.creditCard)}
                            isShowCreditCardPDF
                            readOnly={isReadOnly}
                            isShowCreditCardBankName
                            isShowCardRelation
                            isPersonalSignatureRequired
                            isDisplaySignature
                        />
                    </Grid>
                </FieldGroup>
            )}
            {String(paymentType) === '2' && (
                <FieldGroup label="銀行資訊">
                    <Grid item xs={12}>
                        <ACHCard
                            ref={paymentBankRef}
                            defaultValue={{
                                bankCode: paymentBankCode,
                                bankName: paymentBankName,
                                bankAccount: paymentBankAccount,
                                bankAccountName: paymentBankAccountName,
                                bankAccountID: paymentBankAccountID,
                                signImg: bankCardSignImg,
                            }}
                            handleSameIdEvent={handleIDNumberCopy}
                            errorModule={mapErrorMessages(errorModule, keyMappings.bank)}
                            readOnly={isReadOnly}
                            isDisplaySignature
                            isSameBtn
                        />
                    </Grid>
                </FieldGroup>
            )}
        </React.Fragment>
    );
});

export default MembershipTransferInPaymentModeItem;
