/**
 * 加總 list 中指定欄位的數值
 *
 * @param {Array} list - 包含需要加總的資料項目列表
 * @param {Array<string>} sumFields - 需要加總的欄位名稱列表
 * @returns {Object} totals - 每個欄位的加總結果，鍵為欄位名稱，值為加總值
 *
 * @example
 * const list = [
 *     { quantity: 10, alreadyInventoryQuantity: 5 },
 *     { quantity: 20, alreadyInventoryQuantity: 15 }
 * ];
 * const sumFields = ['quantity', 'alreadyInventoryQuantity'];
 * const totals = sumFieldsInList(list, sumFields);
 * // Result: { quantity: 30, alreadyInventoryQuantity: 20 }
 */
export const sumFieldsInList = (list, sumFields) => {
    const totals = {};

    // 如果 list 不是陣列，直接返回空物件
    if (!Array.isArray(list)) {
        return {};
    }

    // 初始化 totals 的欄位為 0
    sumFields.forEach((field) => {
        totals[field] = 0;
    });

    // 遍歷 list，累加指定欄位的值
    list.forEach((item) => {
        sumFields.forEach((field) => {
            totals[field] += parseFloat(item[field]) || 0; // 如果值無法轉為數字，則加 0
        });
    });

    // 返回加總結果
    return totals;
};
