import React, { useEffect, useMemo, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    Developer as DeveloperIcon,
    Store as StoreIcon,
    UserAlt as UserAltIcon,
    UserPlus as UserPlusIcon,
    ListUl as ListUlIcon,
    ChartPie as ChartPieIcon,
    Star as StarIcon,
} from '@common/SvgIcon/';
import {
    DATA_ANALYSIS_EMPLOYEES_URL_ROUTER,
    DATA_ANALYSIS_GOAL_URL_ROUTER,
    DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
    DATA_ANALYSIS_SALES_URL_ROUTER,
    DATA_ANALYSIS_SERVICE_URL_ROUTER,
    DATA_ANALYSIS_TASK_MATTER_URL_ROUTER,
    DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
} from '@icoach/router/routerPath';
import { parseDate } from '@util/moment';
import DataAnalysisContentMembership from './DataAnalysisContentMembership';
import DataAnalysisContentTaskMatter from './DataAnalysisContentTaskMatter';
import DataAnalysisContentService from './DataAnalysisContentService';
import DataAnalysisContentEmployees from './DataAnalysisContentEmployees';
import DataAnalysisContentGuestProduction from './DataAnalysisContentGuestProduction';
import DataAnalysisContentSales from './DataAnalysisContentSales';
import DataAnalysisContentKpi from './DataAnalysisContentKpi';
import DataAnalysisDialogBox from '@icoach/dataAnalysis/dialog/DataAnalysisDialogBox';
import PageLayout from '@icoach/components/layout/PageLayout';

const pageTabsMap = {
    goal: 'goal',
    membership: 'membership',
    service: 'service',
    guestProduction: 'guest-production',
    sales: 'sales',
    employees: 'employees',
    taskMatter: 'task-matter',
};

const DataAnalysisPage = (props) => {
    const { tabs } = useParams();
    const history = useHistory();
    const searchDateRef = React.useRef(parseDate());
    const dialogRef = useRef(null);

    const pageTabs = useMemo(
        () => {
            return [
                {
                    label: '目標進度',
                    value: pageTabsMap.goal,
                    icon: DeveloperIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_GOAL_URL_ROUTER,
                },
                {
                    label: '會籍',
                    value: pageTabsMap.membership,
                    icon: UserAltIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
                },
                {
                    label: '服務',
                    value: pageTabsMap.service,
                    icon: StarIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_SERVICE_URL_ROUTER,
                },
                {
                    label: '顧客製造',
                    value: pageTabsMap.guestProduction,
                    icon: UserPlusIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
                },
                {
                    label: '非會籍',
                    value: pageTabsMap.sales,
                    icon: ChartPieIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_SALES_URL_ROUTER,
                },
                {
                    label: '教練',
                    value: pageTabsMap.employees,
                    icon: StoreIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_EMPLOYEES_URL_ROUTER,
                },
                {
                    label: '管理',
                    value: pageTabsMap.taskMatter,
                    icon: ListUlIcon,
                    component: NavLink,
                    to: DATA_ANALYSIS_TASK_MATTER_URL_ROUTER,
                },
            ];
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (!Object.values(pageTabsMap).includes(tabs)) {
                history.replace(DATA_ANALYSIS_GOAL_URL_ROUTER);
            }
        },
        // eslint-disable-next-line
        [tabs],
    );

    // if (!propsTabsIndex) return null;
    return (
        <PageLayout>
            <PageLayout.Tabs tabs={pageTabs} activeTab={tabs} />
            {tabs === pageTabsMap.goal && <DataAnalysisContentKpi defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.membership && <DataAnalysisContentMembership defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.service && <DataAnalysisContentService defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.guestProduction && <DataAnalysisContentGuestProduction defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.sales && <DataAnalysisContentSales defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.employees && <DataAnalysisContentEmployees defaultDate={searchDateRef} dialogRef={dialogRef} />}
            {tabs === pageTabsMap.taskMatter && <DataAnalysisContentTaskMatter dialogRef={dialogRef} />}
            <DataAnalysisDialogBox ref={dialogRef} />
        </PageLayout>
    );
};

export default DataAnalysisPage;
