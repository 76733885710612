import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const POST_ORDERS_STATISTICS_EXPORT_SITE_API = `/api/orders/statistics/export/on-site/`;
const POST_ORDERS_STATISTICS_EXPORT_ALL_API = `/api/orders/statistics/export/on-all/`;

const useOrderStatisticsExportApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const postOrderStatisticsExportSiteApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_ORDERS_STATISTICS_EXPORT_SITE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const postOrderStatisticsExportAllApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_ORDERS_STATISTICS_EXPORT_ALL_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
            return resp;
        }, // eslint-disable-next-line
        [],
    );

    return {
        postOrderStatisticsExportSiteApi,
        postOrderStatisticsExportAllApi,
    };
};

export default useOrderStatisticsExportApi;
