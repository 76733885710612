import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DismissibleTooltip from '@icoach/components/DismissibleTooltip';
import { Stack } from '@common/components/';
import InteractiveLabel from '@icoach/components/InteractiveLabel';

const InteractiveContentWrapper = ({
    label,
    tip,
    children,
    justifyContent = 'start',
    alignItems = 'center',
    tooltipProps,
    icon,
    iconSize,
    iconProps = {},
    labelProps = {},
    ...rest
}) => {
    const [open, setOpen] = useState(false);

    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };
    const handleTooltipClose = () => setOpen(false);

    return (
        <DismissibleTooltip tip={tip} open={open} onClose={handleTooltipClose} onToggle={handleTooltipToggle}>
            <Stack alignItems={alignItems} justifyContent={justifyContent} {...rest}>
                <InteractiveLabel
                    label={label}
                    icon={icon}
                    iconSize={iconSize}
                    isIcon={Boolean(tip)}
                    alignItems={alignItems}
                    justifyContent={justifyContent}
                    labelProps={labelProps}
                    iconProps={iconProps}
                />
                {children}
            </Stack>
        </DismissibleTooltip>
    );
};

InteractiveContentWrapper.propTypes = {
    label: PropTypes.node.isRequired,
    icon: PropTypes.element,
    iconSize: PropTypes.string,
    iconProps: PropTypes.object,
    labelProps: PropTypes.object,
    tip: PropTypes.string,
    children: PropTypes.node,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
};

export default InteractiveContentWrapper;
