import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { INBODY_EMPLOYEE_INTERVIEW_ROUTER, INBODY_INTERVIEW_ROUTER, INBODY_RECORD_ROUTER } from '@icoach/router/routerPath';
import { Card, Form, Button, Stack, Box, MenuItem, SelectField } from '@common/components/';
import useInBodyApi from '@apis/useInBodyApi';
import { refIsRequiredError } from '@util/utils';
import { FieldByEmployee, FieldByMember } from '@icoach/inbody/components/';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';
import useGetInbodyOverviewOptionsApi from '@util/hook/useGetInbodyOverviewOptionsApi';
import { referenceTypeMap } from '@icoach/inbody/staticData';

const InBodyHistorySearchBar = ({ className }) => {
    const { referenceID, referenceType: referenceTypeParams } = useParams();
    const { setSourceData } = useInBodyContext();
    const { getSearchHistoryApi } = useInBodyApi();
    const history = useHistory();
    const [isLock, setLock] = useState(false);
    const [referenceType, setReferenceType] = useState(referenceTypeParams || referenceTypeMap.member);
    const { options: referenceTypeOptions, hasEmployeeMeasure } = useGetInbodyOverviewOptionsApi();
    const referenceTypeRef = useRef(null);
    const referenceIDRef = useRef(null);
    const isEmployeeSelected = String(referenceType) === referenceTypeMap.employee;

    const getAllParams = () => {
        return {
            type: referenceType,
            ...referenceIDRef.current.getResult(),
        };
    };

    const isError = () => refIsRequiredError(referenceIDRef, referenceTypeRef);

    const handleSearchOnClick = () => {
        if (!isError()) {
            const params = getAllParams();
            doSearchApi(params);
        }
    };

    const handleInterviewOnClick = () => {
        if (!isError()) {
            const { type: referenceType, referenceID } = getAllParams();
            const router = {
                [referenceTypeMap.member]: INBODY_INTERVIEW_ROUTER,
                [referenceTypeMap.employee]: INBODY_EMPLOYEE_INTERVIEW_ROUTER,
            };
            history.push(`${router[referenceType]}${referenceID}`);
        }
    };

    const redirectToInBodyRecord = (params) => {
        const { referenceType, referenceID } = params;
        let path = INBODY_RECORD_ROUTER;

        if (referenceType) path = `${path}${referenceType}`;
        if (referenceID) path = `${path}/${referenceID}`;

        history.replace(path);
    };

    const handleResetSearch = () => {
        setLock(false);
        redirectToInBodyRecord({ referenceType });
        setSourceData(null);
    };

    const doSearchApi = async (params) => {
        const { referenceID, type } = params;
        const resp = await getSearchHistoryApi(params);
        setSourceData(resp);
        if (referenceID) {
            redirectToInBodyRecord({
                referenceType: type,
                referenceID,
            });
        }

        setLock(true);
    };

    const handleChangeReferenceType = (e) => {
        setReferenceType(e.target.value);
    };

    useEffect(
        () => {
            if (String(referenceTypeParams) !== String(referenceType)) {
                const { referenceID } = referenceIDRef.current.getResult();
                redirectToInBodyRecord({ referenceType, referenceID });
            }
        },
        // eslint-disable-next-line
        [referenceTypeParams, referenceType],
    );

    return (
        <Card className={clsx('search-bar', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnClick}>
                    <Box className="px-4 py-3">
                        <Grid container spacing={2}>
                            {hasEmployeeMeasure ? (
                                <>
                                    <Grid xs={3} item>
                                        <SelectField
                                            key={referenceType}
                                            ref={referenceTypeRef}
                                            label={'對象'}
                                            defaultValue={referenceType}
                                            onChange={handleChangeReferenceType}
                                            fullWidth
                                            disabled={isLock}
                                            required
                                        >
                                            {Array.isArray(referenceTypeOptions) &&
                                                referenceTypeOptions
                                                    .filter((item) => item || !item.disabled)
                                                    .map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.text}
                                                        </MenuItem>
                                                    ))}
                                        </SelectField>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {isEmployeeSelected ? (
                                            <FieldByEmployee key={String(isLock)} ref={referenceIDRef} referenceID={referenceID} isLock={isLock} />
                                        ) : (
                                            <FieldByMember key={String(isLock)} ref={referenceIDRef} referenceID={referenceID} isLock={isLock} />
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={8}>
                                    <FieldByMember key={String(isLock)} ref={referenceIDRef} referenceID={referenceID} isLock={isLock} />
                                </Grid>
                            )}

                            <Grid item xs={4}>
                                <Box className={'mt-4'}>
                                    {isLock ? (
                                        <Button name="reset" color="secondary" variant="contained" onClick={handleResetSearch}>
                                            重設
                                        </Button>
                                    ) : (
                                        <Stack spacing={2}>
                                            {!isEmployeeSelected && (
                                                <Button type="submit" color="secondary" variant="outlined">
                                                    查詢歷史
                                                </Button>
                                            )}

                                            <Button onClick={handleInterviewOnClick} color="secondary" variant="contained">
                                                開始面談
                                            </Button>
                                        </Stack>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default InBodyHistorySearchBar;
