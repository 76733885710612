import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import {POST_SECURITY_MASK_API} from '@apis/apiPath';


const useSecurityMaskApi = () => {
    const Ajax = useAjax();
    const postSecurityMaskApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_SECURITY_MASK_API, params, {isLoadingVisible:false});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        postSecurityMaskApi,
    };
}

export default useSecurityMaskApi;
