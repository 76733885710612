import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Switch, Box } from '@common/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import useClubPermissionApi from '@apis/useClubPermissionApi';

const RolePermissionSwitchBox = React.forwardRef((props, ref) => {
    const { defaultValue = false, sourceData, headerLabel, permissionID, clubID } = props;
    const [checked, setChecked] = useState(false);
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const setMessageDialog = useMessageDialog();
    const { putClubPermissionsApi } = useClubPermissionApi();

    const handleOnChange = (_e, value) => {
        const checkedText = !checked ? '新增' : '移除';
        setMessageDialog({
            open: true,
            title: '提醒',
            MsgComp: () => (
                <React.Fragment>
                    確定要{checkedText}&nbsp;
                    <Box component="span" className="font-weight-bold">
                        {sourceData.employeeRankTypeText}
                    </Box>
                    &nbsp;的&nbsp;
                    <Box component="span" className="font-weight-bold">
                        {headerLabel}
                    </Box>
                    &nbsp;權限嗎？
                </React.Fragment>
            ),
            onConfirm: () => {
                postEmployeeRankPermissionApi(
                    {
                        isEnabled: value,
                        employeeRankType: sourceData.employeeRankType,
                        permissionID: permissionID,
                        clubID,
                    },
                    value
                );
            },
        });
    };

    const postEmployeeRankPermissionApi = async (params, isChecked) => {
        const resp = await putClubPermissionsApi(params);
        if (resp) {
            setChecked(isChecked);
            _snackbar('修改成功', { variant: 'success' });
        }
    };

    useEffect(
        () => {
            setChecked(Boolean(defaultValue));
        },
        // eslint-disable-next-line
        [defaultValue]
    );

    return <Switch checked={checked} onChange={handleOnChange} />;
});

export default RolePermissionSwitchBox;
