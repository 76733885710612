import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Button, Card, DateField, SelectField, MenuItem, Stack, Box } from '@common/components/';
import { parseDate } from '@util/moment';
import { refIsRequiredError } from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import useDocHealthDeclarationApi from '@apis/useDocHealthDeclarationApi';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH } from '@icoach/router/routerPath';
import HealthDeclarationContent101 from '@icoach/documents/healthDeclaration/HealthDeclarationContent101';
import HealthDeclarationContent103 from '@icoach/documents/healthDeclaration/HealthDeclarationContent103';
import FocusModeToggle from '../../components/FocusModeToggle';
import useInactivityTimerToggle from '@util/hook/useInactivityTimerToggle';

const baseDatePeopleTypes = [peopleTypeMap.onlyJoin, peopleTypeMap.transferManually];

const HealthSurveyContent = React.forwardRef((props, ref) => {
    const { isReadOnly = false, onReturnHistory, onReturnContinueDoc } = props;
    const { referenceID, peopleType, contractID } = useParams();
    const history = useHistory();
    const { getDocHealthDeclarationContractApi, postDocHealthDeclarationContractApi } = useDocHealthDeclarationApi();
    const [isMounted, setMounted] = useState(false);
    const [options, setOptions] = useState({});
    const [sourceData, setSourceData] = useState({});
    const contentRef = React.useRef(null);
    const employeeRef = React.useRef(null);
    const fillDateRef = React.useRef(null);
    // 防止連點和多送一次
    const apiSendingRef = React.useRef(false);
    const showPDF = useShowPDF();
    const focusModeRef = useRef(null);

    useInactivityTimerToggle();

    const getAllParams = () => {
        let paramsPack = { ...sourceData };
        const employeeID = employeeRef.current;
        const fillDate = fillDateRef.current;
        Object.assign(paramsPack, { employeeID: employeeID.getResult() || 0 });
        Object.assign(paramsPack, { fillDate: fillDate.getResult() || parseDate(sourceData.fillDate) });
        Object.assign(paramsPack, { targetType: peopleType, targetID: referenceID });
        Object.assign(paramsPack, { survey: peopleType, targetID: referenceID });
        Object.assign(paramsPack, contentRef.current.getResult());

        return paramsPack;
    };

    // 確認送出
    const handleSendOnClick = () => {
        if (apiSendingRef.current) return false;
        if (!contentRef.current.isError() && !refIsRequiredError(employeeRef)) {
            const params = getAllParams();
            // console.log(params);
            apiSendingRef.current = true;
            addDocHealthDeclarationData(params);
        }
    };

    // 返回總覽頁
    const handleReturnHistory = () => {
        window.setTimeout(onReturnHistory, 5);
    };

    // 新增健康申告書
    const addDocHealthDeclarationData = async (params) => {
        const resp = await postDocHealthDeclarationContractApi(params, contractID);
        if (resp) {
            if (resp?.completedPath) {
                showPDF({
                    open: true,
                    pdfUrl: resp.completedPath,
                    onClose: () => {
                        // 新增結束後的動作判斷及處理
                        if (window.location.search.indexOf('continue-doc') !== -1 && typeof onReturnContinueDoc === 'function') {
                            onReturnContinueDoc(resp.contractID);
                        }
                    },
                });
            }
            window.setTimeout(() => {
                let path = `${DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH(peopleType, referenceID, resp.contractID)}${
                    window.location.search
                }`;
                history.replace(path);
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 初始畫面資料
    const doInitDataApi = async (params, id) => {
        const resp = await getDocHealthDeclarationContractApi(params, id);
        if (resp) {
            const { resource, ...contractData } = resp;
            setOptions({ employeesOption: resource.coachOptions });
            setSourceData(contractData);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            doInitDataApi(
                {
                    id: referenceID,
                    type: peopleType,
                },
                contractID,
            );
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [contractID],
    );

    // isCompleteContract:是否為正式文件
    const { employeeID = null, fillDate = null, contractVersion } = sourceData;
    const onlyCreate = baseDatePeopleTypes.indexOf(peopleType) !== -1;

    return (
        <FocusModeToggle ref={focusModeRef} showToggleFocusModeButton={!isReadOnly}>
            <Card>
                {isMounted && (
                    <Card.CardContent className="py-3 px-5">
                        {['101', '102'].includes(String(contractVersion)) && (
                            <HealthDeclarationContent101 ref={contentRef} sourceData={sourceData} peopleType={peopleType} isReadOnly={isReadOnly} />
                        )}
                        {String(contractVersion) === '103' && (
                            <HealthDeclarationContent103 ref={contentRef} sourceData={sourceData} peopleType={peopleType} isReadOnly={isReadOnly} />
                        )}
                        <Box className={clsx(isReadOnly && 'mb-3', 'p-3', 'bg-light')}>
                            <Grid spacing={3} container>
                                <Grid xs={4} item>
                                    <DateField defaultValue={parseDate(fillDate)} ref={fillDateRef} label="填表日期" readOnly={isReadOnly} fullWidth required />
                                </Grid>
                                <Grid xs={4} item>
                                    <SelectField
                                        ref={employeeRef}
                                        label="擔當教練"
                                        defaultValue={employeeID || ''}
                                        readOnly={isReadOnly}
                                        displayEmpty
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="" disabled>
                                            <em>請選擇</em>
                                        </MenuItem>
                                        {options.employeesOption.map(({ value, disabled, text }) => (
                                            <MenuItem key={value} value={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                </Grid>
                            </Grid>
                        </Box>
                        <Stack className="my-3" justifyContent={'center'} spacing={2}>
                            {!onlyCreate && (
                                <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleReturnHistory}>
                                    返回查看歷史
                                </Button>
                            )}
                            {!isReadOnly && (
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="btn-minWidth"
                                        onClick={() => focusModeRef.current.checkAndExitFocusMode(handleSendOnClick)}
                                    >
                                        確認送出
                                    </Button>
                                </React.Fragment>
                            )}
                        </Stack>
                    </Card.CardContent>
                )}
            </Card>
        </FocusModeToggle>
    );
});

export default HealthSurveyContent;
