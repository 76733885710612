import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Typography } from '@common/components/';
import { MembershipTransferOverHistoryTable } from '@icoach/documents/table';
import useDocMembershipTransferOver from '@apis/useDocMembershipTransferOverApi';

const MembershipTransferOverHistory = (props) => {
    const { onBlankContractClick } = props;
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { postTransferOverContractHistoryApi } = useDocMembershipTransferOver();
    const [sourceData, setSourceData] = useState([]);

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        onBlankContractClick && onBlankContractClick();
    };

    // 讀取該對象的歷史紀錄
    const getInitPeopleRecordOverview = async () => {
        const parmas = { type: peopleTypeProps, id: referenceIDProps };
        const resp = await postTransferOverContractHistoryApi(parmas);
        if (resp) setSourceData(resp);
    };

    useEffect(
        () => {
            getInitPeopleRecordOverview();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <header className={'page-header'}>
                    <Typography variant="h5" component="h2">
                        歷史紀錄
                    </Typography>
                    <div className="btn-group">
                        <Button name="create" variant="outlined" color="secondary" onClick={handleCreateButtonClick}>
                            建立新表單
                        </Button>
                    </div>
                </header>
                <MembershipTransferOverHistoryTable className={'theme-gray'} sourceData={sourceData} onReload={getInitPeopleRecordOverview} />
            </Card.CardContent>
        </Card>
    );
};

export default MembershipTransferOverHistory;
