import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const POST_MEMBER_FILTER_EXPORT_WORKOUT_FREQUENCY_API = `/api/member/filter-export/workout-frequency/`;
const POST_MEMBER_FILTER_EXPORT_HUNDRED_T_SHIRT_API = `/api/member/filter-export/hundred-t-shirt/`;
const POST_MEMBER_FILTER_EXPORT_NEW_API = `/api/member/filter-export/new/`;
const POST_MEMBER_FILTER_EXPORT_INVALID_API = `/api/member/filter-export/invalid/`;
const POST_MEMBER_FILTER_EXPORT_BIRTHDAY_API = `/api/member/filter-export/birthday/`;
const POST_MEMBER_FILTER_EXPORT_LEAVE_API = `/api/member/filter-export/leave/`;
const POST_MEMBER_FILTER_EXPORT_RENEW_API = `/api/member/filter-export/renew/`;
const POST_MEMBER_FILTER_EXPORT_HEALTHCARE_API = `/api/member/filter-export/healthcare/`;
const POST_MEMBER_FILTER_EXPORT_MEASURE_NOT_YET_API = `/api/member/filter-export/measure-not-yet/`;
const POST_MEMBER_FILTER_EXPORT_MEASURED_API = `/api/member/filter-export/measured/`;
const POST_MEMBER_FILTER_EXPORT_OVERDRAFT_API = `/api/member/filter-export/overdraft/`;
const POST_MEMBER_FILTER_EXPORT_MONTH_FEE_API = `/api/member/filter-export/month-fee/`;
const POST_MEMBER_FILTER_EXPORT_OVERVIEW_API = `/api/member/filter-export/overview/`;
const POST_MEMBER_FILTER_EXPORT_MEASURE_RESULT_API = `/api/member/filter-export/measure-result/`;
const POST_MEMBER_FILTER_MEASURE_RESULT_EXCEL_API = `/api/member/filter-export/measure-result-excel/`;

const useMemberFilterExportApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const postMemberFilterWorkoutFrequencyApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_WORKOUT_FREQUENCY_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterHundredTShirtApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_HUNDRED_T_SHIRT_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterNewApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_NEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterInvalidApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_INVALID_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterBirthdayApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_BIRTHDAY_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterLeaveApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_LEAVE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterRenewApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_RENEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterHealthcareApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_HEALTHCARE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterMeasureNotYetApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_MEASURE_NOT_YET_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterMeasuredApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_MEASURED_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterOverdraftApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_OVERDRAFT_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterMonthFeeApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_MONTH_FEE_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_OVERVIEW_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    const postMemberFilterMeasureResultApi = useCallback(async (params) => {
        const resp = await Ajax.post(POST_MEMBER_FILTER_EXPORT_MEASURE_RESULT_API, params);
        if (resp && typeof resp === 'string') {
            enqueueSnackbar(resp, { variant: 'success' });
            return null;
        } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
            return resp;
        }
        // eslint-disable-next-line
    }, []);

    // 取得量身成果 EXCEL 匯出
    const postMemberFilterMeasureResultExcelApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_FILTER_MEASURE_RESULT_EXCEL_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, { variant: 'success' });
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
            // return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        postMemberFilterWorkoutFrequencyApi,
        postMemberFilterHundredTShirtApi,
        postMemberFilterNewApi,
        postMemberFilterInvalidApi,
        postMemberFilterBirthdayApi,
        postMemberFilterLeaveApi,
        postMemberFilterRenewApi,
        postMemberFilterHealthcareApi,
        postMemberFilterMeasureNotYetApi,
        postMemberFilterMeasuredApi,
        postMemberFilterOverdraftApi,
        postMemberFilterMonthFeeApi,
        postMemberFilterOverviewApi,
        postMemberFilterMeasureResultApi,
        postMemberFilterMeasureResultExcelApi,
    };
};

export default useMemberFilterExportApi;
