import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import MoreLabel from '@icoach/nonMember/components/MoreLabel';
import { prospectDialogTextMap } from '@icoach/nonMember/staticData';
import Tablenization from '@icoach/components/Tablenization';
import { NON_MEMBER_CUSTOMER_ADD_ROUTER, NON_MEMBER_PROSPECT_INFO_ROUTER } from '@icoach/router/NonMemberRouter';
import { FormControlLabel, Checkbox, Box, Tooltip } from '@common/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import { MorePageBox, PrivacyDataToggleButton } from '@icoach/components';
import usePrivacyToggle, {securityMaskFilterTypeMap} from '@util/hook/usePrivacyToggle';
import { getProspectRowsLabel } from '@icoach/nonMember/tableColumnsConfig';

const ProspectTable = () => {
    const { isRevealed, toggle } = usePrivacyToggle();
    const rowsLabel = useMemo(
        () => {
            const getMoreLabel = () => ({
                cellKey: '',
                headerLabel: '',
                align: 'right',
                isIcon: true,
                formatCell: (cellValue, rowData, { setContactState }) => {
                    return (
                        <MoreLabel
                            moreActionArray={['edit', 'appointment', 'sms', 'contact', 'onvalid', 'remove']}
                            urls={{
                                edit: `${NON_MEMBER_PROSPECT_INFO_ROUTER}${rowData.customerID}`,
                                apo: `${NON_MEMBER_CUSTOMER_ADD_ROUTER}${rowData.customerID}`,
                            }}
                            sourceData={rowData}
                            peopleID={rowData.customerID}
                            dialogViewMap={prospectDialogTextMap}
                            setContactState={setContactState}
                            {...(rowData || {})}
                        />
                    );
                },
            });
            return [...getProspectRowsLabel(isRevealed, toggle), getMoreLabel()];
        },
        // eslint-disable-next-line
        [isRevealed],
    );

    const { tableData, setBookmarkNum, setContactState, refresh } = useNonMember();
    const { pageIndex: page, totalPage: count } = tableData;
    let { list: rowsData = [] } = tableData;
    rowsData = rowsData || [];
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef, , clearAllChecked] = useGroupCheckbox(rowsData);
    const handlePageChange = (e, num) => setBookmarkNum(num);

    const handleToggle = useCallback(() => {
        const apiParams = {
            filterType: securityMaskFilterTypeMap.ClubCustomerGuestOverview,
            totalCount: rowsData.length,
        }
        toggle(apiParams);
    }, [toggle, rowsData]);

    return (
        <Box className="customer-table">
            <Box className="customer-table-action">
                <Box className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader
                        viewIconArray={['sms', 'folder', 'invalid', 'remove']}
                        selectedRef={selectedOptRef}
                        rowsData={rowsData}
                        customerType={1}
                        dialogViewMap={prospectDialogTextMap}
                        refresh={refresh.current}
                        clearAllChecked={clearAllChecked}
                    />
                    <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                        <PrivacyDataToggleButton className={'has-divider'} isRevealed={isRevealed} onToggle={handleToggle} />
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                <Tablenization Pagination={MorePageBox} page={page} count={count} onChange={handlePageChange} isPagination>
                    <Tablenization.TableRowHeader headerRow={rowsLabel} />
                    <Tablenization.TableRowBody
                        headerRow={rowsLabel}
                        rowsData={rowsData}
                        chkRef={selectedOptRef}
                        checkedOnChange={handleCheckedChange}
                        setContactState={setContactState}
                    />
                </Tablenization>
            </Box>
        </Box>
    );
};

export default ProspectTable;
