import React, { useEffect, memo, forwardRef, useImperativeHandle, useState, useCallback, useRef } from 'react';
import useLocalFocusModeToggle from '@util/hook/useLocalFocusModeToggle';
import FocusModeToggleButton from '@icoach/components/FocusModeToggleButton';
import useNavigationGuard from '@util/hook/useNavigationGuard';
import { Box } from '@common/components/';
import clsx from 'clsx';
import useMessageDialog from '@util/hook/useMessageDialog';
import EmployeeIdentityVerificationDialog from '@icoach/components/EmployeeIdentityVerificationDialog';
import useSwitch from '@util/hook/useSwitch';

const FocusModeToggle = memo(
    forwardRef(({ children, showToggleFocusModeButton = true }, ref) => {
        const { isOn: isOpenDialog, handleTurnOn: handleOpenDialog, handleTurnOff: handleCloseDialog } = useSwitch();
        const { isFocusMode, onEnableFocusMode, onDisableFocusMode } = useLocalFocusModeToggle();
        const [isGuardEnabled, setGuardEnabled] = useState(showToggleFocusModeButton);
        const { navigationKey, confirmNavigation, cancelNavigation } = useNavigationGuard({
            isGuardEnabled: isGuardEnabled,
        });
        const setMessageDialog = useMessageDialog();
        const refreshFnRef = useRef(null);

        const handleVerificationOutcome = useCallback(
            (success = true) => {
                if (success) {
                    if (navigationKey) confirmNavigation();
                    if (typeof refreshFnRef.current === 'function') {
                        setGuardEnabled(false);
                        refreshFnRef.current();
                    }
                    onDisableFocusMode();
                    handleCloseDialog();
                }
            },
            // eslint-disable-next-line
            [navigationKey],
        );

        const handleFocusModeTooltip = useCallback(
            () => {
                if (isFocusMode) {
                    handleOpenDialog();
                } else {
                    setMessageDialog({
                        open: true,
                        title: '確定要離開',
                        msg: '此動作將會遺失資料，確定要離開?',
                        onAsyncConfirm: (e, onClose) => {
                            confirmNavigation();
                            onClose();
                        },
                    });
                }
            },
            // eslint-disable-next-line
            [isFocusMode],
        );

        const toggleFocusMode = useCallback(
            () => {
                if (!isFocusMode) {
                    onEnableFocusMode();
                } else {
                    handleOpenDialog();
                }
            },
            // eslint-disable-next-line
            [isFocusMode],
        );

        const handleCloseDialogFn = () => {
            cancelNavigation();
            handleCloseDialog();
        };

        useEffect(
            () => {
                if (navigationKey) {
                    handleFocusModeTooltip();
                }
            },
            // eslint-disable-next-line
            [navigationKey, handleFocusModeTooltip],
        );

        useEffect(
            () => {
                if (!isGuardEnabled && isFocusMode) {
                    setGuardEnabled(isFocusMode);
                }
            },
            // eslint-disable-next-line
            [isFocusMode],
        );

        useImperativeHandle(
            ref,
            () => ({
                checkAndExitFocusMode: (fn) => {
                    if (isFocusMode) {
                        refreshFnRef.current = fn;
                        handleOpenDialog();
                    } else {
                        setGuardEnabled(false);
                        if (typeof fn === 'function') {
                            fn();
                        }
                    }
                },
            }),
            // eslint-disable-next-line
            [isFocusMode],
        );

        return (
            <Box className={clsx('focus-mode', { active: isFocusMode })}>
                {showToggleFocusModeButton && <FocusModeToggleButton className={'focus-mode__button'} isFocusMode={isFocusMode} onClick={toggleFocusMode} />}
                {children}
                <EmployeeIdentityVerificationDialog
                    open={isOpenDialog}
                    sourceData={{ navigationKey: navigationKey }}
                    onClose={handleCloseDialogFn}
                    onIdentityVerificationResult={handleVerificationOutcome}
                />
            </Box>
        );
    }),
);

export default FocusModeToggle;
