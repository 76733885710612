import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Button, TextField, Form, SecurityTextField, Box, Typography } from '@common/components/';
import { useSnackbar } from 'notistack';
import { ReadTextField } from '@icoach/components/';
import useAuthUserApi from '@apis/useAuthUserApi';
import { refIsRequiredError, isEmpty } from '@util/utils';
import { ROOT_LOGIN__ROUTER } from '@icoach/router/routerPath';

import LoginHeader_1 from '@images/login_1.svg';
import LoginHeader_2 from '@images/login_2.svg';
import LoginLogo from '@images/logo-white.svg';
import LoginFooterBg from '@images/footer-bg.svg';

// 連結失效通知畫面
const LinkExpiredNoticeBox = (props) => {
    const { onBackToLogin } = props;
    const [secondState, setSecondState] = useState(5);
    const [dots, setDots] = useState('...');
    const intervalRef = useRef(null);

    useEffect(
        () => {
            // 秒數倒數計時
            const timeout = setInterval(() => {
                setSecondState((prev) => {
                    if (prev > 1) {
                        return prev - 1;
                    } else {
                        clearInterval(timeout); // 停止計時
                        // 在這裡可以執行轉跳邏輯，例如使用 history.push
                        onBackToLogin();
                        return 0;
                    }
                });
            }, 1000);

            return () => clearInterval(timeout);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            // "..." 的循環效果
            intervalRef.current = setInterval(() => {
                setDots((prev) => {
                    if (prev.length === 3) return '.';
                    return prev + '.';
                });
            }, 500);

            return () => clearInterval(intervalRef.current);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Typography align="center" variant="h3">
            網站連結已失效 {secondState} 秒後將會再轉跳回店舖登入{' '}
            <Typography display="inlineBlock" component="span" variant="h3" style={{ width: '3rem' }}>
                {dots}
            </Typography>
        </Typography>
    );
};

const ClubResetAuthPage = () => {
    const { putAuthPwdApi, getCheckAuthUserForgotPasswordTokenApi } = useAuthUserApi();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const [isMounted, setMounted] = useState(false);
    const [hasExpired, setExpired] = useState(true);
    const [userName, setUserName] = useState('');
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const history = useHistory();
    const { token = null } = useParams() || {};

    const getParams = () => {
        return {
            password: confirmPasswordRef.current.getResult(),
        };
    };

    const handleLoginApi = (e) => {
        e.preventDefault();
        e.stopPropagation();
        passwordRef.current.setError(false, null);
        confirmPasswordRef.current.setError(false, null);
        if (!refIsRequiredError(passwordRef)) {
            const f = passwordRef.current.getResult();
            const s = confirmPasswordRef.current.getResult();
            if (f !== s) {
                confirmPasswordRef.current.setError(true, '密碼與確認密碼不相同');
            } else {
                const params = getParams();
                putChangePassword(params, token);
            }
        } else {
            passwordRef.current.setError(true, '請符合以下密碼規則');
        }
    };

    const putChangePassword = async (params, ticket) => {
        const resp = await putAuthPwdApi(params, ticket);
        if (!isEmpty(resp)) {
            _snackbar('重新設定密碼成功', { variant: 'success' });
            history.replace(ROOT_LOGIN__ROUTER);
        }
    };

    const initCheckToken = async (ticket) => {
        const resp = await getCheckAuthUserForgotPasswordTokenApi(ticket);
        if (!isEmpty(resp)) {
            setUserName(resp.userName);
            setExpired(false);
        }
        setMounted(true);
    };

    const backToLogin = () => {
        history.replace(ROOT_LOGIN__ROUTER);
    };

    useEffect(
        () => {
            if (!isEmpty(token)) {
                initCheckToken(token);
            } else {
                backToLogin();
            }
            return () => {
                setMounted(false);
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            {isMounted && (
                <React.Fragment>
                    <Box className="login-header">
                        <Box className="login-header-container">
                            <Box className="login-header-bg">
                                <img src={LoginHeader_1} className="login-header-1" alt="curves" />
                                <img src={LoginHeader_2} className="login-header-2" alt="curves" />
                                <img src={LoginLogo} className="login-logo" alt="logo" />
                            </Box>
                        </Box>
                    </Box>
                    {hasExpired ? (
                        <LinkExpiredNoticeBox onBackToLogin={backToLogin} />
                    ) : (
                        <React.Fragment>
                            <Box className="login-body">
                                <Box className="login-body-container">
                                    <Form className="login-form" onSubmit={handleLoginApi}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <ReadTextField className="flex-grow-1" label={'店舖帳號：'}>
                                                    {userName}
                                                </ReadTextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SecurityTextField
                                                    label="重設密碼"
                                                    ref={passwordRef}
                                                    name="confirm-password"
                                                    autoComplete="confirm-password"
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="確認密碼"
                                                    ref={confirmPasswordRef}
                                                    type="password"
                                                    name="reset-password"
                                                    autoComplete="reset-password"
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box className="login-btn-confirm">
                                            <Button variant="contained" type="submit">
                                                確認
                                            </Button>
                                        </Box>
                                    </Form>
                                </Box>
                            </Box>
                        </React.Fragment>
                    )}
                    <Box className="login-footer">
                        <Box component="img" src={LoginFooterBg} alt="FooterBackground"></Box>
                        <Box className="login-copyright ban-select">
                            <Box component="span">版權所有 © 2022 Curves 可爾姿女性30分鐘環狀運動</Box>
                        </Box>
                    </Box>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ClubResetAuthPage;
