import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import { Box, Button, Form, Stack, TextField, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { ReadTextField } from '@icoach/components/';
import useAuthUserApi from '@apis/useAuthUserApi';
import { generateSecurity } from '@util/apiCryto';
import { isEmpty, localUser } from '@util/utils';

const EmployeeIdentityVerificationDialog = ({ className, open = true, sourceData, onClose, onIdentityVerificationResult }) => {
    const { navigationKey } = sourceData || {};
    const { postAuthUserUnlockScreenApi } = useAuthUserApi();
    const passwordRef = useRef();
    const userInfo = localUser.get('user');
    const employeeInfo = localUser.get('employee');

    const doUnlockScreenApi = async (params) => {
        const resp = await postAuthUserUnlockScreenApi(params);
        let result = false;

        if (typeof resp === 'boolean') {
            result = resp;
        } else {
            result = false;
        }

        if (typeof onIdentityVerificationResult === 'function') onIdentityVerificationResult(result);
    };

    const handleSubmit = () => {
        const { hmacSecurity: password, hmacKey } = generateSecurity(passwordRef.current && passwordRef.current?.getResult());
        const params = {
            password,
            hmacKey,
        };
        doUnlockScreenApi(params);
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', 'user-login-dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            BackdropProps={{
                style: {
                    backgroundColor: '#797a7c',
                },
            }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>教練身分驗證</GradientColorHeader>
            <DialogContent>
                <Box className={'user-login-dialog__wrapper'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>請輸入密碼以退出專注模式。</Typography>
                            {navigationKey && <Typography>注意！轉跳頁面將會遺失未儲存的資料。確定要離開嗎？</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <ReadTextField label={!isEmpty(employeeInfo) ? '當前教練' : '當前使用者'}>
                                {!isEmpty(employeeInfo) ? employeeInfo.displayName : userInfo.displayName}
                            </ReadTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField key={open} label="請輸入密碼" ref={passwordRef} type="password" fullWidth required />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack className={'mt-2'} justifyContent={'center'}>
                                <Button variant="contained" type="submit">
                                    確認送出
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EmployeeIdentityVerificationDialog;
