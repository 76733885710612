import React, { useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Stack, Button, AutocompleteField, Skeleton } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useMemberCheckOutApi from '@apis/useMemberCheckOutApi';

const CheckoutDialog = (props) => {
    const { onClose: onCloseProps, open } = props;
    const { postMembersCheckoutApi, getMembersCheckoutPeopleOptionsApi } = useMemberCheckOutApi();
    const [options, setOptions] = useState([]);
    const [isMounted, setMounted] = useState(false);
    const peopleRef = useRef(null);
    const handleOnClose = (e) => onCloseProps && onCloseProps(null);
    const habdleOnConfirm = (e) => {
        if (!refIsRequiredError(peopleRef)) {
            const { id, name } = peopleRef.current.getResult();
            let params = {
                memberIDs: [id],
            };
            postCheckOutApi(params, name);
        }
    };

    const postCheckOutApi = async (params, memberName) => {
        const resp = await postMembersCheckoutApi(params, memberName);
        if (resp) {
            onCloseProps && onCloseProps(null);
        }
    };

    const getMemberList = async () => {
        const resp = await getMembersCheckoutPeopleOptionsApi();
        if (resp) {
            setOptions(resp);
            setMounted(true);
        }
    };

    React.useLayoutEffect(() => {
        if (open) getMemberList();
        return () => setMounted(false);
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog open={open} PaperProps={{ sx: { width: 400 } }}>
            <GradientColorHeader onClose={handleOnClose}>手動簽退</GradientColorHeader>
            <DialogContent className="py-4">
                {isMounted ? (
                    <Stack direction="column">
                        <AutocompleteField
                            ref={peopleRef}
                            options={options}
                            optionKeys={['name', 'nickName', 'mobile', 'no']}
                            optionMaskKeys={['maskName', 'nickName', 'maskMobile', 'no']}
                            primaryKey={'id'}
                            inputProps={{ placeholder: '請輸入姓名或手機號碼' }}
                            required
                        />
                    </Stack>
                ) : (
                    <Stack spacing={2} justifyContent="center">
                        <Skeleton variant="text" height={60} width={120} />
                        <Skeleton variant="text" height={60} width={320} />
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Stack spacing={2}>
                    <Button variant="outlined" onClick={handleOnClose}>
                        取消
                    </Button>
                    <Button variant="contained" onClick={habdleOnConfirm}>
                        確定
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default CheckoutDialog;
