import React from 'react';
import {Box} from '@common/components/';
import {isEmpty} from '@util/utils';

const CycleOrderListBox = (props) => {
    const { orderData = [], shippingDayText = '10' } = props;
    return (
        <React.Fragment>
            <Box className={'font-color-3'}>{`出貨週期：${shippingDayText}日`}</Box>
            {!isEmpty(orderData) && (
                <Box>
                    {orderData.map(({ cycleOrderItemID, productName, quantity = 1 }) => {
                        return <Box key={cycleOrderItemID}>{`${productName} x ${quantity}`}</Box>;
                    })}
                </Box>
            )}
        </React.Fragment>
    );
};

export default CycleOrderListBox;
