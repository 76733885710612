import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';
import Tablenization from '@icoach/components/Tablenization';
import { Checkbox, FormControlLabel, Stack, IconButton, Chip } from '@common/components/';
import { PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';
import { PageSortAndTotalBox, BusinessCardBox, MorePageBox, MemoControllerBox, PrivacyDataToggle, PrivacyDataToggleButton } from '@icoach/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import { inputCheckedAction } from '@util/utils';
import AppointmentOverviewMoreMenuOption from './AppointmentOverviewMoreMenuOption';
import AppointmentOverviewDialogBox from '../dialog/AppointmentOverviewDialogBox';
import useAppointment from '../components/useAppointment';
import { COLOR_MATCH } from '@icoach/accountBook/staticData';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import usePrivacyToggle, {securityMaskFilterTypeMap} from '@util/hook/usePrivacyToggle';

const AppointmentOverviewTable = React.memo(
    React.forwardRef((props, ref) => {
        const { sourceData = {}, setBookmarkNum, dialogRef } = useAppointment();
        const { list: rowsData = [], pageIndex = 1, totalPage = 1, pageSize = 0, totalCount = 0 } = sourceData || {};
        // checkbox group
        const [
            handleCheckAllChange,
            handleCheckedChange,
            selectedAllRef,
            selectedOptRef,
            indeterminateRef,
            // eslint-disable-next-line
            _changeIndeterminate,
            clearAllChecked,
        ] = useGroupCheckbox(rowsData);
        const handlePageChange = (e, num) => setBookmarkNum(num);
        const { isRevealed, toggle } = usePrivacyToggle();
        const rowsLabel = useMemo(
            () => {
                return [
                    {
                        cellKey: 'appointmentID',
                        isIcon: true,
                        formatCell: (cellValue, rowData, { chkRef, checkedOnChange = () => {} }) => {
                            return (
                                <Checkbox
                                    color="primary"
                                    onChange={checkedOnChange}
                                    value={cellValue}
                                    inputRef={(el) => {
                                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                                            chkRef.current.push(el);
                                        }
                                    }}
                                />
                            );
                        },
                    },
                    {
                        cellKey: 'peopleSheet',
                        headerLabel: '預約者',
                        align: 'left',
                        maxWidth: 16,
                        formatCell: (cellValue, { memberNo, displayName, maskDisplayName, imageSrc, sourceTypeText, peopleType }) => {
                            const resource = {
                                memberImg: imageSrc,
                                displayName,
                                maskDisplayName,
                                memberNo,
                                sourceTypeText,
                                peopleType,
                                isSourceType: String(peopleType) === '1',
                            };
                            return <BusinessCardBox sourceData={resource} isRevealed={isRevealed} isPrivate />;
                        },
                    },
                    {
                        cellKey: 'cellPhone',
                        headerLabel: '手機',
                        align: 'center',
                        formatCell: (cellPhone, { maskCellPhone }) => {
                            return <PrivacyDataToggle fullData={cellPhone} maskedData={maskCellPhone} isRevealed={isRevealed} />;
                        },
                    },
                    {
                        cellKey: 'appointmentDate',
                        headerLabel: '預約日期',
                        align: 'center',
                    },
                    {
                        cellKey: 'appointmentTime',
                        headerLabel: '預約時間',
                        align: 'center',
                    },
                    {
                        cellKey: 'appointmentTypeText',
                        headerLabel: '預約事項',
                        align: 'center',
                    },
                    {
                        cellKey: 'appointmentResultText',
                        headerLabel: '預約狀態',
                        align: 'center',
                        formatCell: (cellValue, { appointmentResultColor }) => {
                            return <Chip label={cellValue} color={COLOR_MATCH[appointmentResultColor]} />;
                        },
                    },
                    {
                        cellKey: 'notes',
                        headerLabel: '預約備註',
                        maxWidth: 16,
                        align: 'center',
                        formatCell: (cellValue) => {
                            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
                        },
                    },
                    {
                        cellKey: 'more',
                        align: 'center',
                        formatCell: (cellValue, { appointmentID, ...other }) => {
                            const resource = {
                                apoId: appointmentID,
                                ...other,
                            };
                            return <AppointmentOverviewMoreMenuOption {...resource} />;
                        },
                    },
                ];
            },
            // eslint-disable-next-line
            [isRevealed],
        );
        const handleToggle = useCallback(() => {
            const apiParams = {
                filterType: securityMaskFilterTypeMap.ClubAppointmentOverview,
                totalCount: rowsData.length,
            };
            toggle(apiParams);
        }, [toggle, rowsData]);

        const handleSMSGroupDialog = () => {
            let checkedItem = inputCheckedAction(selectedOptRef.current, rowsData, 'appointmentID');
            if (checkedItem.length === 0) return;
            checkedItem = checkedItem.map((item) => {
                const { peopleID, peopleType, appointmentID, name, nickName, appointmentDate, appointmentTime } = item;
                const combinedString = `${appointmentDate} ${appointmentTime}`;
                const apoDateTime = parseDate(combinedString, DateTimeStandard.DisplayFullBySlash);
                return { id: peopleID, type: peopleType, name: nickName || name, appointmentID, apoDateTime };
            });
            dialogRef.current.openSmsDialog(checkedItem, clearAllChecked);
        };

        return (
            <div>
                <Stack justifyContent="space-between" alignItems="center">
                    <div className={clsx('ml-2', rowsData.length === 0 && 'visibility-hidden')}>
                        <FormControlLabel
                            value="all"
                            label="全選"
                            control={
                                <Checkbox
                                    color="primary"
                                    indeterminateRef={indeterminateRef}
                                    inputRef={(el) => (selectedAllRef.current = el)}
                                    onChange={handleCheckAllChange}
                                />
                            }
                        />
                        <IconButton onClick={handleSMSGroupDialog}>
                            <PaperPlaneIcon />
                        </IconButton>
                        <PrivacyDataToggleButton className={'has-divider'} isRevealed={isRevealed} onToggle={handleToggle} />
                    </div>
                    <PageSortAndTotalBox pageIndex={pageIndex} pageSize={pageSize} totalCount={totalCount} isCurrentPageLastIndex />
                </Stack>
                <Tablenization
                    Pagination={MorePageBox}
                    cardProps={{ className: 'theme-gray orders-table' }}
                    count={totalPage}
                    page={pageIndex}
                    onChange={handlePageChange}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={rowsLabel} />
                    <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} chkRef={selectedOptRef} checkedOnChange={handleCheckedChange} />
                </Tablenization>
                <AppointmentOverviewDialogBox />
            </div>
        );
    }),
);

export default AppointmentOverviewTable;
