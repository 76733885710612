import React from 'react';
import { Card, Box } from '@common/components/';
import PropTypes from 'prop-types';
import CommonTabs from '@icoach/components/CommonTabs';
import clsx from 'clsx';

const TabsCardLayout = ({ className, tabs, activeTab, onTabChange, children, tabsContent, ...rest }) => {
    return (
        <Card className={clsx('tabs-content-layout', className)} {...rest}>
            <Box component={'header'} className={'page-header'}>
                <CommonTabs tabs={tabs} onTabChange={onTabChange} activeTab={activeTab} />
            </Box>
            <Card.CardContent {...tabsContent}>{children}</Card.CardContent>
        </Card>
    );
};

TabsCardLayout.prototype = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
            icon: PropTypes.element,
            component: PropTypes.elementType,
            to: PropTypes.string,
        }),
    ).isRequired,
    activeTab: PropTypes.string,
    onTabChange: PropTypes.func,
    children: PropTypes.node,
    tabsContent: PropTypes.object,
};

export default TabsCardLayout;
