import React, { useImperativeHandle } from 'react';
import _ from 'lodash';
import { Autocomplete } from '@mui/material';
import { OutlinedInput, FormLabel, Stack, FormHelperText, FormControl, Typography, Box } from '../';
import { isEmpty, findIndexFn } from '@util/utils';
import clsx from 'clsx';

// multiple 待處理 todo by Allen

const initValue = (props) => {
    const { value: _value, key, options } = props;
    let _index = -1;
    if (isEmpty(_value)) return undefined;
    _index = findIndexFn(options, key, _value);
    if (_index === -1) return undefined;
    return options[_index];
};

const initInputValue = (props) => {
    const { value: option, getOptionLabel } = props;
    if (isEmpty(option)) return '';
    return getOptionLabel(option);
};

const initMaskDict = (options, optionKeys, optionMaskKeys) => {
    if (_.isEmpty(optionMaskKeys)) return null;
    
    let dict = {};
    for (const option of options) {
        const key = getOptionText(option, optionKeys);
        dict[key] = getOptionText(option, optionMaskKeys);    
    }
    
    return dict;
};

const getOptionText = (option, optionKeys) => {
    return optionKeys
        .reduce((cur, _key) => {
            const _value = option[_key];
            let newAry = [...cur];
            if (_value) newAry.push(_value);
            return newAry;
        }, [])
        .join(', ');
};

// is uncontrolled Component
const AutocompleteField = React.forwardRef((props, ref) => {
    const {
        // Autocomplete use
        options = [],
        optionKeys = ['name', 'mobile'],
        optionMaskKeys = [],
        primaryKey = 'id',
        defaultValue: defaultValueProps = undefined,
        onChange: onChangeProps,
        label,
        name: nameProps,
        disabled = false,
        fullWidth = false,
        required = false,
        className,
        isError: propsError = false,
        lightColor = false,
        direction: directionProps = 'column',
        helperText = '此欄位為必填',
        InputProps = {},
        inputProps: inputPropsProps = {},
        FormLabelTip = '',
        noOptionsText = <Typography component="span">尚無資料</Typography>
    } = props;

    const getOptionLabel = React.useCallback(
        (_option) => {
            if (!Array.isArray(optionKeys)) {
                console.error('optionKeys must be Array');
                return 'error';
            }
            return getOptionText(_option, optionKeys);
        },
        [optionKeys],
    );

    const [isError, setError] = React.useState(propsError);
    const [defaultValue] = React.useState(initValue({ value: defaultValueProps, options, key: primaryKey }));
    const [value, setValue] = React.useState(defaultValue);
    const [inputValue, setInputValue] = React.useState(initInputValue({ value: defaultValue, getOptionLabel }));
    const inputRef = React.useRef(null);
    const maskDict = React.useRef(null);

    React.useLayoutEffect(() => {
        if (isEmpty(primaryKey)) console.error('Please give primaryKey');
        // eslint-disable-next-line
    }, []);

    React.useLayoutEffect(() => {
        maskDict.current = initMaskDict(options, optionKeys, optionMaskKeys);
        // eslint-disable-next-line
    }, [options]);
    
    

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: (_key = '') => {
                    if (!value) return '';
                    return _key ? value[_key] : value;
                },
                isError: () => {
                    let error = required;
                    if (!_.isEmpty(value)) error = false;
                    setError(error);
                    return error;
                },
                getName: () => nameProps,
            };
        },
        // eslint-disable-next-line
        [value],
    );

    const onInputChange = (event, newInputValue) => setInputValue(newInputValue);

    const onChange = (event, newValue) => {
        setValue(newValue);
        onChangeProps && onChangeProps(newValue);
    };

    const isOptionEqualToValue = (_option, _value) => {
        const targetKey = _option?.[primaryKey] ?? null;
        const targetValue = _value?.[primaryKey] ?? null;
        return String(targetKey) === String(targetValue);
    };

    const renderOption = (props, _option) => {
        const { key: originalLabel } = props;
        let displayLabel = '';
        if (!_.isEmpty(optionMaskKeys)) {
            displayLabel = getOptionText(_option, optionMaskKeys);
        }

        if (_.isEmpty(displayLabel)) {
            displayLabel = originalLabel;
        }

        return (
            <Box {...props} key={`${_option[primaryKey]}_${displayLabel}`}>
                <Typography component="span">{displayLabel}</Typography>
            </Box>
        );
    };

    const renderInput = ({ inputProps, ...params }) => {

        const { value: originalValue } = inputProps;

        if (!_.isEmpty(maskDict.current) && originalValue !== '' && maskDict.current[originalValue]) {
            inputProps.value = maskDict.current[inputProps.value];
        }
        
        const defaultProperty = {
            disabled: params.disabled,
            fullWidth: params.fullWidth,
            InputProps: { ...params.InputProps, ...InputProps },
            inputProps: { ...inputProps, ...inputPropsProps },
            ...params,
            name: nameProps,
        };

        return <OutlinedInput {...defaultProperty} inputRef={inputRef} />;
    };

    const autoCompleteProperty = {
        defaultValue,
        options: options,
        onChange: onChange,
        inputValue: inputValue,
        onInputChange: onInputChange,
        getOptionLabel: getOptionLabel,
        isOptionEqualToValue: isOptionEqualToValue,
        renderInput: renderInput,
        renderOption: renderOption,
        noOptionsText: noOptionsText,
        ...InputProps,
    };

    const formLabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    return (
        <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
            required={required}
            className={clsx('autocomplete-field', className)}
            direction={directionProps}
            error={isError}
        >
            {label && <FormLabel {...formLabelProperties}>{label}</FormLabel>}
            <Stack direction="column" className="field-input-root">
                <Autocomplete {...autoCompleteProperty} />
                {isError && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default AutocompleteField;
