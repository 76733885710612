import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Eye = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,2.7C8.6,2.7,5.9,4.2,4,6C2,7.8,0.7,10,0.1,11.5c-0.1,0.3-0.1,0.7,0,1C0.7,14,2,16.2,4,18c2,1.8,4.7,3.4,8,3.4
	s6.1-1.5,8-3.4c1.9-1.8,3.3-4,3.9-5.5c0.1-0.3,0.1-0.7,0-1C23.3,10,22,7.8,20,6C18.1,4.2,15.4,2.7,12,2.7z M6,12c0-3.3,2.7-6,6-6
	s6,2.7,6,6s-2.7,6-6,6S6,15.3,6,12z M12,9.3c0,1.5-1.2,2.7-2.7,2.7c-0.3,0-0.6,0-0.8-0.1C8.3,11.8,8,11.9,8,12.2
	c0,0.3,0.1,0.6,0.1,0.9c0.6,2.1,2.8,3.4,4.9,2.8c2.1-0.6,3.4-2.8,2.8-4.9c-0.5-1.7-2-2.9-3.7-3c-0.2,0-0.4,0.3-0.3,0.5
	C11.9,8.8,12,9,12,9.3L12,9.3z"
            />
        </SvgIcon>
    );
});

export default Eye;
