import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import EmployeesContext from '@icoach/employees/compoments/EmployeesContext';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import EmployeesOverivewTable from '@icoach/employees/tables/EmployeesOverivewTable';
import { Card, MenuItem, SelectField, Stack, TextField, Button, Form } from '@common/components/';
import useEmployeeApi from '@apis/useEmployeeApi';
import { HUMAN_RESOURCES_ADD } from '@icoach/router/routerPath';

export const CURRENT_PATH_ROUTES = 'employees-overview';

const EmployeesOverviewSearchBar = (props) => {
    const { className, options = [] } = props;
    const { setSourceData, setRefresh, bookmark } = useEmployees();
    const { getEmployeesOverviewApi } = useEmployeeApi();
    const history = useHistory();
    const employeeRef = useRef(null);
    const statusRef = useRef(null);
    const isPreloadRef = useRef(true);

    // targetPage 頁碼 後蓋前
    const getAllParams = (targetPage = {}) => {
        let params = {
            name: employeeRef.current.getResult(),
            employeeStatus: statusRef.current.getResult(),
            pageIndex: 1,
            pageSize: 10,
        };
        Object.assign(params, targetPage);
        return params;
    };

    const handleSubmitClick = () => {
        getSearchEmployeesApi(getAllParams());
    };

    const handleAddEmployeeClick = () => {
        history.push(HUMAN_RESOURCES_ADD);
    };

    const getSearchEmployeesApi = async (params) => {
        const resp = await getEmployeesOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
    };

    setRefresh(() => getSearchEmployeesApi(getAllParams()));

    useEffect(
        () => {
            if (!isPreloadRef.current) {
                getSearchEmployeesApi(getAllParams(bookmark));
            }
            isPreloadRef.current = false;
        },
        // eslint-disable-next-line
        [bookmark]
    );

    return (
        <React.Fragment>
            <Stack justifyContent={'end'} className="mb-2">
                <Button variant={'outlined'} onClick={handleAddEmployeeClick}>
                    新增員工
                </Button>
            </Stack>
            <Card className={className}>
                <Card.CardContent className={'px-4  py-3'}>
                    <Form onSubmit={handleSubmitClick}>
                        <Stack justifyContent={'space-between'} alignItems={'end'} spacing={3}>
                            <Grid spacing={3} container>
                                <Grid xs={6} item>
                                    <TextField ref={employeeRef} label={'員工姓名'} direction={'row'} fullWidth />
                                </Grid>
                                <Grid xs={6} item>
                                    <SelectField ref={statusRef} label="狀態" defaultValue="1" direction={'row'} fullWidth displayEmpty required>
                                        {options.map(({ text, value, disabled }) => (
                                            <MenuItem value={value} key={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                </Grid>
                            </Grid>
                            <Button className={'mb-1 white-space-nowrap'} variant={'contained'} type="submit">
                                送出
                            </Button>
                        </Stack>
                    </Form>
                </Card.CardContent>
            </Card>
        </React.Fragment>
    );
};

const EmployeesOverviewContent = React.forwardRef((props, ref) => {
    const [options, setOptions] = useState([]);
    const [sourceData, setSourceData] = useState(null);
    const [bookmark, setBookmark] = useState({
        pageIndex: 1,
        pageSize: 10,
    });
    const refresh = useRef(null);
    const { getEmployeesStatusOptionsApi } = useEmployeeApi();
    const initOptionsApi = async () => {
        const resp = await getEmployeesStatusOptionsApi();
        resp && setOptions(resp);
    };

    useEffect(
        () => {
            initOptionsApi();
        },
        // eslint-disable-next-line
        []
    );

    const childContext = {
        sourceData: sourceData,
        setSourceData: setSourceData,
        bookmark,
        setBookmark: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        refresh: refresh,
        setRefresh: (el) => (refresh.current = el),
    };

    return (
        <EmployeesContext.Provider value={childContext}>
            <React.Fragment>
                {!_.isEmpty(options) ? (
                    <React.Fragment>
                        <EmployeesOverviewSearchBar className={'mb-3'} options={options} />
                        <EmployeesOverivewTable />
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        </EmployeesContext.Provider>
    );
});

export default EmployeesOverviewContent;
