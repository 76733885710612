import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from '@util/utils';

/**
 * 判斷目前路徑是否符合白名單
 * @param {string} pathname 目前路徑
 * @param {Array<string>} whitelist 白名單路徑列表
 * @returns {boolean} 是否匹配
 */
const matchWhitelist = (pathname, whitelist) => {
    if (isEmpty(whitelist)) return false;
    return whitelist.some((path) => {
        try {
            const basePath = new URL(path, window.location.origin).pathname;
            return pathname.startsWith(basePath);
        } catch {
            return pathname.startsWith(path);
        }
    });
};

/**
 * useNavigationGuard
 * @param {Object} config
 * @param {boolean} config.isGuardEnabled 是否啟用導覽攔截
 * @param {Function} config.onLeave 頁面離開時的自訂回呼
 * @param {Array<string>} config.whitelist 白名單路徑列表
 * @returns {Object} 傳回攔截狀態與導覽控制函數
 */
const useNavigationGuard = (config) => {
    const { isGuardEnabled = true, onLeave, whitelist = [] } = config || {};
    const history = useHistory();
    const location = useLocation();
    const pendingNavigationRef = useRef(null);
    const lastLocationRef = useRef(location.pathname);
    const isBlockingRef = useRef(true);
    const [navigationKey, setNavigationKey] = useState(null);

    const confirmNavigation = async () => {
        if (onLeave) await onLeave();

        if (pendingNavigationRef.current) {
            lastLocationRef.current = pendingNavigationRef.current;

            isBlockingRef.current = false;
            setNavigationKey(null);
            history.replace(pendingNavigationRef.current);
            isBlockingRef.current = true;

            pendingNavigationRef.current = null;
        }
    };

    const cancelNavigation = () => {
        pendingNavigationRef.current = null;

        isBlockingRef.current = false; // 暫時關閉攔截
        setNavigationKey(null);
        history.replace(lastLocationRef.current);
        isBlockingRef.current = true; // 恢復攔截
    };

    useEffect(() => {
        isBlockingRef.current = isGuardEnabled;
    }, [isGuardEnabled]);

    useEffect(
        () => {
            if (!isGuardEnabled) return;

            const unblock = history.block((nextLocation) => {
                if (!isBlockingRef.current) return true;

                if (matchWhitelist(nextLocation.pathname, whitelist)) {
                    lastLocationRef.current = nextLocation.pathname;
                    return true;
                }

                pendingNavigationRef.current = nextLocation.pathname;

                setTimeout(() => {
                    isBlockingRef.current = false;
                    history.replace(lastLocationRef.current);
                    isBlockingRef.current = true;
                }, 0);

                setNavigationKey(Date.now());
                return false;
            });

            const handleBeforeUnload = (e) => {
                if (isGuardEnabled) {
                    e.preventDefault();
                    e.returnValue = '';
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                unblock();
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        },
        // eslint-disable-next-line
        [isGuardEnabled, history, whitelist],
    );

    return {
        navigationKey,
        pendingNavigation: pendingNavigationRef.current,
        confirmNavigation,
        cancelNavigation,
        lastLocation: lastLocationRef.current,
    };
};

export default useNavigationGuard;
