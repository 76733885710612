import { PURCHASE_RECEIPT_ORDER_PATH, PURCHASE_RECEIPT_ORDER_ROUTER, PURCHASE_RECEIPT_OVERVIEW_ROUTER } from '@icoach/router/routerPath';
import { PurchaseReceiptOrderPage, PurchaseReceiptOverviewPage } from '@icoach/purchaseReceipt';

const purchaseReceiptRouter = [
    {
        description: '進貨管理',
        // frontKey: 'C13X01',
        frontKey: 'CXX999001',
    },
    {
        description: '進貨單總覽',
        static: true,
        path: PURCHASE_RECEIPT_OVERVIEW_ROUTER,
        href: PURCHASE_RECEIPT_OVERVIEW_ROUTER,
        // frontKey: 'C13X02',
        frontKey: 'CXX999002',
        as: PurchaseReceiptOverviewPage,
    },
    {
        description: '進貨單',
        static: true,
        path: PURCHASE_RECEIPT_ORDER_ROUTER,
        href: PURCHASE_RECEIPT_ORDER_PATH,
        // frontKey: 'C13X03',
        frontKey: 'CXX999003',
        as: PurchaseReceiptOrderPage,
    },
];

export default purchaseReceiptRouter;
