import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { QuestionCircle as QuestionCircleIcon } from '@common/SvgIcon/';

const InteractiveIcon = ({ className, icon = <QuestionCircleIcon />, onClick, iconSize = '23px', style, ...rest }) => {
    return cloneElement(icon, { style: { ...style, fontSize: iconSize }, className, onClick, ...rest });
};

InteractiveIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    iconSize: PropTypes.string,
    style: PropTypes.object,
};

export default InteractiveIcon;
