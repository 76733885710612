import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Box } from '@common/components/';
import InBodyInterviewCancelMeasureDialog from '@icoach/inbody/components/InBodyInterviewCancelMeasureDialog';
import InBodyInterviewImpedanceDialog from '@icoach/inbody/components/InBodyInterviewImpedanceDialog';
import inBodyImage from '@icoach/assets/img/image-7.png';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';
import InBodyInterviewAddMeasureDialog from '@icoach/inbody/components/InBodyInterviewAddMeasureDialog';

const CANCEL_MEASURE_DIALOG = 'cancelMeasure';
const ADD_MEASURE_DIALOG = 'addMeasure';
const UNUSUAL_IMPEDANCE_DIALOG = 'unusualImpedance';

const InBodyMeasurementProcessHandler = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { peopleID, referenceType, peopleData, isInterviewNotMeasure, refresh } = useInBodyContext();
    const [openDialog, setOpenDialog] = useState(null);
    const sourceDataRef = useRef(null);
    const prevMeasureParamRef = useRef(null);
    const refreshRef = useRef(null);
    const addMeasureDialogRef = useRef(null);
    const dialogRef = useRef({
        openInBodyInterviewAddMeasureDialog: (targetData, refresh) => {
            if (targetData) sourceDataRef.current = targetData;
            refreshRef.current = refresh;
            setOpenDialog(ADD_MEASURE_DIALOG);
        },
        openInBodyInterviewCancelMeasureDialog: (targetData, refresh) => {
            sourceDataRef.current = targetData;
            refreshRef.current = refresh;
            setOpenDialog(CANCEL_MEASURE_DIALOG);
        },
        openInBodyInterviewImpedanceDialog: (targetData, refresh) => {
            sourceDataRef.current = targetData;
            refreshRef.current = refresh;
            setOpenDialog(UNUSUAL_IMPEDANCE_DIALOG);
        },
    });

    const handleCloseDialog = () => {
        if (addMeasureDialogRef.current && addMeasureDialogRef.current.getResult) {
            prevMeasureParamRef.current = addMeasureDialogRef.current.getResult();
        }
        setOpenDialog(false);
    };

    const doRefresh = (...rest) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') {
            setTimeout(() => refreshRef.current(...rest), 5);
        }
    };

    const AddMeasureDialog = (params) => {
        if (isInterviewNotMeasure) {
            enqueueSnackbar('已勾選只面談不量身，無法新增量身', { variant: 'info' });
        } else {
            dialogRef.current.openInBodyInterviewAddMeasureDialog(params, refresh);
        }
    };

    const handleOpenAddMeasureDialog = () => {
        AddMeasureDialog({
            referenceType,
            peopleID,
            height: peopleData?.height || 0,
        });
    };

    const handleConfirmAgainInBodyMeasure = () => {
        if (prevMeasureParamRef.current) {
            AddMeasureDialog(prevMeasureParamRef.current);
        } else {
            handleOpenAddMeasureDialog();
        }
    };

    useEffect(
        () => {
            return () => handleCloseDialog();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Button className={'measure-weight-button'} variant="contained" onClick={handleOpenAddMeasureDialog}>
                <React.Fragment>
                    <Box component={'img'} src={inBodyImage} />
                    進行量身
                </React.Fragment>
            </Button>
            <InBodyInterviewAddMeasureDialog
                ref={addMeasureDialogRef}
                open={openDialog === ADD_MEASURE_DIALOG}
                onClose={handleCloseDialog}
                sourceData={sourceDataRef.current}
                refresh={doRefresh}
                dialogRef={dialogRef}
            />
            <InBodyInterviewCancelMeasureDialog open={openDialog === CANCEL_MEASURE_DIALOG} onClose={handleCloseDialog} refresh={doRefresh} />
            <InBodyInterviewImpedanceDialog
                open={openDialog === UNUSUAL_IMPEDANCE_DIALOG}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmAgainInBodyMeasure}
            />
        </React.Fragment>
    );
};

export default InBodyMeasurementProcessHandler;
