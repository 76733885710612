import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAjax from '@apis/useAjax';
import { localUser } from '@util/utils';
import { getHistoryLockData } from '@redux/historyLock/selector';
import {
    POST_AUTH_LOGIN_API,
    GET_AUTH_LOGOUT_API,
    GET_ME_CHECK_API,
    GET_ME_API,
    GET_AUTH_USER_ME_MAIL_FORGOT_PASSWORD_API,
    GET_AUTH_USER_ME_CHECK_FORGOT_PASSWORD_TICKET_API,
} from '@apis/apiPath';

const useAuthApi = () => {
    const Ajax = useAjax();
    const history = useHistory();
    const isHistoryLock = useSelector(getHistoryLockData);

    const postLoginApi = useCallback(
        async (params, other) => {
            await Ajax.post(POST_AUTH_LOGIN_API, params, other);
        },
        // eslint-disable-next-line
        []
    );

    const getLogoutApi = useCallback(
        async () => {
            const { isLock, fn } = isHistoryLock;
            if (isLock) {
                if (window.confirm('此動作將會遺失資料')) {
                    window.removeEventListener('beforeunload', fn);
                } else {
                    return null;
                }
            }
            localUser.logout(); // 要在API之前清除
            await Ajax.get(
                GET_AUTH_LOGOUT_API,
                {},
                {
                    callbackfn: (resultData, respBody) => {
                        const { success, statusCode } = respBody.data;
                        if (success && statusCode === '0000') {
                            history.push('/');
                            window.location.reload();
                        } else {
                            alert('登出失敗請再操作一次');
                        }
                    },
                }
            );
        },
        // eslint-disable-next-line
        [isHistoryLock]
    );

    // 檢查是否有token可以直接帶入到主畫面
    const getAuthMeCheckApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_ME_CHECK_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得該店店舖資料
    const getAuthMeDataApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_ME_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 店舖忘記密碼重設，寄到使用者的信箱點擊重設
    const getAuthUserMeMailForgotPasswordApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_AUTH_USER_ME_MAIL_FORGOT_PASSWORD_API(params));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 忘記密碼頁面檢查token是否有效
    const getCheckAuthUserTokenApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_AUTH_USER_ME_CHECK_FORGOT_PASSWORD_TICKET_API(params));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        postLoginApi,
        getLogoutApi,
        getAuthMeCheckApi,
        getAuthMeDataApi,
        getAuthUserMeMailForgotPasswordApi,
        getCheckAuthUserTokenApi,
    };
};

export default useAuthApi;
