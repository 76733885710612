import React from 'react';
import PropTypes from 'prop-types';
import useForkRef from '@util/hook/useForkRef';

const Box = React.forwardRef((props, ref) => {
    const { children, className, style = {}, component: Component = 'div', ...other } = props;

    const boxRef = React.useRef(null);
    const combinedRef = useForkRef(ref, boxRef);

    return (
        <Component className={className} style={style} ref={combinedRef} {...other}>
            {children}
        </Component>
    );
});

// 添加 PropTypes 驗證
Box.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    component: PropTypes.elementType, // React 組件類型或標籤名稱
};

export default Box;
