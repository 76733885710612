import React from 'react';
import clsx from 'clsx';
import { TemporariesDeleteDialog } from '@icoach/nonMember/dialog/';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import { customerTypeRouterMap } from '@icoach/nonMember/staticData';
import { MoreLabelBox } from '@icoach/components/';
import { NON_MEMBER_TEMPORARIES_CUSTOMERTYPE_INFO_URL } from '@icoach/router/NonMemberRouter';
import Tablenization from '@icoach/components/Tablenization';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import { parseDate } from '@util/moment';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import useTemporaryApi from '@apis/useTemporaryApi';
import { FormControlLabel, Checkbox } from '@common/components/';
import { RemoveRedEye as RemoveRedEyeIcon, Trash as TrashIcon, DocumentFile as DocumentFileIcon, PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';
import useToggleDialog from '@util/hook/useToggleDialog';

const dialogType = {
    sendSMS: 'sendSMS',
    deleteTemorary: 'deletetemporary',
};

const rowsLabel = [
    {
        cellKey: 'temporaryID',
        headerLabel: '',
        isIcon: true,
        formatCell: (cellValue, _rowData, { chkRef, checkedOnChange }) => {
            return (
                <Checkbox
                    color="primary"
                    onChange={checkedOnChange}
                    value={cellValue}
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: 'name',
        headerLabel: '標題',
        style: { width: 350 },
    },
    {
        cellKey: 'createdDate',
        headerLabel: '建立日期',
        style: { width: 170 },
        formatCell: (cellValue) => parseDate(cellValue, 'YYYY-MM-DD HH:mm:ss'),
    },
    {
        cellKey: 'customerTypeText',
        headerLabel: '篩選名單',
        style: { width: 150 },
    },
    {
        cellKey: 'count',
        headerLabel: '筆數',
        align: 'center',
        style: { width: 80 },
    },
    {
        cellKey: 'customerType',
        align: 'right',
        formatCell: (cellValue, rowsData, { handleOpenDialog }) => {
            return <MoreLabel sourceData={rowsData} customerType={cellValue} handleOpenDialog={handleOpenDialog} />;
        },
    },
];

const IconMap = {
    deletetemporary: {
        description: '將顧客或名單人員設定為無效',
        Icon: <TrashIcon />,
        Dialog: TemporariesDeleteDialog,
    },
};

const MoreLabel = (props) => {
    const { sourceData, customerType, handleOpenDialog } = props;
    const { getTemorariesExcelApi } = useTemporaryApi();
    const moreLabelMap = {
        review: {
            text: '查看',
            icon: <RemoveRedEyeIcon />,
            to: ({ urls }) => `${urls['review']}`,
        },
        sms: {
            text: '發送簡訊',
            icon: <PaperPlaneIcon />,
            onClickEvent: () => handleOpenDialog(dialogType.sendSMS, { affixParams: { temporaryID: sourceData.temporaryID } }),
        },
        export: {
            text: '匯出Excel',
            icon: <DocumentFileIcon />,
            onClickEvent: () => {
                getTemorariesExcelApi(sourceData.temporaryID);
            },
        },
        deletetemporary: {
            text: '刪除',
            icon: <TrashIcon />,
            onClickEvent: () => handleOpenDialog(dialogType.deleteTemorary, { sourceData: [sourceData] }),
        },
    };

    return (
        <MoreLabelBox
            sourceData={[sourceData]}
            moreLabelMap={moreLabelMap}
            moreActionArray={['review', 'sms', 'export', 'deletetemporary']}
            urls={{
                review: `${NON_MEMBER_TEMPORARIES_CUSTOMERTYPE_INFO_URL(customerTypeRouterMap[customerType])}${sourceData.temporaryID}`,
            }}
        />
    );
};

const TemporariesTable = () => {
    const { openTarget, sourceData, affixParams, handleCloseDialog, handleOpenDialog } = useToggleDialog();
    const { tableData, setBookmarkNum, refresh } = useNonMember();
    const { pageIndex: page, totalPage: count } = tableData;
    let { list: rowsData = [] } = tableData;
    rowsData = rowsData || [];
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(rowsData);

    const handlePageChange = (e, num) => {
        setBookmarkNum(num);
    };

    return (
        <div className="customer-table">
            <div className="customer-table-action">
                <div className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader
                        IconMap={IconMap}
                        filterKey={'temporaryID'}
                        viewIconArray={['deletetemporary', 'sms']}
                        selectedRef={selectedOptRef}
                        rowsData={rowsData}
                        refresh={refresh.current}
                    />
                </div>
            </div>
            <div>
                <Tablenization isPagination page={page} count={count} onChange={handlePageChange}>
                    <Tablenization.TableRowHeader headerRow={rowsLabel} />
                    <Tablenization.TableRowBody
                        headerRow={rowsLabel}
                        rowsData={rowsData}
                        chkRef={selectedOptRef}
                        checkedOnChange={handleCheckedChange}
                        handleOpenDialog={handleOpenDialog}
                    />
                </Tablenization>
            </div>
            <TemporariesDeleteDialog open={openTarget === dialogType.deleteTemorary} sourceData={sourceData} onClose={handleCloseDialog} refresh={refresh} />
            <SendSmsDialog
                open={openTarget === dialogType.sendSMS}
                affixParams={affixParams}
                sourceData={null}
                onClose={handleCloseDialog}
                refresh={refresh}
                isTemporaries
            />
        </div>
    );
};

export default TemporariesTable;
