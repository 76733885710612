import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import Tablenization from '@icoach/components/Tablenization';
import { MoreLabelBox, PrivacyDataToggle, PrivacyDataToggleButton } from '@icoach/components';
import { HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH } from '@icoach/router/routerPath';
import { Chip, Stack, Tooltip, Box } from '@common/components/';
import usePrivacyToggle, { securityMaskFilterTypeMap } from '@util/hook/usePrivacyToggle';
import { ListInfo } from '@icoach/accountBook/components';
import useMessageDialog from '@util/hook/useMessageDialog';
import useAuthUserApi from '@apis/useAuthUserApi';

const MoreLabel = ({ nickName, employeeID, canChangePassword, ...props }) => {
    const { putEmployeeForgotPasswordApi } = useAuthUserApi();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const setMessageDialog = useMessageDialog();

    const handleAsyncConfirm = (_e, onClose) => {
        const params = { employeeID };
        employeeForgotPasswordApi(params, onClose);
    };

    const handleOpenIsResetPassword = useCallback(
        (e) => {
            setMessageDialog({
                open: true,
                title: '密碼重設',
                MsgComp: () => (
                    <Box className="text-center">
                        確認要重設
                        <Box component="span" className="font-weight-bold px-1">
                            {nickName}
                        </Box>
                        的密碼嗎？
                    </Box>
                ),
                onAsyncConfirm: handleAsyncConfirm,
            });
        },
        // eslint-disable-next-line
        [nickName]
    );

    const employeeForgotPasswordApi = async (params, onClose) => {
        const resp = await putEmployeeForgotPasswordApi(params);
        if (resp) {
            _snackbar(`${nickName}密碼已重設`, { variant: 'success' });
            onClose();
        }
    };

    const moreLabelMap = useMemo(
        () => {
            const targetMoreLabelData = {
                edit: {
                    text: '查看',
                    to: ({ urls }) => urls,
                },
            };

            if (canChangePassword) {
                Object.assign(targetMoreLabelData, {
                    'reset-password': {
                        text: '密碼重設',
                        onClickEvent: handleOpenIsResetPassword,
                    },
                });
            }

            return targetMoreLabelData;
        },
        // eslint-disable-next-line
        [canChangePassword]
    );

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap} textAlign={'center'} />;
};

const EmployeesOverivewTable = ({ className }) => {
    const { sourceData, setBookmark, refresh } = useEmployees();
    const { list: rowsData = [], totalPage: count = 0, pageIndex: page = 1 } = sourceData || {};

    const { isRevealed, toggle } = usePrivacyToggle();

    const labelTitle = useMemo(
        () => [
            {
                cellKey: 'employeeNo',
                headerLabel: '員工編號',
                align: 'center',
                formatCell: (cellValue, { employeeID }) => (
                    <Stack direction={'column'}>
                        <Link className={'text-decoration-underline text-secondary mb-1'} to={HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH(employeeID)}>
                            {cellValue}
                        </Link>
                    </Stack>
                ),
            },
            {
                cellKey: 'name',
                headerLabel: '姓名',
            },
            {
                cellKey: 'nickName',
                headerLabel: '名牌名稱',
            },
            {
                cellKey: 'statusText',
                headerLabel: '狀態',
                align: 'center',
                formatCell: (cellValue) => {
                    return <Chip label={cellValue} />;
                },
            },
            {
                cellKey: 'jobTitleText',
                headerLabel: '職稱',
            },
            {
                cellKey: 'mobile',
                headerLabel: '手機',
                formatCell: (mobile, { maskCellPhone }) => {
                    return <PrivacyDataToggle fullData={mobile} maskedData={maskCellPhone} isRevealed={isRevealed} />;
                },
            },
            {
                cellKey: 'more',
                headerLabel: '',
                align: 'right',
                isIcon: true,
                formatCell: (cellValue, rowData) => {
                    return (
                        <MoreLabel
                            moreActionArray={['edit', 'reset-password']}
                            {...(rowData || {})}
                            urls={HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH(rowData.employeeID)}
                        />
                    );
                },
            },
        ],
        // eslint-disable-next-line
        [isRevealed]
    );

    const handleToggle = useCallback(() => {
        const apiParams = {
            filterType: securityMaskFilterTypeMap.ClubEmployeeOverview,
            totalCount: rowsData.length,
        };
        toggle(apiParams);
    }, [toggle, rowsData]);

    const handlePageChange = (e, num) => {
        setBookmark(num);
    };

    useEffect(
        () => {
            refresh && refresh.current && refresh.current();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Stack alignItems={'center'} justifyContent={'space-between'}>
                <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                    <PrivacyDataToggleButton isRevealed={isRevealed} onToggle={handleToggle} />
                </Tooltip>
                <ListInfo
                    pageSize={sourceData?.pageSize ?? 0}
                    pageIndex={sourceData?.pageIndex ?? 0}
                    totalCount={sourceData?.totalCount ?? 0}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <Tablenization cardProps={{ className: className }} onChange={handlePageChange} count={count} page={page} isPagination>
                <Tablenization.TableRowHeader headerRow={labelTitle} />
                <Tablenization.TableRowBody headerRow={labelTitle} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default EmployeesOverivewTable;
