import React, { useState, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Tab, Tabs, Box } from '@common/components/';
import { TABS_CONFIG } from '@icoach/employees/EmployeesTabsConfig';
import useMenusControlled from '@util/hook/useMenusControlled';

const EmployeesPage = React.forwardRef((props, ref) => {
    const { tabs } = useParams();
    const [tabsIndex, setTabsIndex] = useState(tabs || TABS_CONFIG[0].key);
    const menulist = useMenusControlled(TABS_CONFIG);

    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    // 使用 useMemo 優化 Tab 配置渲染
    const renderedTabs = useMemo(
        () => {
            return menulist.map(({ key, label, icon, to }) => (
                <Tab key={key} className="py-3" label={label} value={key} icon={icon} component={NavLink} to={to} />
            ));
        },
        // eslint-disable-next-line
        []
    );

    const CurrentComponent = useMemo(
        () => {
            return TABS_CONFIG.find((tab) => tab.key === tabsIndex)?.component || null;
        },
        // eslint-disable-next-line
        [tabsIndex]
    );

    return (
        <Box className="container main-container-spacing">
            <Box className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    {renderedTabs}
                </Tabs>
            </Box>
            {CurrentComponent && <CurrentComponent />}
        </Box>
    );
});

export default EmployeesPage;
