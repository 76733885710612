import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { StepperProvider } from './StepperContext';
import clsx from 'clsx';
import StepConnector from '../stepConnector/StepConnector';

const defaultConnector = <StepConnector />;

const Stepper = forwardRef((props, ref) => {
    const {
        activeStep = 0,
        alternativeLabel = false,
        children,
        className,
        component: Component = 'div',
        connector = defaultConnector,
        nonLinear = false,
        orientation = 'horizontal',
        ...rest
    } = props;

    const childrenArray = React.Children.toArray(children).filter(Boolean);
    const steps = childrenArray.map((step, index) => {
        return React.cloneElement(step, {
            index,
            last: index + 1 === childrenArray.length,
            ...step.props,
        });
    });

    const contextValue = useMemo(
        () => ({ activeStep, alternativeLabel, connector, nonLinear, orientation }),
        [activeStep, alternativeLabel, connector, nonLinear, orientation],
    );

    const rootClasses = clsx(
        'stepper',
        {
            'stepper--horizontal': orientation === 'horizontal',
            'stepper--vertical': orientation === 'vertical',
            'stepper--alternative-label': alternativeLabel,
        },
        className,
    );

    return (
        <StepperProvider value={contextValue}>
            <Component ref={ref} className={rootClasses} {...rest}>
                {steps}
            </Component>
        </StepperProvider>
    );
});

Stepper.propTypes = {
    activeStep: PropTypes.number,
    alternativeLabel: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    component: PropTypes.elementType,
    connector: PropTypes.node,
    nonLinear: PropTypes.bool,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
};
export default Stepper;
