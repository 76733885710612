import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@common/components/';

const PageHeader = ({ className, title, actions, children, ...rest }) => {
    return (
        <Box component={'header'} className={clsx('page-header', className)} {...rest}>
            <Typography className={'page-header-title'} variant={'h3'}>
                {title}
            </Typography>
            {children}
            {actions && (
                <Box className="btn-group">
                    {actions.map(({ label, variant, onClick, ...rest }) => (
                        <Button key={label} variant={variant} onClick={onClick} {...rest}>
                            {label}
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
};

PageHeader.prototype = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
            onClick: PropTypes.func.isRequired,
        }),
    ),
    children: PropTypes.node,
};

export default PageHeader;
