import { sumFieldsInList } from '@util/sumFieldsInList';
import { isEmpty } from '@util/utils';

/**
 * 生成總計行的數據
 *
 * @param {Object} params - 傳遞的參數對象
 * @param {Array<Object>} params.list - 包含需要計算的資料列表
 * @param {Array<string>} params.sumFields - 需要加總的欄位名稱列表
 * @param {string} params.summaryKey - 總計行的鍵名，用於標識總計行的欄位
 * @param {string} [params.summaryValue='總計'] - 總計行的值，默認為 '總計'
 * @param {Object} [params.extraFields={}] - 額外的鍵值對，用於補充更多的數據
 * @returns {Array<Object>} summaryRows - 總計行的數據陣列，包含一個或多個總計行物件
 * @property {boolean} summaryRows[].isSummaryRow - 固定標記，用於標識此行為總計行
 * @property {string} summaryRows[][summaryKey] - 總計行的標題欄位名稱和值
 * @property {Object} summaryRows[].sumData - 加總欄位的計算結果
 * @property {Object} summaryRows[].extraFields - 額外的自定義欄位數據
 *
 * @example
 * const list = [
 *     { quantity: 10, alreadyInventoryQuantity: 5 },
 *     { quantity: 20, alreadyInventoryQuantity: 15 }
 * ];
 * const sumFields = ['quantity', 'alreadyInventoryQuantity'];
 * const summaryRow = generateSummaryRow({
 *     list,
 *     sumFields,
 *     summaryKey: 'productName',
 *     summaryValue: '總計',
 *     extraFields: { className: 'summary-row-class' }
 * });
 * // Result:
 * // {
 * //     isSummaryRow: true,
 * //     productName: '總計',
 * //     quantity: 30,
 * //     alreadyInventoryQuantity: 20,
 * //     className: 'summary-row-class'
 * // }
 */
export const generateSummaryRow = ({ list, sumFields, summaryKey, summaryValue = '總計', extraFields = {} }) => {
    let sumData = {};
    if (!isEmpty(list) && !isEmpty(sumFields)) {
        sumData = sumFieldsInList(list, sumFields);
    }

    return [
        {
            isSummaryRow: true,
            [summaryKey]: summaryValue,
            ...sumData,
            ...extraFields,
        },
    ];
};
