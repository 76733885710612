import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { disableInactivityTimer, enableInactivityTimer } from '@redux/inactivityTimer/action';

/**
 * useInactivityTimerToggle
 * 用於在特定頁面動態禁用和啟用計時器。
 * @param {boolean} isDisabled 是否禁用計時器
 */
const useInactivityTimerToggle = (isDisabled = true) => {
    const dispatch = useDispatch();

    useEffect(
        () => {
            if (isDisabled) {
                dispatch(disableInactivityTimer());
            } else {
                dispatch(enableInactivityTimer());
            }

            return () => {
                dispatch(enableInactivityTimer());
            };
        },
        // eslint-disable-next-line
        [],
    );
};

export default useInactivityTimerToggle;
