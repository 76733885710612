import React, { useRef, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { ReadTextField } from '@icoach/components';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Button, Form, SecurityTextField, Stack, TextField, Box } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { LOGIN_ROUTER, LOGIN_COACH_ROUTER } from '@icoach/router/routerPath';
import { isEmpty, localUser, refIsRequiredError } from '@util/utils';
import useAuthUserApi from '@apis/useAuthUserApi';

const EmployeePasswordResetDialog = (props) => {
    const history = useHistory();
    const { token } = useParams();
    const { putAuthPwdApi, getCheckAuthUserForgotPasswordTokenApi, deleteEmployeeAuthPwdApi } = useAuthUserApi();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const employee = localUser.get('employee') || {};
    const user = localUser.get() || {};
    const getParams = (pwd) => {
        return {
            password: pwd,
        };
    };

    const handleSubmit = () => {
        passwordRef.current.setError(false, null);
        confirmPasswordRef.current.setError(false, null);
        if (!refIsRequiredError(passwordRef)) {
            const f = passwordRef.current.getResult();
            const s = confirmPasswordRef.current.getResult();
            if (f !== s) {
                confirmPasswordRef.current.setError(true, '密碼與確認密碼不相同');
            } else {
                const { token } = employee;
                putChangePassword(getParams(s), token);
            }
        } else {
            passwordRef.current.setError(true, '請符合以下密碼規則');
        }
    };

    const handleNavigateToLoginEmployee = () => {
        localUser.deleteEmployee();
        deleteLogoutEmployeeApi();
    };

    const deleteLogoutEmployeeApi = useCallback(
        async () => {
            await deleteEmployeeAuthPwdApi(token);
            history.replace(LOGIN_COACH_ROUTER);
        },
        // eslint-disable-next-line
        []
    );

    // 重設密碼
    const putChangePassword = async (params, ticket) => {
        const resp = await putAuthPwdApi(params, ticket);
        if (!isEmpty(resp)) {
            _snackbar('重新設定密碼成功', { variant: 'success' });
            localUser.deleteEmployee();
            history.push(LOGIN_COACH_ROUTER);
        }
    };

    // 檢查token
    const getEmployeeDataApi = async (params) => {
        const resp = await getCheckAuthUserForgotPasswordTokenApi(params);
        if (resp) {
            localUser.setEmployeeData(resp);
        } else {
            localUser.deleteEmployee();
            history.replace(LOGIN_COACH_ROUTER);
        }
    };

    useEffect(
        () => {
            if (isEmpty(token)) {
                history.replace(LOGIN_ROUTER);
            } else {
                getEmployeeDataApi(token);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', 'user-login-dialog')}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            BackdropProps={{
                style: {
                    backgroundColor: '#797a7c',
                },
            }}
            onSubmit={handleSubmit}
            open={true}
            fullWidth
        >
            <GradientColorHeader>重新設定新密碼</GradientColorHeader>
            <DialogContent>
                <Box className={'user-login-dialog__wrapper'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReadTextField label={'店舖：'}>{user.displayName || '未知用戶'}</ReadTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <ReadTextField label={'重設定人員：'}>{employee.displayName || '未知人員'}</ReadTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <SecurityTextField label="重設密碼" ref={passwordRef} name="confirm-password" autoComplete="confirm-password" fullWidth required />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="確認密碼"
                                ref={confirmPasswordRef}
                                type="password"
                                name="reset-password"
                                autoComplete="reset-password"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack className={'mt-2'} justifyContent={'center'} spacing={3}>
                                <Button variant="contained" type="submit">
                                    登入
                                </Button>
                                <Button variant="outlined" onClick={handleNavigateToLoginEmployee}>
                                    返回
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EmployeePasswordResetDialog;
