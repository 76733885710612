import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import {
    moreSheet,
    contactSheet,
    rowLabelCheckboxSheet,
    SpecialConditionBox,
    getMemberSheet,
    getCellPhoneSheet,
} from '@icoach/components/';
import { DateIntervalField, Typography, SelectField, MenuItem, Box } from '@common/components/';
import { refIsRequiredError, isNumber } from '@util/utils';
import { displayDateText, parseDate, isValidDate } from '@util/moment';

export const FilterLeaveSearch = React.forwardRef((props, ref) => {
    const { resource = {}, paramsData } = props;
    const { membershipStatusOption = [] } = resource;
    const dateRangeRef = React.useRef(null);
    const membershipStatusRef = useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRangeRef, membershipStatusRef),
                getResult: () => {
                    let result = {};
                    if (dateRangeRef.current && dateRangeRef.current.getResult) {
                        result = Object.assign(result, dateRangeRef.current.getResult());
                    }
                    if (membershipStatusRef.current && membershipStatusRef.current.getResult) {
                        result = Object.assign(result, {
                            membershipStatus: membershipStatusRef.current.getResult(),
                        });
                    }
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const startDate = isValidDate(paramsData.startDate) ? paramsData.startDate : parseDate();
    const endDate = isValidDate(paramsData.startDate) ? paramsData.startDate : parseDate();
    const membershipStatus = isNumber(paramsData.membershipStatus) ? paramsData.membershipStatus : 99;

    return (
        <React.Fragment>
            <Grid item xs={4}>
                <DateIntervalField
                    ref={dateRangeRef}
                    label="時間區間"
                    startDateProps={{ defaultValue: startDate }}
                    endDateProps={{ defaultValue: endDate }}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <SelectField label={'會籍狀態'} defaultValue={membershipStatus} ref={membershipStatusRef} fullWidth>
                    {Array.isArray(membershipStatusOption) &&
                        membershipStatusOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

//********************** 請假名單 **********************//
export const getFilterLeaveLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (cellValue, { memberID }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
            },
        },
        {
            cellKey: 'leaveDay',
            headerLabel: '請假天數',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'membershipStatusText',
            headerLabel: '會籍狀態',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'membershipEndDate',
            headerLabel: '現行合約終止日',
            width: 17,
            align: 'center',
            formatCell: (value) => displayDateText(value),
        },
        {
            cellKey: 'leaveEndDate',
            headerLabel: '暫停結束終止日',
            width: 17,
            align: 'center',
            formatCell: (value) => displayDateText(value),
        },
        {
            cellKey: 'leaveNote',
            headerLabel: '請假資訊',
            width: 30,
            formatCell: (value, { leaveStartDate, leaveEndDate, leaveName }) => {
                return (
                    <Box>
                        <Typography className={'mb-1 font-weight-medium'}>{leaveName}</Typography>
                        <Typography className={'white-space-normal mb-0'}>
                            {displayDateText(leaveStartDate, leaveEndDate)}
                            <br />
                            {value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            cellKey: 'xFactorNotes',
            headerLabel: 'X-factor',
            className: 'white-space-normal',
            width: 30,
            formatCell: (value) => (
                <React.Fragment>
                    {Array.isArray(value) ? (
                        <React.Fragment>
                            {value.map((target) => (
                                <Typography key={target}>{target}</Typography>
                            ))}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            ),
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
