import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { Card, Box, Stack, Typography, IconButton, Menu, ListItemText, MenuItem, Checkbox, Tooltip } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import { ListInfo } from '@icoach/accountBook/components';
import { MoreVertical as MoreVerticalIcon, Check as CheckIcon, ErrorOutline as ErrorOutlineIcon } from '@common/SvgIcon/';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { usePointsRedeemContext } from '@icoach/points/components/PointsRedeemContext';
import { parseDate } from '@util/moment';
import usePointsApi from '@apis/usePointsApi';
import useMessageDialog from '@util/hook/useMessageDialog';
import { MemberInfoPrivacyBox } from '@icoach/components/BusinessCardBox';
import { PrivacyDataToggle, PrivacyDataToggleButton } from '@icoach/components';
import usePrivacyToggle, {securityMaskFilterTypeMap} from '@util/hook/usePrivacyToggle';

const RedeemRecordsBox = ({ redeemDate, point, memo, onClick }) => {
    if (!redeemDate)
        return (
            <Box onClick={onClick}>
                <Typography component="span" color="gray">
                    尚未兌換
                </Typography>
            </Box>
        );

    if (point === 0)
        return (
            <Box onClick={onClick}>
                <Typography component="span" color="main">
                    本次不兌換
                </Typography>
            </Box>
        );

    return (
        <Box onClick={onClick}>
            <Typography component="span" color="main" variant="h6">{`${point}pt`}</Typography>
            <Typography component="span"> - </Typography>
            <Typography component="span">{parseDate(redeemDate, 'YYYY/MM/DD')}</Typography>
            <Typography component="div" className="text-wrap-wrap">
                {Array.isArray(memo) ? memo.join('') : memo}
            </Typography>
        </Box>
    );
};

const ReceivedSignBox = ({ value: valueProps, redeemRecordID: redeemRecordIDProps, rewardID: rewardIDProps }) => {
    const [value, setValue] = useState(0);
    const { postPointsRewardsRedeemStatusApi } = usePointsApi();
    const setMessageDialog = useMessageDialog();

    const handleOnChange = (_e, checked) => {
        _e.stopPropagation();
        if (checked) {
            setMessageDialog({
                open: true,
                title: '是否要簽收',
                msg: '確認要簽收嗎?',
                onAsyncConfirm: (_, onclose) => doSignApi({ status: 1 }, rewardIDProps, redeemRecordIDProps, onclose),
            });
        }
    };

    const handleOnClickCancel = (_e) => {
        _e.stopPropagation();
        setMessageDialog({
            open: true,
            title: '取消簽收',
            msg: '確認要取消簽收嗎?',
            onAsyncConfirm: (_, onclose) => doSignApi({ status: 2 }, rewardIDProps, redeemRecordIDProps, onclose),
        });
    };

    const doSignApi = async (params, rewardID, redeemRecordID, onclose) => {
        const resp = await postPointsRewardsRedeemStatusApi(params, rewardID, redeemRecordID);
        if (resp) {
            setValue(params.status);
            onclose();
        }
    };

    useEffect(
        () => {
            setValue(valueProps);
        },
        // eslint-disable-next-line
        [valueProps],
    );

    return (
        <React.Fragment>
            {String(value) === `1` ? (
                <IconButton onClick={handleOnClickCancel}>
                    <CheckIcon color="success" />
                </IconButton>
            ) : (
                String(value) === `2` && <Checkbox onChange={handleOnChange} checked={false} />
            )}
        </React.Fragment>
    );
};

const MoreLabelBox = React.memo(({ rowData, dialogRef, activityIdRef, refresh }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const setMessageDialog = useMessageDialog();
    const { deletePointsRewardApi } = usePointsApi();
    const { canDelete } = rowData;

    const handleToggleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleAlertDeleteDialog = (e) => {
        e.stopPropagation();
        const { memberNo, displayName, rewardID } = rowData;
        setMessageDialog({
            open: true,
            title: '刪除會員點數',
            msg: `是否要刪除[${memberNo}]${displayName}點數主檔？刪除後無法復原喔。`,
            onAsyncConfirm: (_, onClose) => doDeleteMember(rewardID, onClose),
        });
    };

    const doDeleteMember = async (rID, onClose) => {
        const resp = deletePointsRewardApi(rID);
        if (resp) {
            onClose();
            setTimeout(refresh, 500);
        }
    };

    // 關閉menu錨點
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleToggleClick}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        dialogRef.current.openPointsProductExchangeDialog({ ...rowData, activityId: activityIdRef.current }, refresh);
                    }}
                >
                    <ListItemText>兌換贈品</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        dialogRef.current.openPointsRedeemMemberRecordDialog(rowData);
                    }}
                >
                    <ListItemText>累兌點紀錄</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        dialogRef.current.openPointsGrantDialog(rowData, refresh);
                    }}
                >
                    <ListItemText>會員補點</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        handleClose();
                        handleAlertDeleteDialog(e);
                    }}
                    disabled={!canDelete}
                >
                    <ListItemText>刪除會員</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
});

const PointsRedeemTable = React.memo((props) => {
    const scrollerArrowRef = useRef(null);
    const { dialogRef, sourceData, setBookmarkNum, activityId: activityIdRef, refresh } = usePointsRedeemContext();
    const { list = [], pageIndex = 1, pageSize = 50, totalPage, totalCount = 0 } = sourceData || {};
    const { isRevealed, toggle } = usePrivacyToggle();
    const rowsLabel = useMemo(() => {
        return [
            {
                cellKey: 'displayName',
                headerLabel: '會員',
                width: 10,
                maxWidth: 10,
                formatCell: (displayName, { maskDisplayName, memberNo }) => {
                    return <MemberInfoPrivacyBox sourceData={{ displayName, maskDisplayName, memberNo }} isRevealed={isRevealed} />;
                },
            },
            {
                cellKey: 'cellPhone',
                headerLabel: '手機',
                width: 10,
                maxWidth: 10,
                align: 'center',
                formatCell: (cellPhone, { maskCellPhone }) => {
                    return <PrivacyDataToggle fullData={cellPhone} maskedData={maskCellPhone} isRevealed={isRevealed} />;
                },
            },
            {
                cellKey: 'totalPoints',
                headerLabel: '總點數',
                width: 2,
                align: 'center',
                formatCell: (value, rowData, { dialogRef }) => {
                    const { prepareToResetDate } = rowData;

                    return (
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="h4"
                                color="main"
                                className="vertical-align-middle"
                                onClick={() => {
                                    dialogRef.current.openPointsRedeemMemberRecordDialog(rowData);
                                }}
                            >
                                {value}
                            </Typography>
                            {prepareToResetDate && <ErrorOutlineIcon color={'error'} fontSize={'small'} />}
                        </React.Fragment>
                    );
                },
            },
            {
                cellKey: 'currentPoints',
                headerLabel: '目前點數',
                width: 2,
                align: 'center',
                formatCell: (value) => {
                    return (
                        <Typography component="span" variant="h4">
                            {value}
                        </Typography>
                    );
                },
            },
            {
                cellKey: 'redeemDate',
                headerLabel: '兌換內容',
                width: 20,
                maxWidth: 20,
                formatCell: (value, rowData, { dialogRef, activityIdRef, refresh }) => {
                    return (
                        <RedeemRecordsBox
                            redeemDate={value}
                            point={rowData.redeemPoints}
                            memo={rowData.redeemDesc}
                            onClick={() => {
                                dialogRef.current.openPointsProductExchangeDialog({ ...rowData, activityId: activityIdRef.current }, refresh);
                            }}
                        />
                    );
                },
            },
            {
                cellKey: 'redeemStatus',
                headerLabel: '已簽收',
                isIcon: true,
                width: 6,
                maxWidth: 6,
                align: 'center',
                formatCell: (value, { redeemRecordID, rewardID }) => {
                    return <ReceivedSignBox value={value} redeemRecordID={redeemRecordID} rewardID={rewardID} />;
                },
            },
            {
                cellKey: 'more',
                headerLabel: '',
                isIcon: true,
                width: 4,
                maxWidth: 4,
                align: 'center',
                formatCell: (value, rowData, { dialogRef, activityIdRef, refresh }) => {
                    const newRowData = { ...rowData };
                    return <MoreLabelBox rowData={newRowData} dialogRef={dialogRef} activityIdRef={activityIdRef} refresh={refresh} />;
                },
            },
        ];
    }, [isRevealed]);

    const handlePageChange = (e, num) => {
        setBookmarkNum(num);
    };

    const handleToggle = useCallback(() => {
        const apiParams = {
            filterType: securityMaskFilterTypeMap.ClubPointsRewardOverview,
            totalCount: list.length,
        };
        toggle(apiParams);
    }, [toggle, list]);

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack alignItems={'center'} justifyContent={'space-between'}>
                    <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                        <PrivacyDataToggleButton isRevealed={isRevealed} onToggle={handleToggle} />
                    </Tooltip>
                    <ListInfo pageSize={pageSize} pageIndex={pageIndex} totalCount={totalCount} isCurrentPageFirstIndex isCurrentPageLastIndex />
                </Stack>
                <Box className={'scroll-arrow-container'}>
                    <Tablenization
                        cardProps={{ className: 'theme-gray' }}
                        page={pageIndex}
                        count={totalPage}
                        onChange={handlePageChange}
                        tableContainerRef={scrollerArrowRef}
                        isPagination
                    >
                        <Tablenization.TableRowHeader headerRow={rowsLabel} />
                        <Tablenization.TableRowBody
                            headerRow={rowsLabel}
                            rowsData={list}
                            dialogRef={dialogRef}
                            activityIdRef={activityIdRef}
                            refresh={refresh}
                        />
                    </Tablenization>
                    <ScrollerArrowButton parentRef={scrollerArrowRef} />
                </Box>
            </Card.CardContent>
        </Card>
    );
});

export default PointsRedeemTable;
