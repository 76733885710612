import React from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import InBodyDeleteMeasure from '@icoach/inbody/components/InBodyDeleteMeasure';
import InBodyInterviewPdfDialog from '@icoach/inbody/components/InBodyInterviewPdfDialog';
import { MeasureCompareNumericBox } from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

// 量身紀錄項目 身體總水重 骨骼肌重 基礎代謝 InBody評分 這四項為反指標
const employeeInBodyInterviewMeasureItems = [
    {
        value: '身體組成分析',
        key: 'measureDate',
        uuid: uuid(),
        rowClass: clsx('header', 'title'),
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
        formatCompare: () => '比較',
        formatMutipleCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        value: 'InBody評分',
        uuid: uuid(),
        key: 'inbodyScore',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '骨骼肌重 KG',
        uuid: uuid(),
        key: 'smm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '蛋白質重 KG',
        uuid: uuid(),
        key: 'protein',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '體脂肪率 %',
        uuid: uuid(),
        key: 'pbf',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '體脂肪重 KG',
        uuid: uuid(),
        key: 'bfm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '體重 KG',
        uuid: uuid(),
        key: 'weight',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '身體總水重 L',
        uuid: uuid(),
        key: 'tbw',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '部分別圍度',
        key: 'title-2',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '總體圍 CM',
        uuid: uuid(),
        key: 'ac',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '頸部 CM',
        uuid: uuid(),
        key: 'neckMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '胸圍 CM',
        uuid: uuid(),
        key: 'chestMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '腰圍 CM',
        uuid: uuid(),
        key: 'abdomenMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '臀圍 CM',
        uuid: uuid(),
        key: 'hipMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右臂/左臂 CM',
        uuid: uuid(),
        key: ['rightArmMC', 'leftArmMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右大腿/左大腿 CM',
        uuid: uuid(),
        key: ['rightThighMC', 'leftThighMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '研究參數',
        key: 'title-3',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '內臟脂肪',
        uuid: uuid(),
        key: 'vfl',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '腰臀圍比',
        uuid: uuid(),
        key: 'whr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '肌肉質量指數SMI',
        uuid: uuid(),
        key: 'smi',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },

    {
        value: '骨礦含量',
        uuid: uuid(),
        key: 'bmc',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '基礎代謝率',
        uuid: uuid(),
        key: 'bmr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '身體平衡評估',
        key: 'title-4',
        uuid: uuid(),
        rowClass: clsx('title'),
        formatCell: () => '',
        formatCompare: () => '',
        formatMutipleCell: () => '',
    },
    {
        value: '上半身',
        uuid: uuid(),
        key: 'ubbEvalText',
    },
    {
        value: '下半身',
        uuid: uuid(),
        key: 'lbbEvalText',
    },
    {
        value: '上-下半身',
        uuid: uuid(),
        key: 'ulbbEvalText',
    },
    {
        value: 'InBody 套表',
        uuid: uuid(),
        key: 'pathUrl',
        rowClass: 'inbody-chart',
        formatCell: (value) => {
            return <InBodyInterviewPdfDialog sourceData={value} />;
        },
    },
    { value: '量身擔當', uuid: uuid(), key: 'employeeName' },
    {
        value: '',
        uuid: uuid(),
        key: 'measureID',
        formatCell: (measureID, rowData) => {
            return <InBodyDeleteMeasure measureID={measureID} sourceData={rowData} />;
        },
    },
];

export default employeeInBodyInterviewMeasureItems;
