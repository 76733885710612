import React, { useState } from 'react';
import { Chip } from '@common/components/';
import DismissibleTooltip from '@icoach/components/DismissibleTooltip';
import { QuestionCircle as QuestionCircleIcon } from '@common/SvgIcon/';

export const InteractiveStatusChip = ({ icon: Icon = <QuestionCircleIcon fontSize="small" htmlColor="#ef5350" />, label, tip, color }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const closeTooltip = () => {
        setIsTooltipOpen(false);
    };

    const toggleTooltip = () => {
        setIsTooltipOpen((prev) => !prev);
    };

    return Boolean(tip) ? (
        <DismissibleTooltip open={isTooltipOpen} onToggle={toggleTooltip} onClose={closeTooltip} tip={tip}>
            <Chip label={label} icon={Icon} color={color} />
        </DismissibleTooltip>
    ) : (
        <Chip label={label} color={color} />
    );
};

export default InteractiveStatusChip;
