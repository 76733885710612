import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import usePeopleApi, { peopleType } from '@apis/usePeopleApi';
import { v4 as uuid } from 'uuid';
import { isEmpty, refIsRequiredError } from '@util/utils';
import { AutocompleteField } from '@common/components/';

const FieldByMember = React.forwardRef(({ referenceID, isLock = false }, ref) => {
    const { people: memberOptions = [] } = usePeopleApi(peopleType.all);
    const [key, setKey] = useState(uuid());
    const referenceIDRef = useRef();

    useEffect(() => {
        if (referenceID || !isEmpty(memberOptions)) {
            setKey(uuid());
        }
    }, [referenceID, memberOptions]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    referenceID: referenceIDRef.current.getResult('id'),
                };
            },
            isError: () => refIsRequiredError(referenceIDRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <AutocompleteField
            key={key}
            ref={referenceIDRef}
            defaultValue={referenceID || ''}
            options={memberOptions}
            optionKeys={['name', 'nickName', 'mobile', 'no']}
            optionMaskKeys={['maskName', 'nickName', 'maskMobile', 'no']}
            primaryKey={'id'}
            label={'會員'}
            InputProps={{
                placeholder: '請輸入姓名、手機、編號 進行搜索',
                disabled: isLock,
            }}
            fullWidth
            required
        />
    );
});

export default FieldByMember;
