import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';
import { Button, TextField, Alert, Form, Box, Stack } from '@common/components/';
import useAuthApi from '@apis/useAuthApi';
import { PasswordAgeWarningTextBox, ForcePasswordResetTextBox } from '@root/scripts/login/component/';
import { generateSecurity } from '@util/apiCryto';
import useMessageDialog from '@util/hook/useMessageDialog';
import { isEmpty, localUser, refIsRequiredError } from '@util/utils';
import { ROOT_LOGIN_RESET_PASSWORD_TOKEN_PATH, ROOT__ROUTER, LOGIN_COACH_ROUTER } from '@icoach/router/routerPath';
// img
import '../mockjs/MockJs';
import LoginHeader_1 from '@images/login_1.svg';
import LoginHeader_2 from '@images/login_2.svg';
import LoginLogo from '@images/logo-white.svg';
import LoginFooterBg from '@images/footer-bg.svg';

const LoginPage = () => {
    const history = useHistory();
    const { postLoginApi, getAuthMeCheckApi, getAuthMeDataApi, getAuthUserMeMailForgotPasswordApi } = useAuthApi();
    const setMessageDialog = useMessageDialog();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    const getLoginParams = () => {
        const pwd = passwordRef.current.getResult();
        const { hmacSecurity, hmacKey } = generateSecurity(pwd);
        return { username: usernameRef.current.getResult(), password: hmacSecurity, hmacKey };
    };

    const getForgotPasswordAccount = () => {
        return usernameRef.current.getResult();
    };

    const handleForgotPassword = () => {
        if (!refIsRequiredError(usernameRef)) {
            const account = getForgotPasswordAccount();
            getForgotPasswordApi(account);
        }
    };

    const jumpToModifyPasswordAction = async (token) => {
        history.push(ROOT_LOGIN_RESET_PASSWORD_TOKEN_PATH(token));
    };

    const jumpToHomeAction = async (userData = null, employeeData = null) => {
        const hasUserData = !isEmpty(userData);
        const hasEmployeeData = !isEmpty(employeeData);

        hasUserData && (() => localUser.set({ ...userData }))();
        hasEmployeeData && (() => localUser.setEmployeeData({ ...employeeData }))();

        // 跳轉邏輯
        if (userData.isUserAuthorized || (hasUserData && hasEmployeeData)) {
            // 使用者已授權，跳轉到會員首頁
            history.push(ROOT__ROUTER);
        } else if (hasUserData && !hasEmployeeData) {
            // 有使用者資料但無員工資料，跳轉到教練登入
            history.push(LOGIN_COACH_ROUTER);
        } else {
            // 防止惡意登入，登出並回到首頁
            console.warn('Unauthorized access attempt, logging out.');
            localUser.logout();
            history.replace(ROOT__ROUTER);
        }
    };

    const isPasswordResetPrompt = (targetData) => {
        const { isPasswordResetNotify, isPasswordResetRequired, token } = targetData;
        if (!isPasswordResetNotify && !isPasswordResetRequired) return false;
        else if (isPasswordResetNotify && !isPasswordResetRequired) {
            setMessageDialog({
                open: true,
                dialogPaperProps: { className: 'wx-65rem' },
                title: '通知',
                buttonText: '稍後再說',
                DialogActionsProps: {
                    className: 'flex-y-flex-center',
                },
                buttonVariant: 'text',
                onAsyncConfirm: handleLoginApi,
                MsgComp: ({ onClose }) => {
                    return (
                        <React.Fragment>
                            <PasswordAgeWarningTextBox />
                            <Stack justifyContent="center">
                                <Button
                                    onClick={() => {
                                        onClose();
                                        jumpToModifyPasswordAction(token);
                                    }}
                                    variant="contained"
                                >
                                    前往修改密碼
                                </Button>
                            </Stack>
                        </React.Fragment>
                    );
                },
            });
        } else if (isPasswordResetRequired) {
            setMessageDialog({
                open: true,
                dialogPaperProps: { className: 'wx-65rem' },
                title: '通知',
                buttonText: '前往修改密碼',
                DialogActionsProps: {
                    className: 'flex-y-flex-center',
                },
                onClose: () => {
                    localUser.logout();
                },
                onAsyncConfirm: (_, onclose) => {
                    // 待處理 此流程有個問題 登入後不應該有token 因為重新整理後會跳進教練登入頁面，必須先設定完密碼才可以印進入到教練登入頁，高權限者有一樣的問題
                    jumpToModifyPasswordAction(token);
                    onclose();
                },
                MsgComp: () => {
                    return (
                        <React.Fragment>
                            <ForcePasswordResetTextBox />
                        </React.Fragment>
                    );
                },
            });
        }
        return true;
    };

    const handleLoginApi = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!refIsRequiredError(usernameRef, passwordRef)) {
            const params = getLoginParams();
            postClubLoginApi(params);
        }
    };

    // 忘記密碼
    const getForgotPasswordApi = async (userName) => {
        const resp = await getAuthUserMeMailForgotPasswordApi(userName);
        resp && _snackbar(`已成功寄出於您的店舖信箱`, { variant: 'success' });
    };

    // 登入前判斷
    const postClubLoginApi = async (params) => {
        await postLoginApi(params, {
            callbackfn: (oriData, apiData) => {
                const { data } = apiData;
                if (data.success) {
                    !isPasswordResetPrompt(data.result) && jumpToHomeAction(data.result);
                } else {
                    setMessageDialog({
                        open: true,
                        MsgComp: () => (
                            <Alert variant="filled" severity="error" color="error">
                                {data.message}
                            </Alert>
                        ),
                        buttonText: '確定',
                    });
                }
            },
        });
    };

    useEffect(
        () => {
            const fetchData = async () => {
                const authMeCheck = await getAuthMeCheckApi();
                if (authMeCheck) {
                    const resp = await getAuthMeDataApi();
                    if (!isEmpty(resp)) {
                        const { user, employee } = resp;
                        jumpToHomeAction(user, employee);
                    }
                }
            };
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Box className="login-header">
                <Box className="login-header-container">
                    <Box className="login-header-bg">
                        <Box component="img" src={LoginHeader_1} className="login-header-1" alt="curves"></Box>
                        <Box component="img" src={LoginHeader_2} className="login-header-2" alt="curves"></Box>
                        <Box component="img" src={LoginLogo} className="login-logo" alt="logo"></Box>
                    </Box>
                </Box>
            </Box>
            <Box className="login-body">
                <Box className="login-body-container">
                    <Form className="login-form" onSubmit={handleLoginApi}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField ref={usernameRef} label="帳號" helperText="帳號不得為空值" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField ref={passwordRef} label="密碼" helperText="密碼不得為空值" type="password" fullWidth required />
                            </Grid>
                        </Grid>
                        <Stack direction="column" alignItems="center" spacing={3}>
                            <Button className="submit-button" variant="outlined" type="submit">
                                登入
                            </Button>
                            <Button variant="text" color="gray" size="small" onClick={handleForgotPassword}>
                                忘記密碼
                            </Button>
                        </Stack>
                    </Form>
                </Box>
            </Box>
            <Box className="login-footer">
                <Box component="img" src={LoginFooterBg} alt="FooterBackground" />
                <Box className="login-copyright ban-select">
                    <Box component="span">版權所有 © 2022 Curves 可爾姿女性30分鐘環狀運動</Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default LoginPage;
