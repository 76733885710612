import { useEffect, useState } from 'react';
import { getFocusMode, setFocusMode } from '@util/focusModeController';

const useLocalFocusModeToggle = (onEnable, onDisable) => {
    const [isFocusMode, setIsFocusMode] = useState(getFocusMode());

    const handleToggleFocusMode = () => {
        if (isFocusMode) {
            if (typeof onDisable === 'function') {
                onDisable();
            }
            setFocusMode(false);
            setIsFocusMode(false);
        } else {
            if (typeof onEnable === 'function') {
                onEnable();
            }
            setFocusMode(true);
            setIsFocusMode(true);
        }
    };

    const handleDisableFocusMode = () => {
        setFocusMode(false);
        setIsFocusMode(false);
    };

    const handleEnableFocusMode = () => {
        setFocusMode(true);
        setIsFocusMode(true);
    };

    useEffect(
        () => {
            const handleStorageChange = (e) => {
                if (e.key === 'inactivityTimer') {
                    setIsFocusMode(getFocusMode());
                }
            };

            window.addEventListener('storage', handleStorageChange);

            return () => {
                window.removeEventListener('storage', handleStorageChange);
            };
        },
        // eslint-disable-next-line
        [],
    );

    return {
        isFocusMode,
        onToggleFocusMode: handleToggleFocusMode,
        onDisableFocusMode: handleDisableFocusMode,
        onEnableFocusMode: handleEnableFocusMode,
    };
};

export default useLocalFocusModeToggle;
