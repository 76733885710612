import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import {
    moreSheet,
    contactSheet,
    rowLabelCheckboxSheet,
    SpecialConditionBox,
    getMemberSheet,
    getCellPhoneSheet,
} from '@icoach/components/';
import { DateField, FormControl, FormLabel, OutlinedInput, Stack, Typography } from '@common/components/';
import { refIsRequiredError, isNumber } from '@util/utils';
import { displayDateText, parseDate, isValidDate } from '@util/moment';
import useControlled from '@util/hook/useControlled';

export const MeasureDayRangeField = React.forwardRef((props, ref) => {
    const {
        disabled = false,
        required = false,
        className,
        isError: isErrorProps,
        direction: directionProps = 'column',
        label = '',
        lightColor = false,
        symbolProps = '～',
        defaultValue = { minDay: 1, maxDay: 31 },
        FormLabelTip = '',
    } = props;

    const [isError] = useControlled({
        value: undefined,
        defaultValue: isErrorProps,
    });
    const startValueRef = useRef(null);
    const endValueRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => false,
                getResult: () => {
                    return {
                        measureStartDay: startValueRef.current.value * 1,
                        measureEndDay: endValueRef.current.value * 1,
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const formlabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    return (
        <FormControl disabled={disabled} required={required} className={className} error={isError} direction={directionProps}>
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <Stack direction="column" style={{ flexGrow: 1 }}>
                <Stack>
                    <FormControl disabled={disabled} error={isError}>
                        <OutlinedInput
                            defaultValue={defaultValue.minDay}
                            inputRef={startValueRef}
                            type={'number'}
                            inputProps={{ min: defaultValue.minDay, max: defaultValue.maxDay }}
                            fullWidth
                        />
                    </FormControl>
                    <Typography component="div" className="px-1" style={{ margin: 'auto' }}>
                        {symbolProps}
                    </Typography>
                    <FormControl disabled={disabled} error={isError}>
                        <OutlinedInput
                            defaultValue={defaultValue.maxDay}
                            inputRef={endValueRef}
                            type={'number'}
                            inputProps={{ min: defaultValue.minDay, max: defaultValue.maxDay }}
                            fullWidth
                        />
                    </FormControl>
                </Stack>
            </Stack>
        </FormControl>
    );
});

export const FilterUnInBodyScannerSearch = React.forwardRef(({ paramsData }, ref) => {
    const dateMonthRef = useRef(null);
    const measureDayRangeRef = useRef(null);
    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateMonthRef, measureDayRangeRef),
                getResult: () => {
                    let result = {};
                    if (dateMonthRef.current && dateMonthRef.current.getResult) {
                        Object.assign(result, { date: parseDate(dateMonthRef.current.getResult(), 'YYYY-MM') });
                    }

                    if (measureDayRangeRef.current && measureDayRangeRef.current.getResult) {
                        Object.assign(result, measureDayRangeRef.current.getResult());
                    }

                    return result;
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const dateMonth = !!paramsData.date && isValidDate(`${paramsData.date}-01`) ? `${paramsData.date}-01` : parseDate(new Date(), 'YYYY-MM-DD');
    const maxDay = isNumber(paramsData.measureEndDay) ? paramsData.measureEndDay * 1 : 31;
    const minDay = isNumber(paramsData.measureStartDay) ? paramsData.measureStartDay * 1 : 1;

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <DateField
                    defaultValue={dateMonth}
                    ref={dateMonthRef}
                    views={['year', 'month']}
                    openTo="month"
                    format="YYYY/MM"
                    label="月份"
                    mask="____/__"
                    fullWidth
                />
            </Grid>
            <Grid xs={3} item>
                <MeasureDayRangeField ref={measureDayRangeRef} label={'WM日'} defaultValue={{ minDay: minDay, maxDay: maxDay }} fullWidth />
            </Grid>
        </React.Fragment>
    );
});

// 未量身名單
export const getFilterUnInBodyScannerLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true },
        { ...getCellPhoneSheet(isRevealed) },
        {
            cellKey: 'specialCondition',
            headerLabel: '特殊狀況',
            width: 18,
            formatCell: (cellValue, { memberID }) => {
                return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
            },
        },
        {
            cellKey: 'measureDay',
            headerLabel: 'WM日',
            width: 9,
            align: 'center',
        },
        {
            cellKey: 'lastMeasureDate',
            headerLabel: '最近一次量身',
            align: 'center',
            width: 17,
            formatCell: (value) => displayDateText(value),
        },
        { ...contactSheet },
        { ...moreSheet },
    ];
};
