import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMenusData } from '@redux/menus/selector';

/**
 * 自定義 Hook：控制和過濾菜單清單
 * @param {Array} sourceData - 資料來源陣列
 * @param {string} [key='frontKey'] - 用於匹配的屬性名稱
 * @returns {Array} 過濾後的菜單清單
 */
const useMenusControlled = (sourceData, key = 'frontKey') => {
    const { menulist: menuList = [] } = useSelector(getMenusData) || {};

    return useMemo(() => {
        if (!menuList.length || !sourceData?.length || !key) return [];
        return sourceData.filter((source) =>
            menuList.some((menu) => menu[key] === source[key])
        );
    }, [menuList, sourceData, key]);
};

export default useMenusControlled;