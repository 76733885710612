import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PrivacyDataToggleButton from '@icoach/components/PrivacyToggleButton';


const PrivacyTextWithToggle = ({
     className,
     text,
     isRevealed,
     onToggle,
     logApi,
 }) => {
    return (
        <span className={clsx(className, 'privacy-text-with-toggle')}>
            <span className={"privacy-text-with-toggle__text"}>{text}</span>
            <PrivacyDataToggleButton className={'privacy-text-with-toggle__toggle-button'} isRevealed={isRevealed} onToggle={onToggle} logApi={logApi} />
        </span>
    );
};

PrivacyTextWithToggle.propTypes = {
    ...PrivacyDataToggleButton.propTypes,
    text: PropTypes.string,
    className: PropTypes.string,
};

export default PrivacyTextWithToggle;
