import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@common/components/';
import ProductDetails from '@icoach/components/product/ProductDetails';

const ProductCard = ({
    className,
    productName,
    productImage,
    sku,
    spec,
    price, // 商品定價
    salePrice, // 商品售價
    stock,
    note,
    ...rest
}) => {
    const _productImage =
        productImage ||
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAARjSURBVGhD7ZtrUxpZEIZfQAFBQRHkIgIm65p4SfJr9ufsh/11W7VbSTbZrIniLUIieEEQkVtw+504LmKJHAPOLE5XTY0fDjPnOW93nz6N2CqVyi8AfpNrUa5htk8C96tNgD8+AlhdyE8EvhhmWTvZLOBhV9tS2FJ4yFbAcukhE/QGjqWwpfCQrYDl0kMmqJW07uXSrVYLtXoTlUoVzea3B3WKkREHvB4XnE4n7Hab8ruVgQl7clLGXvYABbk3m03ll/7IBwg8NTmB+GxIu6tCKwNXzmtIpzPY/pxDvd74kbnf+7Mu5yiSiTAWnsbhEbVVTBn48KiId+83cVQ4VXlP38dOB3xYXZ5HKDip9Gxl4Fy+gDd/beC0fH71IpvNhrExFyb9XoyOjuBcvOCkWBYPGJy7+3wevFh+gmhkerDA+7ljvHmXRvkSmLAzQT8S4mIBiSmHw4F6o4HcwQm2d75ejVOaVQ+DfRMCvGIAMF/8bDGBmKw0EwoX4OLiQqCbSG9msSFXQ/7utxkGzGy5/DyFifExDVY3Qh8elfDn648on1X7zQtDgImXSkWx/CypxXCnlUpn+P2PNRTl3os5Jf4Zm6VSRfOQbmYIMCcUi05jdWleJuq9Mb/8QUEUXseZFCh3mdvlxM8LcYRDU8h+OUR6O9s16RkG7BFlGcOJ+IyWoXWr1er4sLaLXdmvm9+6V2NUdmUphbl4WJ7h0LL87l4e6+m9W6ENA6ZbU10WAtFw4Pu2VJUJC2gme4iqgHczwi5JSCQTEQ1WN0Jv7exj8xalDQPWJ+hw2OF2OzEid+6/NanCWq3uX2pw7MvVp0jMhWU7s18lPSY82nelc1jfyNyIacOBO1VkjdsNmLCslFLJ6DXY9iyvQ2/Jfr659eUatGmAuTXNxoKIy/U5k8d+riDgrWvrQdhXL37S3Piu4p9qV6t1ce+vmtJ6PjAFMGGZtRmTfolrVmNvpe7OS9WlQ+vKPpmfvRO2XW29wju73NMNB7bb7Zqq3Fom/eNX8Xh6WsH7f7ZF6WMtvldkC2PMsipTMX7+tdTwpgAmbDQSwHPZnvy+8RvKEfrD2o6cbPyaG7cnqF6hTQPMGIxFg1hcmJMD+X/KdoIwcxOU49tL0P8dsNfj1rLtbCzUc0z2Ctk+zjQK89DAYxpVHqSZBtjrdWNpMYnwzNQgebVM/7ckP7aYaA+apd9KA0DveLCUDE37QfBBGg8gBwLduOySPhgwT0AELsoRzkhjO2lVQikyE1CahnJPi70quhZj6rLkVXphPwazzxCRgwobD2zVqpgyMNs1GelJs9SjW3eWjSovv89Y7vlMlPPJiBwnrx9Je3meMjBr21qtIe2bIo6OS4Z888AWbVDyhss1qrynKwO317cs5LUT0UP9a5u4sl38mZ3Ruw4dt6l9b+Be3MeMYyxgM6rSzzlZCvdzNc34LEthM6rSzzlZCvdzNc34LEthM6rSzzlZCvdzNc34rEep8KP7odaj+inevwq3MIY7PWnZAAAAAElFTkSuQmCC';
    return (
        <Box className={clsx('product-card', className)} {...rest}>
            <Box className="product-card__image">
                <img src={_productImage} alt={productName} />
            </Box>
            <ProductDetails productName={productName} sku={sku} spec={spec} price={price} salePrice={salePrice} stock={stock} note={note} />
        </Box>
    );
};

ProductCard.prototype = {
    ...ProductDetails.prototype,
    className: PropTypes.string,
    productImage: PropTypes.string,
};

export default ProductCard;
