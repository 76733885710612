import { useCallback, useState } from 'react';
import { isEmpty } from '@util/utils';
import useSecurityMaskApi from '@apis/useSecurityMaskApi';

export const securityMaskFilterTypeMap = {
    PjReport: 1, //營運報表PJ
    CaReport: 2, //營運報表CA

    // 會員相關報表
    ClubMemberOverview: 100, //會員總覽
    ClubMemberWorkoutTimes: 101, //次數名單
    ClubMemberRenewal: 102, //應續約名單
    ClubMemberSuspension: 103, //請假名單
    ClubMemberInvalid: 104, //無效名單
    ClubMemberBirthday: 105, //生日名單
    ClubMemberNonMeasure: 106, //未量身名單
    ClubMemberMeasured: 107, //已量身名單
    ClubMemberHundredTShirt: 108, //百T名單
    ClubMemberNewMember: 109, //新會員名單
    ClubMemberRentCabinet: 110, //租櫃名單
    ClubMemberHealthyChoice: 111, //蛋白&益菌名單
    ClubMemberOverdraft: 112, //欠款名單
    ClubMemberMonthFee: 113, //月費收費名單
    ClubMemberMeasureResult: 114, //量身成果名單
    ClubMemberMeasureFilter: 115, //會員成果查詢

    // 顧客及其他相關報表
    ClubCustomerOverview: 150, //顧客總覽
    ClubCustomerTemporary: 151, //暫存名單
    ClubPointsRewardOverview: 152, //點數總覽
    ClubAppointmentOverview: 153, //預約管理總覽
    ClubCustomerGuestOverview: 154, //名單總覽
    ClubEmployeeOverview: 155, //員工總覽
    ClubSmsOverview: 156, //簡訊總覽

    // 總部（HQ）相關報表
    HqPointsRewardOverview: 200, //HQ點數總覽
    HqPointsRewardOrders: 201, //HQ點數贈品配送

};

const usePrivacyToggle = (initialIsRevealed = false) => {
    const [isRevealed, setIsRevealed] = useState(initialIsRevealed);
    const { postSecurityMaskApi } = useSecurityMaskApi();

    const toggle = useCallback(
        (logParams) => {
            setIsRevealed((prevState) => {
                const nextState = !prevState;

                // console.log(logParams, 'logParams');

                // 當狀態變為 true 時，執行 logApi
                if (nextState && !isEmpty(logParams)) {
                    postSecurityMaskApi(logParams);
                }

                return nextState;
            });
        },
        // eslint-disable-next-line
        [postSecurityMaskApi]
    );

    const hide = useCallback(() => {
        setIsRevealed(false);
    }, []);

    return { isRevealed, toggle, hide };
};

export default usePrivacyToggle;
