import React, { createContext, useContext } from 'react';

const StepContext = createContext(undefined);

export const StepProvider = ({ children, value }) => {
    return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

export const useStepContext = () => {
    const context = useContext(StepContext);
    if (context === undefined) {
        throw new Error('useStepContext 必須在 StepProvider 中使用');
    }
    return context;
};
