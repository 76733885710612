import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Eye as EyeIcon, EyeSlash as EyeSlashIcon } from '@common/SvgIcon/';
import { IconButton } from '@common/components/';
import usePrivacyToggle from '@util/hook/usePrivacyToggle';

const PrivacyDataToggleButton = forwardRef(
    ({ className, isRevealed: controlledIsRevealed, initialIsRevealed = false, onToggle, logParams, children, ...rest }, ref) => {
        const isControlled = controlledIsRevealed !== undefined;
        const { isRevealed: uncontrolledIsRevealed, toggle } = usePrivacyToggle(initialIsRevealed);
        const isRevealed = isControlled ? controlledIsRevealed : uncontrolledIsRevealed;

        const handleToggle = () => {
            if (isControlled) {
                if (typeof onToggle === 'function') {
                    onToggle();
                }
            } else {
                toggle(logParams);
            }
        };

        return (
            <IconButton ref={ref} className={clsx(className, 'privacy-toggle-button')} onClick={handleToggle} {...rest}>
                {children || (isRevealed ? <EyeIcon /> : <EyeSlashIcon />)}
            </IconButton>
        );
    },
);

PrivacyDataToggleButton.propTypes = {
    className: PropTypes.string,
    isRevealed: PropTypes.bool,
    onToggle: PropTypes.func,
    logApi: PropTypes.func,
    children: PropTypes.node,
};

export default PrivacyDataToggleButton;
