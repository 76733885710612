import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, Switch, useHistory } from 'react-router-dom';
import { Box, Card, PrivateRoute } from '@common/components/';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import { CycleOrderDeleteDialog } from '@icoach/documents/dialog';
import useToggleDialog from '@util/hook/useToggleDialog';
import { Grid } from '@mui/material';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import { formatCurrencyFn } from '@util/utils';
import CyclePurchaseDetailOrder from '@icoach/documents/cyclePurchase/CyclePurchaseDetailOrder';
import CyclePurchaseDetailHistory from '@icoach/documents/cyclePurchase/CyclePurchaseDetailHistory';
import CyclePurchaseDetailOrderFuture from '@icoach/documents/cyclePurchase/CyclePurchaseDetailOrderFuture';
import {
    DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH,
    DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_FUTURE_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_HISTORY_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_MAIN_ROUTER,
    DOCUMENT_CYCLE_PURCHASE_PATH,
    DOCUMENT_CYCLE_PURCHASE_ROUTER,
} from '@icoach/router/routerPath';
import PageLayout from '@icoach/components/layout/PageLayout';
import TabsCardLayout from '@icoach/components/layout/TabsCardLayout';

const dialogType = {
    delete: 'delete',
};

const CyclePurchaseSummaryCard = (props) => {
    const { payment, ...other } = props.sourceData || {};
    let sourceData = {
        ...other,
        ...payment,
    };
    const CyclePurchaseSummaryInfo = [
        {
            key: 'cycleOrderNo',
            text: '主單編號',
        },
        {
            key: 'cycleOrderTypeText',
            text: '訂單狀態',
        },
        {
            key: 'cycleOrderDate',
            text: '建單日期',
            formatter: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
        },
        {
            key: 'updatedDate',
            text: '更新日期',
            formatter: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
        },
        {
            key: 'shippingDayText',
            text: '出貨週期',
            formatter: (value) => {
                if (value) return `${value}日`;
                return '';
            },
        },
        {
            key: 'memberName',
            text: '訂購人',
        },
        {
            key: 'saleAmount',
            text: '訂單金額',
            formatter: (value) => {
                return formatCurrencyFn(value, { symbol: '$' });
            },
        },
        {
            key: 'cardExpiryDate',
            text: '信用卡效期',
            formatter: (value) => {
                if (value && value.length) {
                    const month = value.substring(0, 2);
                    const year = value.substring(2, 4);
                    return `20${year}年${month}月`;
                } else {
                    return value;
                }
            },
        },
        {
            key: 'firstShipmentMonth',
            text: '首次出貨月',
            formatter: (value) => parseDate(value, 'YYYY年MM月'),
        },
    ];

    const handleValue = (value, formatter) => {
        if (!formatter) return value;

        if (typeof formatter === 'function') return formatter(value);
    };

    return (
        <Card className={'mb-3 px-4 pt-3 pb-2'}>
            <Card.CardContent>
                <Grid spacing={2} container>
                    {CyclePurchaseSummaryInfo.map((item, i) => {
                        const { key, text, formatter } = item;
                        return (
                            <Grid key={`${key}-${i}`} className={'mb-1'} xs={6} lg={4} xl={3} item>
                                <Grid spacing={2} container>
                                    <Grid xs={5} item>
                                        <Box className={'font-color-3'}>{text}</Box>
                                    </Grid>
                                    <Grid xs={7} item>
                                        <Box>{handleValue(sourceData[key], formatter)}</Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

const CyclePurchaseDetailPage = (props) => {
    const { memberID, cycleOrderID, tabs: activeTab } = props.match.params;
    const history = useHistory();
    const { getCyclePurchaseMemberOrderApi } = useCyclePurchaseApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [data, setData] = useState({});

    const pageActions = useMemo(
        () => {
            const { canEffection, canDeleted } = data || {};
            let actions = [
                {
                    label: '返回總覽',
                    variant: 'contained',
                    as: NavLink,
                    to: DOCUMENT_CYCLE_PURCHASE_ROUTER,
                },
            ];

            if (canEffection) {
                actions = [
                    ...actions,
                    {
                        label: '變更訂單',
                        variant: 'outlined',
                        as: NavLink,
                        to: DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID),
                    },
                ];
            }

            if (canDeleted) {
                actions = [
                    ...actions,
                    {
                        label: '刪除訂單',
                        variant: 'outlined',
                        onClick: () => handleOpenDialog(dialogType.delete),
                    },
                ];
            }
            return actions;
        },
        // eslint-disable-next-line
        [JSON.stringify(data)],
    );

    const tabData = useMemo(
        () => {
            const cyclePurchaseTabsMap = {
                main: 'main',
                history: 'history',
                future: 'future',
            };
            const { effectionQuantity = 0 } = data || {};
            const LabelComponent = (params) => {
                const { label, qty = 0 } = params;

                return (
                    <React.Fragment>
                        <span className={'tab-label'}>{label}</span>
                        {qty > 0 && <span className={'tab-label-qty'}>{qty}</span>}
                    </React.Fragment>
                );
            };

            return [
                {
                    label: '訂單主檔',
                    value: cyclePurchaseTabsMap.main,
                    component: NavLink,
                    to: DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID, cyclePurchaseTabsMap.main),
                },
                {
                    label: '訂單歷史',
                    value: cyclePurchaseTabsMap.history,
                    component: NavLink,
                    to: DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID, cyclePurchaseTabsMap.history),
                },
                {
                    label: <LabelComponent label={'預計變更'} qty={effectionQuantity} />,
                    value: cyclePurchaseTabsMap.future,
                    component: NavLink,
                    to: DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID, cyclePurchaseTabsMap.future),
                },
            ];
        },
        // eslint-disable-next-line
        [JSON.stringify(data), memberID, cycleOrderID],
    );

    const doInitCyclePurchaseApi = async (memberID, cycleOrderID) => {
        let res = await getCyclePurchaseMemberOrderApi(memberID, cycleOrderID);
        if (res) {
            setData(res);
        }
    };

    const refresh = () => {
        history.push(DOCUMENT_CYCLE_PURCHASE_ROUTER);
    };

    useEffect(() => {
        if (memberID && cycleOrderID) {
            doInitCyclePurchaseApi(memberID, cycleOrderID);
        }
        // eslint-disable-next-line
    }, [memberID, cycleOrderID]);

    return (
        <PageLayout>
            <PageLayout.Header title={'健康產品循環訂購單'} actions={pageActions} />
            <CyclePurchaseSummaryCard sourceData={data} />
            <TabsCardLayout tabs={tabData} activeTab={activeTab} tabsContent={{ className: 'py-3 px-4' }}>
                <Switch>
                    <PrivateRoute path={DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_MAIN_ROUTER} as={CyclePurchaseDetailOrder} sourceData={data} exact />
                    <PrivateRoute path={DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_HISTORY_ROUTER} as={CyclePurchaseDetailHistory} sourceData={data} exact />
                    <PrivateRoute
                        path={DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_FUTURE_ROUTER}
                        as={CyclePurchaseDetailOrderFuture}
                        sourceData={data}
                        refresh={doInitCyclePurchaseApi}
                        exact
                    />
                </Switch>
            </TabsCardLayout>
            <CycleOrderDeleteDialog
                open={openTarget === dialogType.delete}
                memberID={memberID}
                cycleOrderID={cycleOrderID}
                onClose={handleCloseDialog}
                refresh={refresh}
            />
        </PageLayout>
    );
};

export default CyclePurchaseDetailPage;
