import React from 'react';
import { Card } from '@common/components/';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import InteractiveContentWrapper from '@icoach/components/InteractiveContentWrapper';

const PurchaseReceiptOrderInfoCard = ({ className, sourceData, ...rest }) => {
    const { clubName, purchaseReceiptNo, createdDate, sourceTypeText, deliveryDate, inspectionEndDate, notes } = sourceData || {};
    return (
        <Card className={className} {...rest}>
            <Card.CardContent className={'p-3'}>
                <Grid container spacing={2}>
                    <Grid xs={3} item>
                        <ReadTextField label={'分店'}>{clubName}</ReadTextField>
                    </Grid>
                    <Grid xs={3} item>
                        <ReadTextField label={'編號'}>{purchaseReceiptNo}</ReadTextField>
                    </Grid>
                    <Grid xs={3} item>
                        <ReadTextField label={'預計到貨日'}>{parseDate(deliveryDate, DateTimeStandard.DisplayFullBySlash)}</ReadTextField>
                    </Grid>
                    <Grid xs={3} item>
                        <ReadTextField
                            label={<InteractiveContentWrapper label={'點收截止日'} tip={'請於截止日完成點收，避免進貨單被凍結。'} iconSize={'18px'} />}
                        >
                            {parseDate(inspectionEndDate, DateTimeStandard.DisplayFullBySlash)}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={3} item>
                        <ReadTextField label={'建立時間'}>{parseDate(createdDate, DateTimeStandard.DisplayFullBySlash)}</ReadTextField>
                    </Grid>
                    <Grid xs={3} item>
                        <ReadTextField label={'來源'}>{sourceTypeText}</ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'訂單備註'}>{notes}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

export default PurchaseReceiptOrderInfoCard;
