import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, Divider, TextField, SecurityTextField, Alert, Form } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useAuthUserApi from '@apis/useAuthUserApi';
import useAuthApi from '@apis/useAuthApi';
import useSecurityConfirm from "@util/hook/useSecurityConfirm";

const ModifyPasswordDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps } = props;
    const { putModifyAuthPwdApi } = useAuthUserApi();
    const { getLogoutApi } = useAuthApi();
    const oriPwdRef = useRef(null);
    const newPwdRef = useRef(null);
    const confirmNewPwdRef = useRef(null);
    const { doConfirmPwd } = useSecurityConfirm()

    const getParams = () => {
        const oldSecurity = oriPwdRef.current.getResult();
        const security = newPwdRef.current.getResult();
        const reconfirmSecurity = confirmNewPwdRef.current.getResult();
        return {
            oldSecurity,
            security,
            reconfirmSecurity,
        };
    };

    const handleModifyPwd = () => {
        if (!refIsRequiredError(oriPwdRef, newPwdRef, confirmNewPwdRef) && doConfirmPwd(oriPwdRef, newPwdRef, confirmNewPwdRef)) {
            const params = getParams();
            putEditPwdApi(params);
        }
    };

    const putEditPwdApi = async (params) => {
        const resp = await putModifyAuthPwdApi(params);
        if (resp) {
            setTimeout(() => {
                getLogoutApi();
            }, 5000);
            onCloseProps && onCloseProps();
        }
    };

    return (
        <Dialog open={!!openProps} PaperProps={{ component: Form }}>
            <GradientColorHeader onClose={onCloseProps}>修改店舖密碼</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField ref={oriPwdRef} label="原密碼" type="password" required fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SecurityTextField ref={newPwdRef} label="新密碼" required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField ref={confirmNewPwdRef} label="新密碼確認" type="password" required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info" variant="filled" color="info">
                            店舖密碼修改後，新密碼即刻生效
                        </Alert>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleModifyPwd}>
                    修改
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ModifyPasswordDialog;
