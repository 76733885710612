import React from 'react';
import { Typography } from '@common/components/';

const PasswordAgeWarningTextBox = React.forwardRef((props, ref) => {
    return (
        <React.Fragment>
            <Typography variant="h3" className="text-center" color="error">
                密碼即將到期
            </Typography>
            <Typography component={'div'} variant="h5" className="text-center my-3">
                您的密碼即將到期，
            </Typography>
            <Typography component={'div'} variant="h5" className="text-center my-3">
                建議您儘早更換密碼以保障帳號安全。
            </Typography>
        </React.Fragment>
    );
});

export default PasswordAgeWarningTextBox;
