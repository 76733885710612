import React, { useEffect, useState } from 'react';
import { Button } from '@common/components/';
import MembershipFormAvatarDialog from '@icoach/members/dialog/MembershipFormAvatarDialog';
import CreateOrderDialog from '@icoach/accountBook/dialog/CreateOrderDialog';
import { DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_PATH } from '@icoach/router/routerPath';
import { useHistory } from 'react-router-dom';
import useShowPDF from '@util/hook/useShowPDF';
import useMessageDialog from '@util/hook/useMessageDialog';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { isEmpty } from '@util/utils';

const MemberDetailHealthButton = (props) => {
    const { memberID } = props;
    const history = useHistory();
    const showPDF = useShowPDF();
    const setMessageDialog = useMessageDialog();
    const [detailHealthData, setDetailHealthData] = useState({});
    const { getMemberProfileHealthDeclarationApi } = useMemberProfileApi();

    const handleClickHealthDeclarationButton = () => {
        const { isContract = false, url = '' } = detailHealthData;
        if (isContract) {
            showPDF({
                open: true,
                pdfUrl: url,
            });
        } else {
            setMessageDialog({
                open: true,
                title: '查無健康申告書',
                msg: '會員還沒填寫過健康申告書，請教練協助會員填寫，是否要轉跳到新增頁面呢？',
                onAsyncConfirm: handleGoToDocHealthDeclaration,
            });
        }
    };

    const handleGoToDocHealthDeclaration = (e, onClose) => {
        const path = DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(2, memberID, 0);
        onClose && onClose();
        history.push(path);
    };

    const checkHealthDeclarationDocumentApi = async (id) => {
        const resp = await getMemberProfileHealthDeclarationApi(id);
        if (resp) {
            setDetailHealthData(resp);
        }
    };

    useEffect(
        () => {
            if (memberID) {
                checkHealthDeclarationDocumentApi(memberID);
            }
        },
        // eslint-disable-next-line
        [memberID],
    );

    if (isEmpty(detailHealthData)) return null;

    return (
        <Button variant="outlined" onClick={handleClickHealthDeclarationButton}>
            健康申告書
        </Button>
    );
};

const MembershipCreateNewMemberBox = React.forwardRef((props, ref) => {
    const { memberID } = props;
    if (!/^\d+$/.test(memberID)) return null;
    const [openDialog, setOpenDialog] = useState(null);

    const handleOnClose = () => {
        setOpenDialog(null);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={() => setOpenDialog('take-pciture')}>
                新增大頭照
            </Button>
            <Button variant="outlined" onClick={() => setOpenDialog('buy-products')}>
                購買商品
            </Button>
            <MemberDetailHealthButton memberID={memberID} />
            <CreateOrderDialog open={openDialog === 'buy-products'} memberID={memberID} onClose={handleOnClose} />
            <MembershipFormAvatarDialog open={openDialog === 'take-pciture'} memberID={memberID} onClose={handleOnClose} />
        </React.Fragment>
    );
});

export default MembershipCreateNewMemberBox;
