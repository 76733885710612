import React, { useEffect, useState } from 'react';
import { Box } from '@common/components/';
import RolePermissionTable from '@icoach/club/rolePermission/RolePermissionTable';
import useClubPermissionApi from '@apis/useClubPermissionApi';
import { isEmpty } from '@util/utils';
import RolePermissionSwitchBox from '@icoach/club/rolePermission/RolePermissionSwitchBox';

const RolePermissionPage = React.forwardRef((props, ref) => {
    const { getClubPermissionsOptionsApi } = useClubPermissionApi();

    const [rowsLabel, setRowsLabel] = useState(null);
    const [options, setOptions] = useState(null);

    const intialize = async () => {
        const resp = await getClubPermissionsOptionsApi();
        if (resp) {
            const { basicPermissionList } = resp;
            let labels = [
                {
                    cellKey: 'employeeRankTypeText',
                    headerLabel: '職稱',
                    width: 10,
                    maxWidth: 10,
                },
                ...basicPermissionList,
            ];
            labels = labels.map((user, _index) => {
                if (_index === 0 || !user.isEnabled) {
                    return user;
                } else {
                    return {
                        cellKey: user.permissionKey,
                        headerLabel: user.permissionName,
                        width: 10,
                        maxWidth: 10,
                        align: 'center',
                        formatCell: (value, _rowData, { headerLabel, cellKey }) => {
                            const pID = user.permissionID;
                            const pList = _rowData['basicPermissionList'];
                            const clubID = _rowData.clubID;
                            const targetData = pList.find((tar) => tar.permissionID === pID);
                            if (!isEmpty(targetData)) {
                                return (
                                    <RolePermissionSwitchBox
                                        defaultValue={targetData.isEnabled}
                                        sourceData={_rowData}
                                        headerLabel={headerLabel}
                                        permissionID={pID}
                                        clubID={clubID}
                                    />
                                );
                            } else return null;
                        },
                    };
                }
            });
            setOptions(resp);
            setRowsLabel(labels);
        }
    };

    useEffect(
        () => {
            intialize();
        },
        // eslint-disable-next-line
        []
    );

    return <Box className="container main-container-spacing">{!isEmpty(rowsLabel) && <RolePermissionTable rowsLabel={rowsLabel} options={options} />}</Box>;
});

export default RolePermissionPage;
