import React, { useImperativeHandle, useRef, useState } from 'react';
import PurchaseInspectionDialog from '@icoach/purchaseReceipt/PurchaseInspectionDialog';
import PurchaseReceiptStorageRecordDialog from '@icoach/purchaseReceipt/PurchaseReceiptStorageRecordDialog';

const PURCHASE_INSPECTION_DIALOG = 'purchaseInspectionDialog';
const PURCHASE_RECEIPT_STORAGE_RECORD_DIALOG = 'purchaseReceiptStorageRecordDialog';

const PurchaseInspectionDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const doRefresh = (...arg) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') {
            refreshRef.current(...arg);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    useImperativeHandle(
        ref,
        () => ({
            openPurchaseInspectionDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                refreshRef.current = refreshFn;
                setOpenDialog(PURCHASE_INSPECTION_DIALOG);
            },
            openPurchaseReceiptStorageRecordDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                refreshRef.current = refreshFn;
                setOpenDialog(PURCHASE_RECEIPT_STORAGE_RECORD_DIALOG);
            },
            closeDialog: () => handleCloseDialog(),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <>
            <PurchaseInspectionDialog
                key={openDialog === PURCHASE_INSPECTION_DIALOG}
                open={openDialog === PURCHASE_INSPECTION_DIALOG}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PurchaseReceiptStorageRecordDialog
                key={openDialog === PURCHASE_RECEIPT_STORAGE_RECORD_DIALOG}
                open={openDialog === PURCHASE_RECEIPT_STORAGE_RECORD_DIALOG}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
        </>
    );
});

export default PurchaseInspectionDialogBox;
