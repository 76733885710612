import useAjax from '@apis/useAjax';
import { GET_INSPECTION_ORDER_API, POST_INSPECTION_ORDER_API } from '@apis/apiPath';
import { useCallback } from 'react';

const useInspectionOrderApi = () => {
    const Ajax = useAjax();

    const getInspectionOrderApi = useCallback(
        async (inspectionOrderID) => await Ajax.get(GET_INSPECTION_ORDER_API(inspectionOrderID)),
        // eslint-disable-next-line
        [],
    );

    const postInspectionOrderApi = useCallback(
        async (params) => await Ajax.post(POST_INSPECTION_ORDER_API, params),
        // eslint-disable-next-line
        [],
    );

    return {
        getInspectionOrderApi,
        postInspectionOrderApi,
    };
};

export default useInspectionOrderApi;
