import { useState, useEffect, useRef } from 'react';
import useQueryManager from '@util/hook/useQueryManager';
import { paramsToObject, isEmpty } from '@util/utils';

const useOverview = ({ getOptionsApi, getOverviewApi, defaultQueryParams = {}, 
                         isMasonryPagination = false, isEffectFetchOverview = true }) => {
    const { getQuery, updateQuery } = useQueryManager();
    const searchParams = paramsToObject(getQuery());

    const [options, setOptions] = useState({});
    const [overviewData, setOverviewData] = useState([]);

    const prevListRef = useRef([]);

    const isOptionsLoadingRef = useRef(false);
    const isOverviewLoadingRef = useRef(false);

    const fetchOptions = async () => {
        if (isOptionsLoadingRef.current) return;

        isOptionsLoadingRef.current = true;
        try {
            const resp = await getOptionsApi();
            if (resp) setOptions(resp);
        } catch (error) {
            console.error('Failed to fetch options:', error);
            setOptions({});
        } finally {
            isOptionsLoadingRef.current = false;
        }
    };

    const fetchOverview = async (params = searchParams) => {
        
        if (isOverviewLoadingRef.current) return;

        isOverviewLoadingRef.current = true;
        try {
            const resp = await getOverviewApi(params);
            if (resp) {
                let result = {...resp};
                if (isMasonryPagination && result.pageIndex !== 1) {
                    result.list = [...prevListRef.current, ...resp.list];
                }
                prevListRef.current = result.list;
                setOverviewData(result);
            };
        } catch (error) {
            console.error('Failed to fetch overview:', error);
        } finally {
            isOverviewLoadingRef.current = false;
        }
    };

    const handleClearSearch = () => {
        updateQuery(defaultQueryParams);
    };

    useEffect(
        () => {
            if (typeof getOptionsApi === 'function') fetchOptions();
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (!isEmpty(searchParams)) {
                fetchOverview(searchParams);
            } else if (isEmpty(searchParams) && isEffectFetchOverview && !isEmpty(defaultQueryParams)) {
                updateQuery(defaultQueryParams);
            }
        },
        // eslint-disable-next-line
        [searchParams, defaultQueryParams],
    );

    return {
        options,
        overviewData,
        searchParams,
        updateQuery,
        getOverviewApi: fetchOverview,
        handleClearSearch,
    };
};

export default useOverview;
