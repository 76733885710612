import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Button, ButtonGroup, Card } from '@common/components/';
import BatchPaymentOrderBillingPage from '@icoach/accountBook/BatchPaymentOrderBillingPage';
import BatchPaymentOrdersByCreditCard from '@icoach/accountBook/BatchPaymentOrdersByCreditCard';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import BatchPaymentOrdersByACH from '@icoach/accountBook/BatchPaymentOrdersByACH';
import BatchPaymentOrdersByCash from '@icoach/accountBook/BatchPaymentOrdersByCash';
import { paymentTypeMap } from '@icoach/rentCabinet/staticData';
import { bulkDebitBillingType as bulkDebitBillingTypeMap } from '@icoach/accountBook/staticData';
import { BatchPaymentTabs } from '@icoach/accountBook/components';

export const PaymentTypeButtons = (props) => {
    const { paymentType, setPaymentType } = props;

    return (
        <ButtonGroup className={'report-btn-group mb-2'}>
            <Button className={clsx({ current: String(paymentType) === paymentTypeMap.credit })} onClick={() => setPaymentType(paymentTypeMap.credit)}>
                信用卡
            </Button>
            <Button className={clsx({ current: String(paymentType) === paymentTypeMap.ach })} onClick={() => setPaymentType(paymentTypeMap.ach)}>
                ACH
            </Button>
            <Button className={clsx({ current: String(paymentType) === paymentTypeMap.cash })} onClick={() => setPaymentType(paymentTypeMap.cash)}>
                現金
            </Button>
        </ButtonGroup>
    );
};

// 當期扣款名單
const CurrentOrdersContent = () => {
    const { paramData, tableData, setPaymentType } = useAccountBookContext();
    const { creditItems, achItems, cashItems = [] } = tableData || {};
    const { paymentType } = paramData;

    return (
        <React.Fragment>
            <PaymentTypeButtons paymentType={paymentType} setPaymentType={setPaymentType} />
            {String(paymentType) === paymentTypeMap.credit && <BatchPaymentOrdersByCreditCard tableData={creditItems} />}
            {String(paymentType) === paymentTypeMap.ach && <BatchPaymentOrdersByACH tableData={achItems} />}
            {String(paymentType) === paymentTypeMap.cash && <BatchPaymentOrdersByCash tableData={cashItems} />}
        </React.Fragment>
    );
};

const ExemptedOrdersContent = () => {
    const { tableData } = useAccountBookContext();
    const { noPaymentItems = [] } = tableData || {};

    return <BatchPaymentOrdersByCash tableData={noPaymentItems} />;
};

// 欠費名單
const OverdueOrdersContent = (props) => {
    const { paramData, tableData, setPaymentType } = useAccountBookContext();
    const { paymentType } = paramData;
    const { creditUnpaidItems, achUnpaidItems, cashUnpaidItems } = tableData || {};

    return (
        <React.Fragment>
            <PaymentTypeButtons paymentType={paymentType} setPaymentType={setPaymentType} />
            {String(paymentType) === paymentTypeMap.credit && <BatchPaymentOrdersByCreditCard tableData={creditUnpaidItems} />}
            {String(paymentType) === paymentTypeMap.ach && <BatchPaymentOrdersByACH tableData={achUnpaidItems} />}
            {String(paymentType) === paymentTypeMap.cash && <BatchPaymentOrdersByCash tableData={cashUnpaidItems} />}
        </React.Fragment>
    );
};

const BatchPaymentOrderMembershipPage = (props) => {
    const { tableData, paramData } = useAccountBookContext();
    const { bulkDebitBillingType } = paramData;

    return (
        <Card className={clsx('tabs-content-layout bulk-debit-content', {'hidden': _.isEmpty(tableData)})}>
            <BatchPaymentTabs />
            <Card.CardContent className={'p-4'}>
                {bulkDebitBillingType === bulkDebitBillingTypeMap.billing && <BatchPaymentOrderBillingPage />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.currentOrders && <CurrentOrdersContent />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.exemptedOrders && <ExemptedOrdersContent />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.overdueOrders && <OverdueOrdersContent />}
            </Card.CardContent>
        </Card>
    );
};

export default BatchPaymentOrderMembershipPage;
