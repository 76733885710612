import { useCallback } from 'react';
import useAjax from "@apis/useAjax";

const GET_SECURITY_EXPORT_API = `/api/security-export`;

const useSecurityExportApi = () => {
    const Ajax = useAjax();
    
    const getExportPermissionApi = useCallback(
        async () => {
            return await Ajax.get(GET_SECURITY_EXPORT_API);
        },
        // eslint-disable-next-line
        [],
    )
    
    return {
        getExportPermissionApi
    };
}

export default useSecurityExportApi;