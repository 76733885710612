import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent } from '@mui/material';
import Tablenization from '@icoach/components/Tablenization';
import { parseDate } from '@util/moment';
import { BusinessCardBox } from '@icoach/components';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const defaultRowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '紀錄時間',
        formatCell: (date) => {
            return parseDate(date, DateTimeStandard.DisplayDate);
        },
    },
    {
        cellKey: 'maskDisplayName',
        headerLabel: '對象',
        minWidth: 18,
        formatCell: (value, { memberNo }) => {
            const data = {
                memberName: value,
                memberNo,
                // sourceTypeText: cellPhone,
            };
            return <BusinessCardBox sourceData={data} isShowAvatar={false} />;
        },
    },
    {
        cellKey: 'maskCellPhone',
        headerLabel: '手機',
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
    },

    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
    },
];

const ReportDetailDialogContent = (props) => {
    const { rowsData = [], rowsLabel = [] } = props;
    return (
        <DialogContent className={'p-4'}>
            <Tablenization cardProps={{ className: 'theme-gray' }}>
                <Tablenization.TableRowHeader headerRow={rowsLabel} />
                <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
            </Tablenization>
        </DialogContent>
    );
};

const ReportDetailDialog = React.forwardRef((props, ref) => {
    const { open, sourceData, onClose } = props;

    if (!open) return null;

    const { title, apiFn, dateString, dataKeyName, tableRowsLabel: rowsLabel = defaultRowsLabel } = sourceData;
    const [rowsData, setRowsData] = useState([]);

    const getDataApi = async (_dateString, _dataKeyName) => {
        let resp = await apiFn(_dateString, _dataKeyName);
        if (resp) {
            setRowsData(resp);
        }
    };

    useEffect(
        () => {
            if (open && dateString && dataKeyName) {
                const _dateString = parseDate(dateString, 'YYYY-MM');
                getDataApi(_dateString, dataKeyName);
            }
        },
        // eslint-disable-next-line
        [open, dateString, dataKeyName]
    );

    return (
        <Dialog className={clsx('simple-content', 'dialog')} PaperProps={{ className: 'wx-80rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            <ReportDetailDialogContent rowsData={rowsData} rowsLabel={rowsLabel} />
        </Dialog>
    );
});

export default ReportDetailDialog;
