import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_ME_MENUS_API = '/api/me/menus/';

const useNavigationApi = () => {
    const Ajax = useAjax();

    const getMeMenusApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_ME_MENUS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMeMenusApi,
    };
};

export default useNavigationApi;
