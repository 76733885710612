import React from 'react';
import clsx from 'clsx';
import { isFilled } from './utils';
import FormControlContext from '../formControl/FormControlContext';
import useFormControl from '../formControl/useFormControl';
import formControlState from '../formControl/formControlState';
import TextareaAutosize from '../textareaAutosize/TextareaAutosize';
import useEnhancedEffect from '@util/hook/useEnhancedEffect';
import useForkRef from '@util/hook/useForkRef';

/*      Event
    handleFocus
    handleBlur
    handleChange
    handleClick
    handleAutoFill
*/

export const InputBaseRoot = React.forwardRef((props, ref) => {
    const { onClick, className, children, ownerState, style = {} } = props;
    const staticClass = `inputbase-root`;
    let classNames = staticClass;

    if (ownerState.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (ownerState.multiline) classNames = clsx(classNames, `${staticClass}-multiline`);
    if (ownerState.multiline && ownerState.size === 'small') classNames = clsx(classNames, `${staticClass}-size-small`);
    if (ownerState.fullWidth) classNames = clsx(classNames, `${staticClass}-fullWidth`);

    return (
        <div onClick={onClick} className={clsx(classNames, className)} style={{ ...style }} ref={ref}>
            {children}
        </div>
    );
});

export const InputBaseComponent = React.forwardRef((props, ref) => {
    const {
        className,
        ownerState,
        value,
        defaultValue,
        onKeyDown,
        onKeyUp,
        placeholder,
        readOnly,
        required,
        name,
        id,
        type,
        onBlur,
        onChange,
        onFocus,
        disabled,
        as: Component,
        style = {},
        autoComplete,
        ...other
    } = props;
    const staticClass = `inputbase-input`;
    let classNames = staticClass;
    if (ownerState.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (ownerState.size === 'small') classNames = clsx(classNames, `${staticClass}-size-small`);
    if (ownerState.multiline) classNames = clsx(classNames, `${staticClass}-multiline`);

    const defaultProps = {
        className: clsx(classNames, className),
        disabled,
        defaultValue,
        value,
        onKeyDown,
        onKeyUp,
        placeholder,
        readOnly,
        required,
        name,
        id,
        type,
        ref: ref,
        onBlur,
        onChange,
        onFocus,
        style,
        autoComplete: autoComplete,
        ...other,
    };

    return <Component {...defaultProps} />;
});

const InputBase = React.forwardRef((props, ref) => {
    const {
        name,
        id,
        className,
        components,
        defaultValue,
        disabled: disabledProps,
        inputComponent = 'input',
        inputProps: inputPropsProp = {},
        inputRef: inputRefProp,
        value: valueProp,
        onChange,
        onClick,
        onBlur,
        onFocus,
        onWheel,
        onKeyDown,
        onKeyUp,
        placeholder,
        readOnly,
        startAdornment,
        endAdornment,
        multiline = false,
        fullWidth = false,
        renderSuffix,
        rows,
        maxRows,
        minRows,
        type = 'text',
        onChangeNative,
        isError: isErrorProps,
        autoComplete,
        ...other
    } = props;

    const value = inputPropsProp.value != null ? inputPropsProp.value : valueProp;
    const [focused, setFocused] = React.useState(false);
    const { current: isControlled } = React.useRef(value != null);
    const inputRef = React.useRef();
    const cacheValue = React.useRef({
        currentValue: value,
        prevValue: value,
        changeEventObj: null,
    });

    const handleInputRefProp = useForkRef(inputRefProp, inputPropsProp.ref);
    const handleInputRef = useForkRef(inputRef, handleInputRefProp);

    const formControl = useFormControl();
    const fcs = formControlState({
        props,
        formControl,
        states: ['color', 'disabled', 'error', 'size', 'required', 'filled'],
    });
    fcs.focused = formControl ? formControl.focused : focused;

    // The blur won't fire when the disabled state is set on a focused input.
    // We need to book keep the focused state manually.
    React.useEffect(() => {
        if (!formControl && disabledProps && focused) {
            setFocused(false);
            onBlur && onBlur();
        }
    }, [formControl, disabledProps, focused, onBlur]);

    const onFilled = formControl && formControl.onFilled; //有值
    const onEmpty = formControl && formControl.onEmpty; //空值

    const checkDirty = React.useCallback(
        (obj) => {
            if (isFilled(obj)) {
                onFilled && onFilled();
            } else {
                onEmpty && onEmpty();
            }
        },
        [onFilled, onEmpty]
    );

    useEnhancedEffect(() => {
        if (isControlled) {
            checkDirty({ value });
        }
    }, [value, checkDirty, isControlled]);

    const handleFocus = (event) => {
        // Fix a bug with IE11 where the focus/blur events are triggered
        // while the component is disabled.
        if (fcs.disabled) {
            event.stopPropagation();
            return;
        }

        onFocus && onFocus(event);
        inputPropsProp.onFocus && inputPropsProp.onFocus(event);

        if (formControl && formControl.onFocus) {
            formControl.onFocus(event);
        } else {
            setFocused(true);
        }
    };

    const handleBlur = (event) => {
        if (cacheValue.current.currentValue !== cacheValue.current.prevValue && typeof onChangeNative === 'function') {
            onChangeNative(cacheValue.current.changeEventObj, cacheValue.current.currentValue);
            cacheValue.current.prevValue = cacheValue.current.currentValue;
        }
        onBlur && onBlur(event);
        inputPropsProp.onBlur && inputPropsProp.onBlur(event);
        if (formControl && formControl.onBlur) {
            formControl.onBlur(event);
        } else {
            setFocused(false);
        }
    };

    const handleChange = (event, ...args) => {
        const element = event.target || inputRef.current;
        const value = element.value;
        if (!isControlled) {
            if (element == null) {
                console.error(
                    'Did you use a custom `inputComponent` and forget to forward refs? See https://mui.com/r/input-component-ref-interface for more info.'
                );
            }
            checkDirty({
                value: value,
            });
        }
        cacheValue.current.currentValue = value;
        cacheValue.current.changeEventObj = event;
        inputPropsProp.onChange && inputPropsProp.onChange(event, ...args);
        // Perform in the willUpdate
        onChange && onChange(event, ...args);
    };

    const handleClick = (event) => {
        if (inputRef.current && event.currentTarget === event.target) {
            inputRef.current.focus();
        }
        onClick && onClick(event);
    };

    const handleWheel = (event) => {
        if (type === 'number') {
            document.activeElement.blur();
        }
        onWheel && onWheel(event);
    };
    // const handleAutoFill = (event) => {
    //     // Provide a fake value as Chrome might not let you access it for security reasons.
    //     checkDirty(event.animationName === 'mui-auto-fill-cancel' ? inputRef.current : { value: 'x' });
    // };

    // Check the input state on mount, in case it was filled by the user
    // or auto filled by the browser before the hydration (for SSR).
    React.useEffect(() => {
        checkDirty(inputRef.current);
        // eslint-disable-next-line
    }, []);

    let InputComponent = inputComponent;
    let inputProps = inputPropsProp;
    if (multiline && InputComponent === 'input') {
        if (rows) {
            inputProps = {
                type: undefined,
                minRows: rows,
                maxRows: rows,
                ...inputProps,
            };
        } else {
            inputProps = {
                type: undefined,
                maxRows,
                minRows,
                ...inputProps,
            };
        }
        InputComponent = TextareaAutosize;
    }

    const Root = components.Root || InputBaseRoot;
    const Input = components.Input || InputBaseComponent;

    React.useEffect(() => {
        if (formControl) {
            formControl.setAdornedStart(Boolean(startAdornment));
        }
    }, [formControl, startAdornment]);

    const ownerState = {
        disabled: fcs.disabled || disabledProps,
        error: fcs.error || isErrorProps,
        focused: fcs.focused,
        size: fcs.size,
        startAdornment,
        endAdornment,
        multiline,
        fullWidth,
        type,
    };

    return (
        <Root ref={ref} onClick={handleClick} ownerState={ownerState} style={components.rootStyle} {...other}>
            {startAdornment}
            <FormControlContext.Provider value={null}>
                <Input
                    autoComplete={autoComplete}
                    value={value}
                    defaultValue={defaultValue}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={fcs.disabled}
                    required={fcs.required}
                    ref={handleInputRef}
                    // onAnimationStart={handleAutoFill}
                    name={name}
                    id={id}
                    type={type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onWheel={handleWheel}
                    as={InputComponent}
                    ownerState={ownerState}
                    style={components.inputStyle}
                    {...inputProps}
                />
            </FormControlContext.Provider>
            {endAdornment}
            {renderSuffix
                ? renderSuffix({
                      ...fcs,
                      startAdornment,
                  })
                : null}
        </Root>
    );
});

export default InputBase;
