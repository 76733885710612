import React, { useRef } from "react";
import useMessageDialog from "./useMessageDialog";
import useSecurityExportApi from "@apis/useSecurityExportApi";
import { Grid } from "@mui/material";
import { Alert, Button, TextField, Typography } from "@common/components/";

const useExportActionDialog = (props = {}) => {
    const { checkExportPermission = true } = props;
    const exportSecurityRef = useRef(null);
    const setMessageDialog = useMessageDialog();
    const { getExportPermissionApi } = useSecurityExportApi();

    const doCheckExportPermission = async () => {
        if (checkExportPermission) {
            const resp = await getExportPermissionApi();
            return showExportPolicyDialog(resp)
        }

        return true;
    }

    const showExportPolicyDialog = (resp) => {
        if (resp) {
            const { message, url } = resp;
            setMessageDialog({
                open: true,
                title: '個資資訊安全原則',
                MsgComp: () => (
                    <div>
                        <Typography className="word-break-break-word">{message}</Typography>
                        <p />
                        <Button to={url} color="error">
                            保管責任書
                        </Button>
                    </div>
                ),
            });

            return false;
        }

        return true;
    }
    const showExportConfirmDialog = async (originalParams, onConfirm) => {
        if (await doCheckExportPermission()) {
            setMessageDialog({
                open: true,
                title: '檔案密碼',
                dialogPaperProps: { className: 'wx-50rem' },
                MsgComp: () => (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="textSecondary">根據個人資料保護規範，下載的檔案需要密碼保護。</Typography>
                                <TextField ref={exportSecurityRef} label="請輸入開啟檔案的密碼(最少4碼)" type={'password'} fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start font-size-small">
                                    請妥善保管您的密碼，避免被他人使用，並在使用完檔案後，盡快刪除以確保資料安全，降低不必要的風險。
                                </Alert>
                            </Grid>
                        </Grid>
                    </div>
                ),
                onAsyncConfirm: (_, onClose) => {
                    const params = {
                        exportSecurity: exportSecurityRef.current.getResult(),
                        ...originalParams
                    };
                    onConfirm(params);
                    onClose();
                },
            });
        }
    }

    return {
        showExportConfirmDialog,
        showExportPolicyDialog
    }
};

export default useExportActionDialog;