import { useState, useCallback } from 'react';

const useTabState = (initialTab) => {
    const [activeTab, setActiveTab] = useState(initialTab);

    const handleTabChange = useCallback((event, newValue) => {
        setActiveTab(newValue);
    }, []);

    return { activeTab, handleTabChange };
};

export default useTabState;
