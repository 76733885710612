import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Card, DateIntervalField, Form, MenuItem, SelectField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import useOrderApi from '@apis/useOrderApi';
import { getCurrentEndDay, getCurrentStartDate, parseDate } from '@util/moment';
import { useAccountBookContext } from './components/AccountBookContext';
import { initBookmark } from './staticData';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';
import { ACCOUNT_BOOK_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import { useHistory } from 'react-router-dom';

const AccountBookOrderOverviewSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { postOrderOverviewAdvanceApi, getOrdersOptionsApi } = useOrderApi();
    const { orderSourceType, setTableData, bookmark, setBookmarkNum, setRefresh } = useAccountBookContext();
    const [paramsData, setParamsData] = useState({});
    const { name = '', startDate = '', endDate = '', orderType = '99', status = '99', receiverType = '99' } = paramsData || {};
    const [options, setOptions] = useState({});
    const { integrateStatusOptions = [], receiverTypeOptions = [], orderTypeOptions = [] } = options;
    const nameRef = useRef(null);
    const dateRangeRef = useRef(null);
    const receiverTypeRef = useRef(null);
    const statusRef = useRef(null);
    const orderTypeRef = useRef(null);
    const preloadRef = useRef(false);

    const updateUrlWithSearchQuery = (params) => {
        let url = ACCOUNT_BOOK_ORDERS_OVERVIEW_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOverviewApi = async (params) => {
        const res = await postOrderOverviewAdvanceApi(params);
        if (res) setTableData(res);
        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
    };

    const getParams = (pageInfo = {}, orderSourceType) => {
        const result = {};

        if (pageInfo) {
            Object.assign(result, pageInfo);
        }

        if (orderSourceType) {
            Object.assign(result, { orderSourceType });
        }

        if (nameRef.current && nameRef.current.getResult) {
            Object.assign(result, { name: nameRef.current.getResult() });
        }

        if (dateRangeRef.current && dateRangeRef.current.getResult) {
            const { startDate, endDate } = dateRangeRef.current.getResult();
            Object.assign(result, { startDate: getCurrentStartDate(startDate), endDate: getCurrentEndDay(endDate) });
        }

        if (receiverTypeRef.current && receiverTypeRef.current.getResult) {
            Object.assign(result, { receiverType: receiverTypeRef.current.getResult() || 0 });
        }

        if (statusRef.current && statusRef.current.getResult) {
            Object.assign(result, { status: statusRef.current.getResult() || 0 });
        }

        if (orderTypeRef.current && orderTypeRef.current.getResult) {
            Object.assign(result, { orderType: orderTypeRef.current.getResult() || 0 });
        }

        return result;
    };

    const getOptionsApi = async () => {
        let res = await getOrdersOptionsApi();
        if (res) {
            setOptions(res);
            const { location } = history;
            const search = location.search;
            if (search) {
                const params = paramsToObject(search);
                if (!isEmpty(params)) {
                    setParamsData(params);
                }
                preloadRef.current = false;
                getOverviewApi(params);
            }

        }
    };

    const handleSearchOnClick = () => {
        const params = getParams(initBookmark(), orderSourceType);
        preloadRef.current = false;
        getOverviewApi(params);
        setBookmarkNum(1);
    };

    useEffect(
        () => {
            if (!preloadRef.current) return;
            const params = getParams(bookmark, orderSourceType);
            preloadRef.current = false;
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [orderSourceType],
    );

    useEffect(
        () => {
            if (!preloadRef.current) return;
            const params = getParams(bookmark, orderSourceType);
            if (String(bookmark.pageIndex) === '1') return;
            preloadRef.current = false;
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(
        () => {
            getOptionsApi();
            setRefresh(handleSearchOnClick);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('search-bar-light p-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnClick}>
                    <Grid spacing={2} container>
                        <Grid xs={4} item>
                            <TextField
                                key={name}
                                ref={nameRef}
                                label={'訂單/購買人'}
                                defaultValue={name}
                                InputProps={{ placeholder: '請輸入訂單編號或購買人或會員編號' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={8} item>
                            <DateIntervalField
                                key={`${startDate}_${endDate}`}
                                ref={dateRangeRef}
                                label={'建立日期'}
                                startDateProps={{
                                    defaultValue: parseDate(startDate),
                                }}
                                endDateProps={{
                                    defaultValue: parseDate(endDate),
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={receiverType} label={'購買人身份'} ref={receiverTypeRef} defaultValue={receiverType} fullWidth>
                                {Array.isArray(receiverTypeOptions) &&
                                    receiverTypeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={status} label={'狀態'} ref={statusRef} defaultValue={status} fullWidth>
                                {Array.isArray(integrateStatusOptions) &&
                                    integrateStatusOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={orderType} label={'訂單類型'} ref={orderTypeRef} defaultValue={orderType} fullWidth>
                                {Array.isArray(orderTypeOptions) &&
                                    orderTypeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={12} textAlign={'right'} item>
                            <Button className={'white-space-nowrap'} type={'submit'} variant={'contained'}>
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default AccountBookOrderOverviewSearchBar;
