import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_CLUB_PERMISSIONS_OPTIONS_API = `/api/club-permissions/options/`;
const GET_CLUB_PERMISSIONS_API = `/api/club-permissions/`;
const PUT_CLUB_PERMISSIONS_API = `/api/club-permissions/`;

const useClubPermissionApi = () => {
    const Ajax = useAjax();

    // 欄位options
    const getClubPermissionsOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CLUB_PERMISSIONS_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 總覽頁
    const getClubPermissionsOverviewApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CLUB_PERMISSIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改權限
    const putClubPermissionsApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_CLUB_PERMISSIONS_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return { getClubPermissionsOptionsApi, getClubPermissionsOverviewApi, putClubPermissionsApi };
};

export default useClubPermissionApi;
