import EmployeesPage from '@icoach/employees/EmployeesPage';
import EmployeesInformationsPage from '@icoach/employees/informations/EmployeesInformationsPage';
import EmployeesInformationsAddPage from '@icoach/employees/informations/EmployeesInformationsAddPage';
import ClubSystemPage from '@icoach/club/ClubSystemPage';
import ClubSuspensionPage from '@icoach/club/suspension/ClubSuspensionPage';
import {
    HUMAN_RESOURCES_TABS,
    HUMAN_RESOURCES_EMPLOYEES_OVERVIEW,
    HUMAN_RESOURCES_ROLES_PERMISSION_OVERVIEW,
    HUMAN_RESOURCES_ADD,
    HUMAN_RESOURCES_INFORMATION_EMPLOYEEID,
    SYSTEM_CONFIGURATION_TABS,
    SYSTEM_CONFIGURATION_BASIC,
    CLUB_CLOSED_CONFIGURATION,
} from '@icoach/router/routerPath';

// 員工
const employeesRouter = [
    {
        description: '店舖管理',
        frontKey: 'C10X01',
    },
    {
        description: '員工總覽',
        exact: true,
        path: HUMAN_RESOURCES_TABS,
        href: HUMAN_RESOURCES_EMPLOYEES_OVERVIEW,
        as: EmployeesPage,
        frontKey: 'C10X02',
    },
    {
        description: '新增員工',
        exact: true,
        path: HUMAN_RESOURCES_ADD,
        as: EmployeesInformationsAddPage,
        frontKey: 'C10X03',
    },
    {
        description: '員工資訊頁面',
        exact: true,
        path: HUMAN_RESOURCES_INFORMATION_EMPLOYEEID,
        as: EmployeesInformationsPage,
        frontKey: 'C10X04',
    },
    {
        description: '系統設定',
        exact: true,
        path: SYSTEM_CONFIGURATION_TABS,
        href: SYSTEM_CONFIGURATION_BASIC,
        as: ClubSystemPage,
        frontKey: 'C10X05',
    },
    {
        description: '營業暫停',
        exact: true,
        path: CLUB_CLOSED_CONFIGURATION,
        href: CLUB_CLOSED_CONFIGURATION,
        as: ClubSuspensionPage,
        frontKey: 'C10X06',
    },
    {
        description: '店舖權限',
        exact: true,
        path: HUMAN_RESOURCES_ROLES_PERMISSION_OVERVIEW,
        frontKey: 'C10X07',
    },
];

export default employeesRouter;
