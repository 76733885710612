import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Chip } from '@common/components/';
import TabsCardLayout from '@icoach/components/layout/TabsCardLayout';
import PageLayout from '@icoach/components/layout/PageLayout';
import { PURCHASE_RECEIPT_ORDER_PATH, PURCHASE_RECEIPT_OVERVIEW_ROUTER } from '@icoach/router/routerPath';
import ProductCard from '@icoach/components/product/ProductCard';
import OverviewListTable from '@icoach/components/overviewList/OverviewListTable';
import PurchaseReceiptOrderInfoCard from './PurchaseReceiptOrderInfoCard';
import usePurchaseReceiptApi from '@apis/usePurchaseReceiptApi';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import PurchaseInspectionDialogBox from '@icoach/purchaseReceipt/PurchaseInspectionDialogBox';
import { purchaseReceiptOrderStatusColorMap } from '@icoach/purchaseReceipt/staticData';

const purchaseReceiptTabsMap = {
    productList: 'product-list',
    stockInRecords: 'stock-in-records',
    stockInErrorRecords: 'stock-in-error-records',
};

const PurchaseReceiptOrderPage = () => {
    const { purchaseReceiptID, tabs: activeTab } = useParams();
    const { getPurchaseReceiptOrderApi } = usePurchaseReceiptApi();
    const [sourceData, setSourceData] = useState();

    const dialogRef = useRef(null);

    const tabData = useMemo(
        () => [
            {
                label: '商品清單',
                value: purchaseReceiptTabsMap.productList,
                component: NavLink,
                to: PURCHASE_RECEIPT_ORDER_PATH(purchaseReceiptID, purchaseReceiptTabsMap.productList),
            },
            {
                label: '入庫記錄',
                value: purchaseReceiptTabsMap.stockInRecords,
                component: NavLink,
                to: PURCHASE_RECEIPT_ORDER_PATH(purchaseReceiptID, purchaseReceiptTabsMap.stockInRecords),
            },
            {
                label: '驗收異常紀錄',
                value: purchaseReceiptTabsMap.stockInErrorRecords,
                component: NavLink,
                to: PURCHASE_RECEIPT_ORDER_PATH(purchaseReceiptID, purchaseReceiptTabsMap.stockInErrorRecords),
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const headerRowData = useMemo(
        () => ({
            [purchaseReceiptTabsMap.productList]: [
                {
                    cellKey: 'productID',
                    headerLabel: '商品',
                    formatCell: (productID, { productName, sku, variantName, pictureSrc }) => {
                        return <ProductCard productImage={pictureSrc} productName={productName} sku={sku} spec={variantName} />;
                    },
                },
                {
                    cellKey: 'quantity',
                    headerLabel: '訂購',
                    align: 'center',
                },
                {
                    cellKey: 'inspectionQuantity',
                    headerLabel: '點收',
                    align: 'center',
                },
                {
                    cellKey: 'alreadyInventoryQuantity ',
                    headerLabel: '已入庫',
                    align: 'center',
                },
            ],
            [purchaseReceiptTabsMap.stockInRecords]: [
                {
                    cellKey: 'inspectionDate',
                    headerLabel: '入庫時間',
                    formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
                },
                {
                    cellKey: 'inspectionQuantity',
                    headerLabel: '入庫數量',
                    align: 'center',
                },
                {
                    cellKey: 'employeeName',
                    headerLabel: '擔當',
                    align: 'center',
                },
                {
                    cellKey: '',
                    headerLabel: '',
                    align: 'right',
                    formatCell: (_, { inspectionOrderID }) => {
                        return (
                            <Button
                                onClick={() => {
                                    dialogRef.current.openPurchaseReceiptStorageRecordDialog({ inspectionOrderID });
                                }}
                            >
                                查看
                            </Button>
                        );
                    },
                },
            ],
            [purchaseReceiptTabsMap.stockInErrorRecords]: [
                {
                    cellKey: '',
                    headerLabel: '編號',
                },
                {
                    cellKey: '',
                    headerLabel: '申請時間',
                },
                {
                    cellKey: 'employeeName',
                    headerLabel: '擔當',
                },
                {
                    cellKey: '',
                    headerLabel: '原因',
                },
                {
                    cellKey: 'productID',
                    headerLabel: '',
                    formatCell: (productID, { productName, suk }) => {
                        return (
                            <Button component={NavLink} to={''}>
                                查看
                            </Button>
                        );
                    },
                },
            ],
        }),
        // eslint-disable-next-line
        [],
    );

    const listData = useMemo(
        () => ({
            [purchaseReceiptTabsMap.productList]: sourceData?.items || [],
            [purchaseReceiptTabsMap.stockInRecords]: sourceData?.inventoryRecords || [],
            [purchaseReceiptTabsMap.stockInErrorRecords]: [],
        }),
        // eslint-disable-next-line
        [sourceData],
    );

    const doPurchaseReceiptOrderApi = async (purchaseReceiptID) => {
        const resp = await getPurchaseReceiptOrderApi(purchaseReceiptID);

        if (resp) setSourceData(resp);
    };

    const pageActions = useMemo(
        () => {
            const { purchaseReceiptStatus } = sourceData || {};
            let actions = [
                {
                    label: '返回總覽',
                    variant: 'contained',
                    as: NavLink,
                    to: PURCHASE_RECEIPT_OVERVIEW_ROUTER,
                },
            ];

            if (String(purchaseReceiptStatus) === '3') {
                actions = [
                    ...actions,
                    {
                        label: '到貨點收',
                        variant: 'contained',
                        onClick: () =>
                            dialogRef.current.openPurchaseInspectionDialog({ ...sourceData, purchaseReceiptID }, () =>
                                doPurchaseReceiptOrderApi(purchaseReceiptID),
                            ),
                    },
                ];
            }
            return actions;
        },
        // eslint-disable-next-line
        [JSON.stringify(sourceData)],
    );

    useEffect(
        () => {
            if (purchaseReceiptID) {
                doPurchaseReceiptOrderApi(purchaseReceiptID);
            }
        },
        // eslint-disable-next-line
        [purchaseReceiptID],
    );

    return (
        <PageLayout>
            <PageLayout.Header title={'進貨單'} actions={pageActions}>
                <Chip
                    className={'ml-2'}
                    label={sourceData?.purchaseReceiptStatusText}
                    color={purchaseReceiptOrderStatusColorMap.get(sourceData?.purchaseReceiptStatus)}
                />
            </PageLayout.Header>
            <PurchaseReceiptOrderInfoCard className={'mb-3'} sourceData={sourceData} />
            <TabsCardLayout tabs={tabData} activeTab={activeTab} tabsContent={{ className: 'p-3' }}>
                <OverviewListTable headerRow={headerRowData[activeTab]} list={listData[activeTab]} isGray />
            </TabsCardLayout>
            <PurchaseInspectionDialogBox ref={dialogRef} />
        </PageLayout>
    );
};

export default PurchaseReceiptOrderPage;
