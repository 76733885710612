import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { initBookmark } from '@icoach/nonMember/staticData';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import useNonMemberApi from '@apis/useNonMemberApi';
import { stringToArray, arrayIntersection, objectToQueryString, isEmpty, paramsToObject, parseQueryStringToArray } from '@util/utils';
import {
    Box,
    Button,
    Card,
    TextField,
    SelectField,
    MenuItem,
    Form,
    Popover,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    DateIntervalField,
} from '@common/components/';
import { ArrowDropDown as ArrowDropDownIcon } from '@common/SvgIcon/';
import useWindowScrollPositions from '@util/hook/useWindowScrollPositions';
import { getSubMonths, parseDate } from '@util/moment';
import {
    NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER,
    NON_MEMBER_PROSPECT_OVERVIEW_ROUTER,
    NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER,
} from '@icoach/router/NonMemberRouter';

const getOptionsAllValue = (targetData = []) => targetData.map(({ value }) => value);

// 顧客進階表單元件
const customerAdvancedSelect = [
    {
        optionsName: 'validTypes',
        label: '顧客別',
        value: 2,
        name: 'validType',
    },
    {
        optionsName: 'customerStatus',
        label: '顧客狀態',
        value: getOptionsAllValue,
        name: 'customerStatus',
        isMultiple: true,
    },
    {
        optionsName: 'customerSourceTypes',
        label: '來源',
        value: getOptionsAllValue,
        name: 'source',
        isMultiple: true,
    },
    {
        optionsName: 'askRecordTypes',
        label: '詢問方式',
        value: getOptionsAllValue,
        name: 'askRecordType',
        isMultiple: true,
    },
    {
        optionsName: 'requiredTypes',
        label: '需求',
        value: getOptionsAllValue,
        name: 'required',
        isMultiple: true,
    },
];

// 名單進階表單元件
const prospectAdvancedSelect = [
    {
        optionsName: 'validTypes',
        label: '顧客別',
        value: 2,
        name: 'validType',
    },
    {
        optionsName: 'customerSourceTypes',
        label: '來源',
        value: getOptionsAllValue,
        name: 'source',
        isMultiple: true,
    },
    {
        optionsName: 'requiredTypes',
        label: '需求',
        value: getOptionsAllValue,
        name: 'required',
        isMultiple: true,
    },
];

const advancedItem = {
    customerOverview: { sourceData: customerAdvancedSelect },
    prospectOverview: { sourceData: prospectAdvancedSelect },
};

// 暫存管理搜尋元件
const TemporariesSearchBox = React.forwardRef((props, ref) => {
    const { paramsData = {}, options = [] } = props;
    const { name, customerType = 2 } = paramsData;
    const nameRef = useRef(null);
    const customerTypeRef = useRef(null);

    const getResult = () => {
        let result = {};

        if (nameRef.current && nameRef.current.getResult) {
            Object.assign(result, { name: nameRef.current.getResult() });
        }

        if (customerTypeRef.current && customerTypeRef.current.getResult) {
            Object.assign(result, { customerType: customerTypeRef.current.getResult() });
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult,
            };
        },
        // eslint-disable-next-line
        [],
    );
    return (
        <React.Fragment>
            <Grid xs={6} item>
                <TextField
                    key={name}
                    ref={nameRef}
                    defaultValue={name}
                    InputProps={{
                        placeholder: '請輸入標題',
                    }}
                    fullWidth
                />
            </Grid>
            <Grid xs={6} item>
                <SelectField key={customerType} ref={customerTypeRef} name={'customerType'} defaultValue={customerType} direction="row" fullWidth>
                    {options.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

// 顧客進階選項
const CustomerSearchBox = React.forwardRef((props, ref) => {
    const { paramsData: paramsDataProps, options = {} } = props;
    const paramsData = paramsDataProps || {};
    const { validTypes = [], customerStatus, customerSourceTypes, askRecordTypes, requiredTypes } = options || {};
    const validTypeRef = useRef(null);
    const customerStatusRef = useRef(null);
    const customerSourceTypesRef = useRef(null);
    const askRecordTypesRef = useRef(null);
    const requiredTypesRef = useRef(null);
    const nameRef = useRef(null);
    const dateRangeRef = useRef(null);

    const getResult = () => {
        let result = {
            isAdvanced: true,
        };

        if (nameRef.current && nameRef.current.getResult) {
            Object.assign(result, { name: nameRef.current.getResult() });
        }

        if (validTypeRef.current && validTypeRef.current.getResult) {
            Object.assign(result, { validType: validTypeRef.current.getResult() });
        }

        if (customerStatusRef.current && customerStatusRef.current.getResult) {
            Object.assign(result, { customerStatus: customerStatusRef.current.getResult() });
        }

        if (customerSourceTypesRef.current && customerSourceTypesRef.current.getResult) {
            Object.assign(result, { source: customerSourceTypesRef.current.getResult() });
        }

        if (askRecordTypesRef.current && askRecordTypesRef.current.getResult) {
            Object.assign(result, { askRecordType: askRecordTypesRef.current.getResult() });
        }

        if (requiredTypesRef.current && requiredTypesRef.current.getResult) {
            Object.assign(result, { required: requiredTypesRef.current.getResult() });
        }

        if (dateRangeRef.current && dateRangeRef.current.getResult) {
            Object.assign(result, dateRangeRef.current.getResult());
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult,
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid xs={6} item>
                <TextField
                    key={paramsData.name}
                    ref={nameRef}
                    label={'顧客'}
                    defaultValue={paramsData.name}
                    InputProps={{
                        placeholder: '請輸入姓名/手機號碼(擇一即可)',
                    }}
                    fullWidth
                />
            </Grid>
            <Grid xs={6} item>
                <DateIntervalField
                    key={`${paramsData.startDate}_${paramsData.endDate}`}
                    ref={dateRangeRef}
                    label={'詢問日期'}
                    startDateProps={{
                        defaultValue: parseDate(getSubMonths(paramsData.startDate, 6)),
                    }}
                    endDateProps={{
                        defaultValue: parseDate(paramsData.endDate),
                    }}
                    fullWidth
                />
            </Grid>
            <Grid xs={2} item>
                <SelectField key={paramsData.validType} ref={validTypeRef} defaultValue={paramsData.validType || 2} fullWidth>
                    {validTypes.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={text} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid xs={2} item>
                <MultipleSelectCheckedBox
                    key={paramsData.source}
                    label="來源"
                    options={customerSourceTypes}
                    ref={customerSourceTypesRef}
                    defaultValue={paramsData.source}
                />
            </Grid>
            <Grid xs={2} item>
                <MultipleSelectCheckedBox
                    key={paramsData.required}
                    label="需求"
                    options={requiredTypes}
                    ref={requiredTypesRef}
                    defaultValue={paramsData.required}
                />
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox
                    key={paramsData.customerStatus}
                    label="顧客狀態"
                    options={customerStatus}
                    ref={customerStatusRef}
                    defaultValue={paramsData.customerStatus}
                />
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox
                    key={paramsData.askRecordType}
                    label="詢問方式"
                    options={askRecordTypes}
                    ref={askRecordTypesRef}
                    defaultValue={paramsData.askRecordType}
                />
            </Grid>
        </React.Fragment>
    );
});

// 名單進階選項
const ProspectSearchBox = React.forwardRef((props, ref) => {
    const { paramsData, options } = props;
    const { validTypes = [], customerSourceTypes = [], requiredTypes = [] } = options;
    const validTypeRef = useRef(null);
    const customerSourceTypesRef = useRef(null);
    const requiredTypesRef = useRef(null);
    const nameRef = useRef(null);
    const dateRangeRef = useRef(null);

    const getResult = () => {
        let result = {
            isAdvanced: true,
        };

        if (nameRef.current && nameRef.current.getResult) {
            Object.assign(result, { name: nameRef.current.getResult() });
        }

        if (validTypeRef.current && validTypeRef.current.getResult) {
            Object.assign(result, { validType: validTypeRef.current.getResult() });
        }

        if (customerSourceTypesRef.current && customerSourceTypesRef.current.getResult) {
            Object.assign(result, { source: customerSourceTypesRef.current.getResult() });
        }

        if (requiredTypesRef.current && requiredTypesRef.current.getResult) {
            Object.assign(result, { required: requiredTypesRef.current.getResult() });
        }

        if (dateRangeRef.current && dateRangeRef.current.getResult) {
            Object.assign(result, dateRangeRef.current.getResult());
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult,
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid xs={4} item>
                <TextField
                    key={paramsData.name}
                    ref={nameRef}
                    label={'名單'}
                    defaultValue={paramsData.name}
                    InputProps={{
                        placeholder: '請輸入姓名/手機號碼(擇一即可)',
                    }}
                    fullWidth
                />
            </Grid>
            <Grid xs={8} item>
                <DateIntervalField
                    key={`${paramsData.startDate}_${paramsData.endDate}`}
                    ref={dateRangeRef}
                    label={'登錄日期'}
                    startDateProps={{
                        defaultValue: parseDate(getSubMonths(paramsData.endDate, 6)),
                    }}
                    endDateProps={{
                        defaultValue: parseDate(paramsData.endDate),
                    }}
                    fullWidth
                />
            </Grid>
            <Grid xs={4} item>
                <SelectField key={paramsData.validType} ref={validTypeRef} defaultValue={paramsData.validType || 2} fullWidth>
                    {validTypes.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={text} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid xs={4} item>
                <MultipleSelectCheckedBox
                    key={paramsData.source}
                    label={'來源'}
                    options={customerSourceTypes}
                    ref={customerSourceTypesRef}
                    defaultValue={paramsData.source}
                />
            </Grid>
            <Grid xs={4} item>
                <MultipleSelectCheckedBox
                    key={paramsData.required}
                    label={'需求'}
                    options={requiredTypes}
                    ref={requiredTypesRef}
                    defaultValue={paramsData.required}
                />
            </Grid>
        </React.Fragment>
    );
});

// 變形下拉式選單(內容有checkbox)
export const MultipleSelectCheckedBox = React.forwardRef((props, ref) => {
    const { options = [], label, showLabel = false, defaultValue } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedState, setChecked] = useState({});
    const preloadRef = useRef(false);

    const handleDefaultValue = (defaultValue) => {
        let result = {};

        if (typeof defaultValue === 'string') {
            defaultValue = parseQueryStringToArray(defaultValue);
        }

        if (Array.isArray(defaultValue) && defaultValue.length > 0) {
            defaultValue.forEach((item) => {
                result[item] = true;
            });
        }
        setChecked(result);
    };

    const handleAnchorElClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorElClose = () => {
        setAnchorEl(null);
    };

    const onCheckedChange = (e, isChecked) => {
        setChecked((pre) => ({
            ...pre,
            [e.target.value]: isChecked,
        }));
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    let result = Object.entries(checkedState)
                        .filter(([key, value]) => value)
                        .map(([num]) => num);
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        [checkedState],
    );

    useEffect(
        () => {
            if (!preloadRef.current) {
                handleDefaultValue(defaultValue);
                preloadRef.current = true;
            }
        },
        // eslint-disable-next-line
        [defaultValue],
    );

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const counts = Object.values(checkedState).filter((isTrue) => isTrue).length;
    return (
        <React.Fragment>
            <TextField
                label={showLabel && label}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" className={'cursor-pointer'}>
                            <ArrowDropDownIcon />
                        </InputAdornment>
                    ),
                }}
                value={label ? `${label}(${counts})` : ''}
                inputStyle={{ cursor: 'pointer' }}
                inputRootStyle={{ cursor: 'pointer' }}
                onClick={handleAnchorElClick}
                fullWidth
                readOnly
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleAnchorElClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className="py-2 px-4" style={{ width: 520 }}>
                    <Grid container spacing={2}>
                        {options.map(({ text, value, disabled }) => {
                            return (
                                <Grid item xs={4} key={text}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={!!checkedState[value]}
                                                value={value}
                                                onChange={onCheckedChange}
                                                defaultChecked={!!checkedState[value]}
                                            />
                                        }
                                        label={text}
                                        disabled={disabled}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Popover>
        </React.Fragment>
    );
});

const NonMemberSearchBar = React.forwardRef((props, ref) => {
    const { value: propsValue } = props;
    const history = useHistory();
    const { getInitOptionsApi, postOverviewApi } = useNonMemberApi();
    const { setTableData, bookmark, setBookmarkNum, setRefresh, setSearchParams, setScreener } = useNonMember();
    const [paramsData, setParamsData] = useState({}); // 初始預設搜尋條件使用,其他狀況下無用
    const searchRef = useRef(null);
    // 顧客/清單 進階收尋所有參數參考
    const screenerRef = useRef(null);
    // 防止再次打api
    const preloadRef = useRef(false);
    // 所有option
    const [options, setOptions] = useState({});

    useWindowScrollPositions();

    useImperativeHandle(screenerRef, () => {
        return {
            getResult: () => {
                let mesgAry = [];
                const _par = getParams();
                if (_par['name']) mesgAry.push(`搜尋字串: ${_par['name']}`);
                const filterMap = advancedItem[propsValue].sourceData;
                const currentFilter = filterMap.reduce((acc, cur) => {
                    let newAcc = [...acc];
                    const { label: _label, name: _name, optionsName: _optionsName } = cur;
                    const _val = stringToArray(_par[_name]);

                    if (_val.length > 0) {
                        let filterString = `${_label}: `;
                        filterString += arrayIntersection(_val, options[_optionsName], 'value', 'text');

                        newAcc.push(filterString);
                    }
                    return newAcc;
                }, []);
                mesgAry = [...mesgAry, ...currentFilter];

                return mesgAry.join(' & ');
            },
        };
    });

    /** 更新網址上的搜尋條件 **/
    const updateUrlWithSearchQuery = (params) => {
        let url = '';

        switch (propsValue) {
            case 'prospectOverview':
                url += NON_MEMBER_PROSPECT_OVERVIEW_ROUTER;
                break;
            case 'customerOverview':
                url += NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER;
                break;
            case 'temporariesOverview':
                url += NON_MEMBER_TEMPORARIES_OVERVIEW_ROUTER;
                break;
            default:
                break;
        }

        if (!isEmpty(params)) url += objectToQueryString(params);

        history.replace(url);
    };

    const handleListData = (list = []) => {
        let _list = list;
        if (Array.isArray(list)) {
            // customerID 如果存在就代表是 顧客 或 名單 type 是 1
            _list = list.map(({ ...others }) => ({
                ...others,
                id: others.customerID || others.temporaryID,
                type: others.customerID ? 1 : 0,
            }));
        }
        return _list;
    };

    // 搜尋api
    const doSearchDataApi = async (params, isRefresh = false) => {
        let resp = await postOverviewApi(propsValue, params);
        if (resp) {
            let { list, ...other } = resp;
            list = handleListData(list);
            // 刷新從第一頁開始
            if (isRefresh) {
                setTableData({ list, ...other });
            } else {
                setTableData((prev) => {
                    const prevList = prev.list;
                    let targetList = list;

                    if (Array.isArray(prevList)) {
                        targetList = prevList.concat(targetList);
                    }

                    return {
                        list: targetList,
                        ...other,
                    };
                });
            }
        }
        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
    };

    // 取得表格所有數值
    const getParams = (targetBookmark = {}) => {
        let paramsJson = {};

        if (targetBookmark) {
            Object.assign(paramsJson, targetBookmark);
        }

        if (searchRef.current && searchRef.current.getResult) {
            Object.assign(paramsJson, searchRef.current.getResult());
        }

        return paramsJson;
    };

    // 搜尋按鈕
    const handleSearchOnClick = () => {
        preloadRef.current = false;
        const params = getParams({ ...initBookmark });
        doSearchDataApi(params, true);

        setBookmarkNum(1);
    };

    // 剛載入初始資料
    const initOptionsData = async () => {
        const resp = await getInitOptionsApi(propsValue);
        if (resp) {
            setOptions(resp);
            const { location } = history;
            const params = paramsToObject(location.search);
            if (!isEmpty(params)) {
                setParamsData(params);
            }
            preloadRef.current = true;
            // doSearchDataApi(getParams(bookmark));
        }
    };

    useEffect(
        () => {
            // 第一頁不在這打API
            if (String(bookmark.pageIndex) === '1') return null;
            if (!preloadRef.current) return null;
            const params = getParams(bookmark);
            preloadRef.current = false;
            doSearchDataApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(
        () => {
            initOptionsData();
            setSearchParams(getParams); // 給暫存資料更多Icon判斷 顧客/名單 狀態使用
            setScreener(screenerRef);
            setRefresh(handleSearchOnClick);
            preloadRef.current = true;
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Form submit={handleSearchOnClick}>
            <Card>
                <Card.CardContent>
                    <Box className="p-3">
                        <Grid container spacing={2}>
                            {propsValue === 'temporariesOverview' && (
                                <TemporariesSearchBox
                                    ref={searchRef}
                                    paramsData={paramsData}
                                    options={options['customerTypeOption'] || []}
                                    onSearchClick={handleSearchOnClick}
                                />
                            )}
                            {propsValue === 'customerOverview' && <CustomerSearchBox ref={searchRef} paramsData={paramsData} options={options} />}
                            {propsValue === 'prospectOverview' && <ProspectSearchBox ref={searchRef} paramsData={paramsData} options={options} />}
                            <Grid className={'text-right'} xs={12} item>
                                <Button color="secondary" variant="contained" onClick={handleSearchOnClick} type="submit">
                                    搜尋
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

export default NonMemberSearchBar;
