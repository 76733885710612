import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthPrivateRoute from '@root/scripts/components/route/AuthPrivateRoute';
import { localUser, isEmpty } from '@util/utils';
import { LOGIN_ROUTER, LOGIN_COACH_ROUTER, LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH } from '@icoach/router/routerPath';
// mode is exact or strict

const RedirectComponent = (props) => {
    const { ...other } = props;
    return <Redirect {...other} />;
};

const PrivateRoute = ({ as: Component, isAuth = false, ...other }) => {
    const user = localUser.get();
    const employee = localUser.get('employee') || {};
    
    return (
        <Route
            {...other}
            render={(props) => {
                return user ? (
                    Boolean(user?.isUserAuthorized) ? (
                        isAuth ? (
                            <AuthPrivateRoute {...props} {...other} as={Component} />
                        ) : (
                            <Component {...props} {...other} />
                        )
                    ) : isEmpty(employee) ? (
                        <RedirectComponent to={LOGIN_COACH_ROUTER} />
                    ) : Boolean(employee?.isPasswordResetRequired) ? (
                        <RedirectComponent to={LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH(employee.token)} />
                    ) : (
                        <Component {...props} {...other} />
                    )
                ) : (
                    <RedirectComponent to={LOGIN_ROUTER} />
                );
            }}
        />
    );
};
export default PrivateRoute;
