import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Chip, DateIntervalField, Grid, MenuItem, SelectField, Stack } from '@common/components/';
import OverviewList from '@icoach/components/overviewList/OverviewList';
import usePurchaseReceiptApi from '@apis/usePurchaseReceiptApi';
import { displayDateText, getCurrentEndDay, getCurrentStartDate, parseDate, getToday, getSubMonths } from '@util/moment';
import { PURCHASE_RECEIPT_ORDER_PATH } from '@icoach/router/routerPath';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import { initBookmark, purchaseReceiptOrderStatusColorMap } from './staticData';
import { MemoControllerBox, PageSortAndTotalBox } from '@icoach/components';
import PageLayout from '@icoach/components/layout/PageLayout';
import { refIsRequiredError } from '@util/utils';
import useOverview from '@util/hook/useOverview';
import InteractiveContentWrapper from '@icoach/components/InteractiveContentWrapper';

const DefaultSortBy = 1;

const PurchaseReceiptOverviewPage = () => {
    const { postPurchaseReceiptOverviewApi, getPurchaseReceiptOptionsApi } = usePurchaseReceiptApi();

    const headerRow = useMemo(
        () => [
            {
                cellKey: 'purchaseReceiptID',
                headerLabel: '進貨單號',
                formatCell: (purchaseReceiptID, { purchaseReceiptNo }) => {
                    return (
                        <Button component={NavLink} to={PURCHASE_RECEIPT_ORDER_PATH(purchaseReceiptID)}>
                            {purchaseReceiptNo}
                        </Button>
                    );
                },
            },
            {
                cellKey: 'purchaseReceiptStatusText',
                headerLabel: '狀態',
                align: 'center',
                formatCell: (purchaseReceiptStatusText, { purchaseReceiptStatus }) => {
                    return <Chip label={purchaseReceiptStatusText} color={purchaseReceiptOrderStatusColorMap.get(purchaseReceiptStatus)} />;
                },
            },
            {
                cellKey: 'deliveryDate ',
                align: 'center',
                headerLabel: '預計到貨日',
                formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
            },
            {
                cellKey: 'inspectionEndDate ',
                align: 'center',
                headerLabel: <InteractiveContentWrapper label={'點收截止日'} tip={'請於截止日完成點收，避免進貨單被凍結。'} />,
                formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
            },
            {
                cellKey: 'purchaseDate',
                align: 'center',
                headerLabel: '建單時間',
                formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
            },
            {
                cellKey: 'sourceTypeText',
                align: 'center',
                headerLabel: '來源',
            },
            {
                cellKey: 'notes',
                headerLabel: '備註',
                formatCell: (cellValue) => {
                    return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
                },
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const { options, overviewData, searchParams, updateQuery, handleClearSearch } = useOverview({
        getOptionsApi: getPurchaseReceiptOptionsApi,
        getOverviewApi: postPurchaseReceiptOverviewApi,
        defaultQueryParams: useMemo(
            () => {
                const today = getToday();
                return {
                    ...initBookmark(DefaultSortBy),
                    startDate: getCurrentStartDate(getSubMonths(today, 3)),
                    endDate: getCurrentEndDay(today),
                    purchaseReceiptStatus: 99,
                    sourceType: 99,
                };
            },
            // eslint-disable-next-line
            [],
        ),
    });

    const { pageIndex = 1, startDate, endDate, purchaseReceiptStatus, sourceType, sortBy } = searchParams || {};
    const { purchaseReceiptStatusOption = [], purchaseTypeOption = [], purchaseReceiptSortByOption = [] } = options || {};

    const dateRef = React.useRef(null);
    const purchaseReceiptStatusRef = React.useRef(null);
    const sourceTypeRef = React.useRef(null);

    const getParamsData = (sortBy) => {
        let result = {
            ...initBookmark(sortBy),
        };

        if (dateRef.current && dateRef.current.getResult) {
            const { startDate, endDate } = dateRef.current.getResult();
            result.startDate = getCurrentStartDate(startDate);
            result.endDate = getCurrentEndDay(endDate);
        }

        if (purchaseReceiptStatusRef.current && purchaseReceiptStatusRef.current.getResult) {
            result.purchaseReceiptStatus = purchaseReceiptStatusRef.current.getResult();
        }

        if (sourceTypeRef.current && sourceTypeRef.current.getResult) {
            result.sourceType = sourceTypeRef.current.getResult();
        }

        return result;
    };

    const handleSubmit = () => {
        const paramsData = getParamsData();
        const isError = refIsRequiredError(dateRef, purchaseReceiptStatusRef, sourceTypeRef);

        if (!isError) {
            updateQuery(paramsData);
        }
    };

    const handlePageChange = (sortBy) => (e, page) => {
        const params = getParamsData(sortBy);
        updateQuery({
            ...params,
            pageIndex: page,
        });
    };

    const handleSortChange = (value) => {
        const params = getParamsData(value);
        updateQuery(params);
    };

    return (
        <PageLayout>
            <PageLayout.Header title={'進貨單總覽'} />
            <OverviewList
                headerRow={headerRow}
                list={overviewData?.list}
                totalPage={overviewData?.totalPage}
                pageIndex={pageIndex}
                onChangePage={handlePageChange(sortBy)}
                isPagination
            >
                <OverviewList.SearchBar className={'mb-3'} onSubmit={handleSubmit} onClearSearch={handleClearSearch}>
                    <Grid container spacing={2}>
                        <Grid xs={6} item>
                            <DateIntervalField
                                label={'建單區間'}
                                key={displayDateText(startDate, endDate)}
                                ref={dateRef}
                                startDateProps={{
                                    defaultValue: startDate,
                                }}
                                endDateProps={{
                                    defaultValue: endDate,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={3} item>
                            <SelectField
                                label={'狀態'}
                                key={purchaseReceiptStatus}
                                ref={purchaseReceiptStatusRef}
                                defaultValue={purchaseReceiptStatus}
                                fullWidth
                            >
                                {Array.isArray(purchaseReceiptStatusOption) &&
                                    purchaseReceiptStatusOption.map(({ text, value, disabled }) => {
                                        return (
                                            <MenuItem key={value} value={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        );
                                    })}
                            </SelectField>
                        </Grid>
                        <Grid xs={3} item>
                            <SelectField label={'來源'} key={sourceType} ref={sourceTypeRef} defaultValue={sourceType} fullWidth>
                                {Array.isArray(purchaseTypeOption) &&
                                    purchaseTypeOption.map(({ text, value, disabled }) => {
                                        return (
                                            <MenuItem key={value} value={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        );
                                    })}
                            </SelectField>
                        </Grid>
                    </Grid>
                </OverviewList.SearchBar>
                <Stack className={'mb-1'} alignItems={'center'} justifyContent={'end'}>
                    <PageSortAndTotalBox
                        className={'mb-1'}
                        pageIndex={pageIndex}
                        pageSize={overviewData?.pageSize}
                        totalCount={overviewData?.totalCount}
                        sortDefaultValue={sortBy}
                        sortOptions={purchaseReceiptSortByOption}
                        sortOnChange={handleSortChange}
                        isCurrentPageLastIndex
                        isSort
                    />
                </Stack>
            </OverviewList>
        </PageLayout>
    );
};

export default PurchaseReceiptOverviewPage;
