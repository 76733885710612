import React, { useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import TextField from './TextField';
import { Check as CheckIcon, Minus as MinusIcon } from '../icons/SvgIcon/';

const SecurityTextField = React.forwardRef((props, ref) => {
    const inputRef = React.useRef('');
    const [validLetterType, setValidLetterType] = useState({
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        hasSymbol: false,
        hasLength: false,
        hasError: false,
    });
    const { includeLetterType = ['uppercase', 'lowercase', 'number', 'symbol', 'length'], ...others } = props;

    const { hasUppercase, hasLowercase, hasNumber, hasSymbol, hasLength, hasError } = validLetterType;

    const includeUppercase = includeLetterType.indexOf('uppercase') >= 0;
    const includeLowercase = includeLetterType.indexOf('lowercase') >= 0;
    const includeNumber = includeLetterType.indexOf('number') >= 0;
    const includeSymbol = includeLetterType.indexOf('symbol') >= 0;
    const includeLength = includeLetterType.indexOf('length') >= 0;

    const handleKeyUp = () => {
        const pwd = inputRef.current.getResult();

        if (_.isEmpty(pwd)) {
            setValidLetterType({
                hasUppercase: false,
                hasLowercase: false,
                hasNumber: false,
                hasSymbol: false,
                hasLength: false,
                hasError: false,
            });
        } else {
            let letterType = {
                hasUppercase: /[A-Z]/.test(pwd),
                hasLowercase: /[a-z]/.test(pwd),
                hasNumber: /\d/.test(pwd),
                hasSymbol: /[\W_]/.test(pwd),
                hasLength: pwd.length >= 8,
                hasError: false,
            };

            const checks = [
                includeUppercase && !letterType.hasUppercase,
                includeLowercase && !letterType.hasLowercase,
                includeNumber && !letterType.hasNumber,
                includeSymbol && !letterType.hasSymbol,
                includeLength && !letterType.hasLength,
            ];

            letterType.hasError = checks.some((condition) => condition);
            setValidLetterType(letterType);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                const { hasError } = validLetterType;
                return inputRef.current.isError() || hasError;
            },
            setError: (chk, text) => {
                inputRef.current.setError(chk, text);
            },
            getResult: () => {
                return inputRef.current.getResult();
            },
            getName: () => inputRef.current.getName,
            getId: () => inputRef.current.getId,
            node: () => inputRef.current,
        }),
        // eslint-disable-next-line
        [validLetterType]
    );

    return (
        <>
            <TextField ref={inputRef} type={'password'} {...others} onKeyUp={handleKeyUp} isError={hasError} helperText={''} />
            <div className="formhelpertext formhelpertext-security">
                {includeUppercase && (
                    <span className={clsx({ 'formhelpertext-security-active': hasUppercase })}>
                        {hasUppercase ? <CheckIcon fontSize={'small'} /> : <MinusIcon fontSize={'small'} />}
                        <span>包含大寫</span>
                    </span>
                )}

                {includeLowercase && (
                    <span className={clsx({ 'formhelpertext-security-active': hasLowercase })}>
                        {hasLowercase ? <CheckIcon fontSize={'small'} /> : <MinusIcon fontSize={'small'} />}
                        <span>包含小寫</span>
                    </span>
                )}

                {includeNumber && (
                    <span className={clsx({ 'formhelpertext-security-active': hasNumber })}>
                        {hasNumber ? <CheckIcon fontSize={'small'} /> : <MinusIcon fontSize={'small'} />}
                        <span>包含數字</span>
                    </span>
                )}

                {includeSymbol && (
                    <span className={clsx({ 'formhelpertext-security-active': hasSymbol })}>
                        {hasSymbol ? <CheckIcon fontSize={'small'} /> : <MinusIcon fontSize={'small'} />}
                        <span>包含符號</span>
                    </span>
                )}

                {includeLength && (
                    <span className={clsx({ 'formhelpertext-security-active': hasLength })}>
                        {hasLength ? <CheckIcon fontSize={'small'} /> : <MinusIcon fontSize={'small'} />}
                        <span>最少8碼</span>
                    </span>
                )}
            </div>
        </>
    );
});

export default SecurityTextField;
