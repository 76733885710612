import * as Loading from './loading';
import * as Menus from './menus';
import * as Version from './version';
import * as MessageDialog from './messageDialog';
import * as PdfDialog from './pdfDialog';
import * as HistoryLock from './historyLock';
import * as InactivityTimer from './inactivityTimer';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    ...Loading,
    ...Menus,
    ...Version,
    ...MessageDialog,
    ...PdfDialog,
    ...PdfDialog,
    ...HistoryLock,
    ...InactivityTimer,
};
