import React, { useMemo, useRef, useCallback, useEffect } from 'react';
import _ from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import { MorePageBox, useLabelCheckBox, LabelHeaderCheckBox } from '@icoach/components/';
import MemberDialogBox from '@icoach/members/dialog/MemberDialogBox';
import { Stack, Tooltip, IconButton, Box } from '@common/components/';
import { PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { INBODY_INTERVIEW_MEASURE_PATH } from '@icoach/router/routerPath';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';
import usePrivacyToggle, { securityMaskFilterTypeMap } from '@util/hook/usePrivacyToggle';
import { PrivacyDataToggleButton } from '@icoach/components';
import { getFilterInBodyScannerLabel } from '@icoach/inbody/overview/FilterInBodyScannerTemplate';
import { getFilterUnInBodyScannerLabel } from '@icoach/inbody/overview/FilterUnInBodyScannerTemplate';
import { getFilterMemberComparisonLabel } from '@icoach/inbody/overview/FilterInMemberComparisonTemplate';
import { isEmpty } from '@util/utils';

const InBodyOverviewFilterTable = React.memo((props) => {
    const {
        sourceData,
        rowsLabelType,
        sortOption,
        bookmark,
        setBookmarkNum,
        setBookmarkSortBy,
        setContactState,
        reSetCheckedItemsRef,
        doKeepState,
        doCheckActiveState,
    } = useInBodyContext();
    const { sortBy } = bookmark;
    const { list: rowsData = [], pageIndex: page = 1, totalPage: count, pageSize, totalCount } = sourceData;
    const { checkBoxsRef, headerCheckBoxRef, onHeaderChange, onCellChange, getCheckedItems, getCheckedSourceData, doSaveCheckedItem, doClearCheckedItems } =
        useLabelCheckBox();
    const { isRevealed, toggle, hide } = usePrivacyToggle();
    const memberDialogBoxRef = useRef(null);
    const scrollerArrowRef = useRef(null);
    reSetCheckedItemsRef(doClearCheckedItems);
    const handlePageChange = (e, num) => {
        doSaveCheckedItem();
        setBookmarkNum(num);
    };

    const handleSendSMSClick = () => {
        let params = getCheckedSourceData();
        if (params.length === 0) return;
        params = params.map((target) => ({ id: target.memberID, type: 2, name: target.nickName || target.memberName }));
        memberDialogBoxRef.current.openSmsDialog(params);
    };

    const headerRowMap = useMemo(
        () => ({
            [securityMaskFilterTypeMap.ClubMemberNonMeasure]: getFilterInBodyScannerLabel,
            [securityMaskFilterTypeMap.ClubMemberMeasured]: getFilterUnInBodyScannerLabel,
            [securityMaskFilterTypeMap.ClubMemberMeasureFilter]: getFilterMemberComparisonLabel,
        }),
        [],
    );

    const headerRow = useMemo(
        () => {
            const handler = headerRowMap[rowsLabelType];
            return handler ? handler(isRevealed) : [];
        },
        // eslint-disable-next-line
        [rowsLabelType, isRevealed, headerRowMap],
    );

    const handleToggle = useCallback(
        () => {
            if (!isEmpty(rowsLabelType)) {
                const apiParams = {
                    filterType: rowsLabelType,
                    totalCount: rowsData.length,
                };
                toggle(apiParams);
            }
        },
        // eslint-disable-next-line
        [toggle, rowsData, rowsLabelType],
    );

    const handleKeepState = (row) => {
        return doKeepState && doKeepState(row.memberID);
    };

    const handelCheckActiveState = (row) => {
        return doCheckActiveState && doCheckActiveState(row.memberID);
    };

    useEffect(
        () => {
            if (isRevealed) {
                hide(); // 切換會員名單時都要隱藏隱私資料
            }
        },
        // eslint-disable-next-line
        [rowsLabelType],
    );

    return (
        <React.Fragment>
            {headerRow.length !== 0 && (
                <React.Fragment>
                    <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                        {!_.isEmpty(rowsData) && (
                            <Stack className="pl-4 pb-1">
                                <LabelHeaderCheckBox ref={headerCheckBoxRef} onChange={onHeaderChange} />
                                <Stack>
                                    <Tooltip title="群發送簡訊">
                                        <IconButton onClick={handleSendSMSClick}>
                                            <PaperPlaneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                                    <PrivacyDataToggleButton className={'has-divider'} isRevealed={isRevealed} onToggle={handleToggle} />
                                </Tooltip>
                            </Stack>
                        )}
                        <ListInfo
                            className={'ml-auto'}
                            pageSize={pageSize}
                            pageIndex={page}
                            totalCount={totalCount}
                            sortDefaultValue={sortBy}
                            sortOptions={sortOption}
                            sortOnChange={setBookmarkSortBy}
                            isCurrentPageLastIndex
                            isSort={!isEmpty(sortOption)}
                        />
                    </Stack>
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            Pagination={MorePageBox}
                            tableContainerRef={scrollerArrowRef}
                            cellWidth={12}
                            count={count}
                            page={page}
                            onChange={handlePageChange}
                            isPagination
                            isFixed
                        >
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody
                                headerRow={headerRow}
                                dialogRef={memberDialogBoxRef}
                                rowsData={rowsData}
                                routerPath={INBODY_INTERVIEW_MEASURE_PATH}
                                checkBoxsRef={checkBoxsRef}
                                onCheckBoxChange={onCellChange}
                                getCheckedItems={getCheckedItems}
                                rowOnKeepState={handleKeepState}
                                rowOnCheckActiveState={handelCheckActiveState}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                    <MemberDialogBox ref={memberDialogBoxRef} setContactState={setContactState} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default InBodyOverviewFilterTable;
