import React, { useState, useCallback } from 'react';
import { Tabs, Tab } from '@common/components/';
import PropTypes from 'prop-types';

const CommonTabs = ({ tabs, activeTab: controlledActiveTab, onTabChange: controlledOnTabChange, ...rest }) => {
    const activeTab = controlledActiveTab || tabs[0]?.value || '';
    const [internalActiveTab, setInternalActiveTab] = useState(activeTab);
    const isControlled = controlledActiveTab !== undefined && controlledOnTabChange !== undefined;
    // const activeTab = isControlled ? controlledActiveTab : internalActiveTab;

    const handleTabChange = useCallback(
        (event, newValue) => {
            if (isControlled) {
                controlledOnTabChange(event, newValue);
            } else {
                setInternalActiveTab(newValue);
            }
        },
        [isControlled, controlledOnTabChange],
    );

    return (
        <Tabs value={internalActiveTab} onChange={handleTabChange} {...rest}>
            {tabs.map(({ label, value, icon: IconComponent, component: Component, to }) => (
                <Tab
                    key={value}
                    className={'py-3'}
                    label={
                        <>
                            {IconComponent && <IconComponent className="mr-2" />}
                            {label}
                        </>
                    }
                    value={value}
                    component={Component}
                    to={to}
                />
            ))}
        </Tabs>
    );
};

CommonTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
            icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
            component: PropTypes.elementType,
            to: PropTypes.string,
        }),
    ).isRequired,
    activeTab: PropTypes.string,
    onTabChange: PropTypes.func,
};

export default CommonTabs;
