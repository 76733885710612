import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StepProvider } from './StepContext';
import { useStepperContext } from '../stepper/StepperContext';
import clsx from 'clsx';

const Step = forwardRef((props, ref) => {
    const {
        active: activeProp,
        children,
        className,
        component = 'div',
        completed: completedProp,
        disabled: disabledProp,
        expanded = false,
        index,
        last,
        ...rest
    } = props;

    const { activeStep, connector, alternativeLabel, orientation, nonLinear } = useStepperContext();

    let [active = false, completed = false, disabled = false] = [activeProp, completedProp, disabledProp];

    if (activeStep === index) {
        active = activeProp !== undefined ? activeProp : true;
    } else if (!nonLinear && activeStep > index) {
        completed = completedProp !== undefined ? completedProp : true;
    } else if (!nonLinear && activeStep < index) {
        disabled = disabledProp !== undefined ? disabledProp : true;
    }

    const contextValue = React.useMemo(
        () => ({ index, last, expanded, icon: index + 1, active, completed, disabled }),
        [index, last, expanded, active, completed, disabled],
    );

    const rootClasses = clsx(
        'step',
        {
            'step--horizontal': orientation === 'horizontal',
            'step--alternative-label': Boolean(alternativeLabel),
        },
        className,
    );

    const Component = component;
    const newChildren = (
        <Component className={rootClasses} ref={ref} {...rest}>
            {connector && alternativeLabel && index !== 0 ? connector : null}
            {children}
        </Component>
    );

    return (
        <StepProvider value={contextValue}>
            {connector && !alternativeLabel && index !== 0 ? (
                <React.Fragment>
                    {connector}
                    {newChildren}
                </React.Fragment>
            ) : (
                newChildren
            )}
        </StepProvider>
    );
});

Step.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    component: PropTypes.elementType,
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    index: PropTypes.number,
    last: PropTypes.bool,
    sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
};

export default Step;
