import React, { useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, Button, Form, Stack, Typography } from '../../../../components';
import useOrderBatchPaymentApi from '../../../../apis/useOrderBatchPaymentApi';
import useExportActionDialog from "@util/hook/useExportActionDialog";

const ExportCsvController = React.forwardRef((props, ref) => {
    const { className, billingCategory, assignmentMonth, tableRef, handleCancel } = props;
    const { postOrderBatchPaymentExportOrderApi } = useOrderBatchPaymentApi();
    const [count, setCount] = useState(0);
    const { showExportConfirmDialog } = useExportActionDialog({ checkExportPermission: false });

    const getParams = () => {
        let result = {
            assignmentMonth,
            billingCategory,
        };

        if (tableRef.current && tableRef.current.getCurrentData) {
            const currentData = tableRef.current.getCurrentData() || [];
            result = Object.assign(result, { orderIDs: currentData.map((item) => item.orderID) });
        }

        return result;
    };

    const OrderBatchPaymentExportOrderApi = async (params) => {
        await showExportConfirmDialog(params, (nextParams) => {
            let res = postOrderBatchPaymentExportOrderApi(nextParams);

            if (res) handleCancel();
        });


    };

    const handleSubmit = () => {
        const params = getParams();
        if (params.orderIDs.length > 0) {
            OrderBatchPaymentExportOrderApi(params);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            setCount,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={clsx('mb-2 bulk-controller-box', className)}>
            <Form onSubmit={handleSubmit}>
                <Grid spacing={3} container>
                    <Grid xs={8} item>
                        <Typography className={'font-weight-bold'} variant={'body1'}>
                            請選擇要匯出的名單
                        </Typography>
                        <Typography variant={'body1'}>
                            匯出筆數：<strong className={'h6 text-primary'}>{count}</strong>
                        </Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <Stack className={'h-100'} direction={'column'} justifyContent={'end'}>
                            <Box className={'btn-group'}>
                                <Button color={'grey'} variant={'outlined'} onClick={handleCancel}>
                                    取消
                                </Button>
                                <Button type={'submit'} variant={'outlined'}>
                                    進行批次匯出
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Box>
    );
});

export default ExportCsvController;
