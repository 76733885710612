import React, { useEffect, useState } from 'react';
import { OrderAllowanceRecordTable } from './tables';
import useOrderReturnApi from '@apis/useOrderReturnApi';
import PageLayout from '@icoach/components/layout/PageLayout';

const AccountBookOrderAllowanceRecordPage = (props) => {
    const orderID = props.match.params.orderID;
    const { getOrderAbandonAllowanceApi } = useOrderReturnApi();
    const [data, setData] = useState([]);

    const doOrderAbandonAllowanceApi = async (orderID) => {
        let res = await getOrderAbandonAllowanceApi(orderID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (orderID) {
            doOrderAbandonAllowanceApi(orderID);
        }
        // eslint-disable-next-line
    }, [orderID]);

    return (
        <PageLayout>
            <PageLayout.Header title={'訂單折讓歷史'} />
            <OrderAllowanceRecordTable rowsData={data} refresh={() => doOrderAbandonAllowanceApi(orderID)} />
        </PageLayout>
    );
};

export default AccountBookOrderAllowanceRecordPage;
