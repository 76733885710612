import React from 'react';
import { Typography } from '@common/components/';

const ForcePasswordResetTextBox = React.forwardRef((props, ref) => {
    return (
        <React.Fragment>
            <Typography component="div" variant="h3" className="text-center my-2" color="error">
                密碼已超過三個月未更新，為保障帳戶安全，
            </Typography>
            <Typography component="div" variant="h3" className="text-center my-2"  color="error">
                請立即修改密碼，否則將無法進行下一步操作。
            </Typography>
        </React.Fragment>
    );
});

export default ForcePasswordResetTextBox;
