import React, { useEffect, useRef, useState } from 'react';
import { useRentCabinetContext } from '@icoach/rentCabinet/components/RentCabinetContext';
import { AutocompleteField, Button, Card, DateIntervalField, Form, Grid, MenuItem, SelectField, TextField } from '@common/components/';
import { initBookmark } from '@icoach/rentCabinet/staticData';
import useCabinetRentalApi from '@apis/useCabinetRentalApi';
import usePeopleApi, { peopleType } from '@apis/usePeopleApi';
import { objectToQueryString, paramsToObject, refIsRequiredError } from '@util/utils';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { RENT_CABINET_DOCUMENT_URL } from '@icoach/router/MembersRouter';
import { useHistory } from 'react-router-dom';
import { getCurrentEndDay, getCurrentStartDate, getSubMonths, parseDate } from '@util/moment';

const RentCabinetOverviewSearchBar = React.forwardRef((props, ref) => {
    const { className } = props;
    const history = useHistory();
    const { getRentCabinetOptionsApi, postRentCabinetListApi } = useCabinetRentalApi();
    const { setRefresh, setTableData, bookmark, setBookmarkNum, reSetCheckedItems } = useRentCabinetContext();
    const { people: memberOptions = [] } = usePeopleApi(peopleType.all);
    const [paramsData, setParamsData] = useState({});
    const { memberID, startDate, endDate, cabinetName, paymentCycle = '99', cabinetRentalStatus = '99' } = paramsData;
    const [resource, setResource] = useState({});
    const { cabinetRentalStatusOption = [], cabinetPaymentCycleOption = [] } = resource;
    const cabinetStatusRef = useRef(null);
    const cabinetNameRef = useRef(null);
    const memberRef = useRef(null);
    const dateRef = useRef(null);
    const paymentCycleRef = useRef(null);
    const preload = useRef(true);

    const updateUrlWithSearchQuery = (params) => {
        let url = RENT_CABINET_DOCUMENT_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOptionsApi = async () => {
        let res = await getRentCabinetOptionsApi();
        if (res) {
            setResource(res);
            const { location } = history;
            const search = location.search;
            if (search) {
                const params = paramsToObject(search);
                if (!isEmpty(params)) {
                    setParamsData(params);
                }
                preload.current = false;
                getOverviewApi(params);
            }
            
        }
    };

    const getParams = (pageInfo) => {
        let result = {};

        if (!isEmpty(pageInfo)) {
            Object.assign(result, pageInfo);
        }
        if (cabinetStatusRef.current && cabinetStatusRef.current.getResult) {
            Object.assign(result, {
                cabinetRentalStatus: cabinetStatusRef.current.getResult(),
            });
        }
        if (cabinetNameRef.current && cabinetNameRef.current.getResult) {
            Object.assign(result, {
                cabinetName: cabinetNameRef.current.getResult(),
            });
        }
        if (memberRef.current && memberRef.current.getResult) {
            const memberData = memberRef.current.getResult();
            Object.assign(result, { memberID: memberData?.id || '' });
        }
        if (dateRef.current && dateRef.current.getResult) {
            let { startDate, endDate } = dateRef.current.getResult();
            if (startDate && endDate) Object.assign(result, { startDate: getCurrentStartDate(startDate), endDate: getCurrentEndDay(endDate) });
        }
        if (paymentCycleRef.current && paymentCycleRef.current.getResult) {
            Object.assign(result, { paymentCycle: paymentCycleRef.current.getResult() });
        }

        return result;
    };

    const getOverviewApi = async (params) => {
        let resp = await postRentCabinetListApi(params);
        if (resp) {
            let { list, ...other } = resp;
            if (String(params.pageIndex) === '1') {
                setTableData({ list, ...other });
            } else {
                setTableData((prev) => {
                    const prevList = prev.list;
                    let targetList = list;

                    if (Array.isArray(prevList)) {
                        targetList = prevList.concat(targetList);
                    }

                    return {
                        list: targetList,
                        ...other,
                    };
                });
            }
        }
        updateUrlWithSearchQuery(params);
        preload.current = true;
    };

    const handleSearchSubmit = () => {
        let isError = refIsRequiredError(cabinetStatusRef, cabinetNameRef, memberRef, dateRef, paymentCycleRef);
        if (!isError) {
            let params = getParams(initBookmark());
            preload.current = false;
            reSetCheckedItems();
            getOverviewApi(params);
            setBookmarkNum(1);
        }
    };

    useEffect(
        () => {
            if (!preload.current) return;
            if (String(bookmark.pageIndex) === '1') return;
            const params = getParams(bookmark);
            preload.current = false;
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(() => {
        getOptionsApi();
        setRefresh(handleSearchSubmit);
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar-light px-4 py-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchSubmit}>
                    <Grid spacing={3} container>
                        <Grid xs={4} item>
                            <AutocompleteField
                                key={memberID}
                                className={'flex-auto'}
                                ref={memberRef}
                                label={'承租會員'}
                                defaultValue={memberID}
                                options={memberOptions}
                                optionKeys={['name', 'nickName', 'mobile', 'no']}
                                optionMaskKeys={['maskName', 'nickName', 'maskMobile', 'no']}
                                primaryKey={'id'}
                                inputProps={{
                                    placeholder: '請輸入會員姓名或手機號碼或會員編號',
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={8} item>
                            <DateIntervalField
                                key={`${startDate}_${endDate}`}
                                ref={dateRef}
                                label={'租借期間'}
                                startDateProps={{
                                    defaultValue: startDate || getSubMonths(parseDate(), '6'),
                                }}
                                endDateProps={{
                                    defaultValue: parseDate(endDate),
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <TextField
                                key={cabinetName}
                                label={'置物櫃名稱'}
                                ref={cabinetNameRef}
                                defaultValue={cabinetName}
                                className={'flex-auto'}
                                InputProps={{ placeholder: '請輸入置物櫃名稱' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={paymentCycle} ref={paymentCycleRef} label={'繳費週期'} defaultValue={paymentCycle} fullWidth>
                                {Array.isArray(cabinetPaymentCycleOption) &&
                                    cabinetPaymentCycleOption.map(({ text, value }) => (
                                        <MenuItem value={value} key={value}>
                                            {text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField
                                key={cabinetRentalStatus}
                                ref={cabinetStatusRef}
                                label={'租約狀態'}
                                defaultValue={cabinetRentalStatus}
                                fullWidth
                                required
                            >
                                {Array.isArray(cabinetRentalStatusOption) &&
                                    cabinetRentalStatusOption.map(({ text, value }) => (
                                        <MenuItem value={value} key={value}>
                                            {text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid className={'text-right'} xs={12} item>
                            <Button className={'mb-1 white-space-nowrap'} type={'submit'} variant={'contained'}>
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
});

export default RentCabinetOverviewSearchBar;
