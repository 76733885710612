import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import usePrivacyToggle from '@util/hook/usePrivacyToggle';
import PrivacyDataToggleButton from '@icoach/components/PrivacyToggleButton';

const PrivacyDataToggle = ({ className, fullData, maskedData, isRevealed: controlledIsRevealed, initialIsRevealed = false, onToggle, showIcon = false }) => {
    const isControlled = controlledIsRevealed !== undefined;
    const { isRevealed: uncontrolledIsRevealed, toggle } = usePrivacyToggle(initialIsRevealed);
    const isRevealed = isControlled ? controlledIsRevealed : uncontrolledIsRevealed;

    const handleToggle = () => {
        if (!showIcon) return;

        if (isControlled) {
            if (typeof onToggle === 'function') {
                onToggle(!controlledIsRevealed);
            }
        } else {
            toggle({});
        }
    };

    return (
        <span className={clsx(className, 'privacy-data')}>
            <span className="privacy-data__content">{isRevealed ? fullData : maskedData}</span>
            {showIcon && (
                <PrivacyDataToggleButton className="privacy-data__icon" isRevealed={isRevealed} onToggle={handleToggle} initialIsRevealed={initialIsRevealed} />
            )}
        </span>
    );
};

PrivacyDataToggle.propTypes = {
    className: PropTypes.string,
    fullData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maskedData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRevealed: PropTypes.bool,
    onToggle: PropTypes.func,
    showIcon: PropTypes.bool,
    logApi: PropTypes.func,
};

export default PrivacyDataToggle;
