import React, { useMemo } from 'react';
import { Chip, Tooltip } from '@common/components/';
import usePrivacyToggle, { securityMaskFilterTypeMap } from '@util/hook/usePrivacyToggle';
import { PrivacyDataToggle, PrivacyDataToggleButton } from '@icoach/components';
import Tablenization from '@icoach/components/Tablenization';

const setSendType = (sendType, sendText) => {
    let targetMap = {
        text: '失敗',
        color: 'error',
    };
    if (sendType < 0) {
        targetMap = {
            text: '預約',
            color: 'info',
        };
    } else if ([17, 18].includes(sendType)) {
        targetMap = {
            text: '成功',
            color: 'success',
        };
    } else if ([16].includes(sendType)) {
        targetMap = {
            text: sendText,
            color: 'info',
        };
    }
    return targetMap;
};

const SmsIdOverviewTable = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    const { isRevealed, toggle } = usePrivacyToggle();
    const rowsLabel = useMemo(
        () => [
            {
                cellKey: 'displayName',
                headerLabel: '姓名',
                width: 8,
                formatCell: (displayName, { maskDisplayName }) => {
                    return <PrivacyDataToggle fullData={displayName} maskedData={maskDisplayName} isRevealed={isRevealed} />;
                },
            },
            {
                cellKey: 'phone',
                headerLabel: '手機',
                width: 8,
                formatCell: (phone, { maskCellPhone }) => {
                    return <PrivacyDataToggle fullData={phone} maskedData={maskCellPhone} isRevealed={isRevealed} />;
                },
            },
            {
                cellKey: 'referenceTypeText',
                headerLabel: '對象',
                width: 8,
            },
            {
                cellKey: 'sendStatus',
                headerLabel: '狀態',
                align: 'center',
                width: 8,
                formatCell: (cellValue, { sendStatusText }) => {
                    const typeMap = setSendType(cellValue * 1, sendStatusText);
                    return <Chip color={typeMap.color} label={typeMap.text} />;
                },
            },
            {
                cellKey: 'sendStatusText',
                headerLabel: '備註',
                width: 14,
            },
        ],
        // eslint-disable-next-line
        [isRevealed],
    );

    // :TODO: 缺少 filterType
    const handleToggle = () => {
        const apiParams = {
            filterType: securityMaskFilterTypeMap.ClubSmsOverview,
            totalCount: sourceData.totalCount,
        };
        toggle(apiParams);
    };

    return (
        <React.Fragment>
            <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                <PrivacyDataToggleButton isRevealed={isRevealed} onToggle={handleToggle} />
            </Tooltip>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={rowsLabel} />
                <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={sourceData} />
            </Tablenization>
        </React.Fragment>
    );
});

export default SmsIdOverviewTable;
