import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MembershipTransferInHistoryTable } from '../../table';
import { Button, Card, Typography } from '../../../../components/';
import useDocMembershipTransferInApi from '../../../../apis/useDocMembershipTransferInApi';
import { useHistory } from 'react-router';

const MembershipTransferInHistory = (props) => {
    const { onBlankContractClick, isReadOnly, searchRef } = props;
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const { postDocTransferInContractHistoryApi } = useDocMembershipTransferInApi();
    const [sourceData, setSourceData] = useState([]);

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        if (isReadOnly) return;
        onBlankContractClick && onBlankContractClick();
    };

    // 讀取該對象的歷史紀錄
    const getInitPeopleRecordOverview = async () => {
        const parmas = { type: peopleTypeProps, id: referenceIDProps };
        const resp = await postDocTransferInContractHistoryApi(parmas);
        if (resp) setSourceData(resp);
    };

    useEffect(
        () => {
            getInitPeopleRecordOverview();
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            // 重刷頁面才會用 REPLACE
            if (history.action === 'REPLACE') {
                getInitPeopleRecordOverview();
            }
        },
        // eslint-disable-next-line
        [history.location],
    );

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    {!isReadOnly && (
                        <div className="btn-group">
                            <Button name="create" variant="outlined" color="secondary" onClick={handleCreateButtonClick}>
                                建立新表單
                            </Button>
                        </div>
                    )}
                </header>
                <MembershipTransferInHistoryTable
                    className={'theme-gray'}
                    sourceData={sourceData}
                    searchRef={searchRef}
                    onReload={getInitPeopleRecordOverview}
                />
            </Card.CardContent>
        </Card>
    );
};

export default MembershipTransferInHistory;
