import React, { createContext, useContext } from 'react';

const StepperContext = createContext(undefined);

export const StepperProvider = ({ children, value }) => {
    return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};

export const useStepperContext = () => {
    const context = useContext(StepperContext);
    if (context === undefined) {
        throw new Error('useStepperContext 必須在 StepperProvider 中使用');
    }
    return context;
};
