import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ErrorCircle as ErrorCircleIcon, CheckCircle as CheckCircleIcon } from '@common/SvgIcon/';
import SvgIcon from '../icons/SvgIcon';

const StepIcon = forwardRef((props, ref) => {
    const { className: classNameProp, active = false, completed = false, error = false, icon = 'icon', ...rest } = props;

    const rootClasses = clsx(
        'step-icon',
        {
            'step-icon--completed': completed,
            'step-icon--active': active,
            'step-icon--error': error,
        },
        classNameProp,
    );

    if (typeof icon === 'number' || typeof icon === 'string') {
        if (error) return <ErrorCircleIcon className={rootClasses} {...rest} />;

        if (completed) return <CheckCircleIcon className={rootClasses} {...rest} />;

        return (
            <SvgIcon className={rootClasses} ref={ref} {...rest}>
                <circle cx={12} cy={12} r={12} />
                <text x={12} y={12} textAnchor={'middle'} dominantBaseline={'central'} className={'step-icon__text'}>
                    {icon}
                </text>
            </SvgIcon>
        );
    }

    return icon;
});

StepIcon.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    completed: PropTypes.bool,
    error: PropTypes.bool,
    icon: PropTypes.node,
    sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
};

export default StepIcon;
