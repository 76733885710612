import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@common/components/';
import clsx from 'clsx';

const PurchaseInspectionQuantityInput = forwardRef(({ className, variantID, purchaseQuantity = 1, ...rest }, ref) => {
    const inputRef = useRef(null);
    const [inspectionQuantity, setInspectionQuantity] = useState('');

    const handleInputChange = (e) => {
        setInspectionQuantity(e.target.value);
    };

    const handleQuantityChange = (e) => {
        setInspectionQuantity(purchaseQuantity);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    variantID, // 後端可以辨識哪個商品
                    inspectionQuantity,
                };
            },
            isError: () => inputRef.current && inputRef.current.isError(),
        }),
        // eslint-disable-next-line
        [inspectionQuantity],
    );

    return (
        <Stack className={clsx('purchase-inspection-quantity', className)} justifyContent={'end'} alignItems={'start'} spacing={2} {...rest}>
            <TextField
                ref={inputRef}
                className={'purchase-inspection-quantity__input'}
                value={inspectionQuantity}
                onChange={handleInputChange}
                inputProps={{
                    max: purchaseQuantity,
                    min: 0,
                }}
                maskType={'MONEY'}
                required
            />
            <Typography className={'font-weight-bold purchase-inspection-quantity__purchase-quantity'}>{`/ ${purchaseQuantity}`}</Typography>
            <Button className={'font-weight-bold purchase-inspection-quantity__button'} variant={'outlined'} onClick={handleQuantityChange}>
                全到
            </Button>
        </Stack>
    );
});

export default PurchaseInspectionQuantityInput;
