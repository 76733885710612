import React, { useEffect, useRef, useState } from 'react';
import { Box, Card } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import useClubPermissionApi from '@apis/useClubPermissionApi';


const RolePermissionTable = (props) => {
    const { rowsLabel } = props;
    const scrollerArrowRef = useRef(null);
    const { getClubPermissionsOverviewApi } = useClubPermissionApi();
    const [sourceData, setSourceData] = useState(null);
    const [isMounted, setMounted] = useState(false);

    const initOverviewData = () => {
        getRolePermissionApi();
    };

    const getRolePermissionApi = async () => {
        const resp = await getClubPermissionsOverviewApi();
        if (resp) {
            setSourceData(resp);
        }
        setMounted(true);
    };

    useEffect(
        () => {
            initOverviewData();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            {isMounted && (
                <Card className="mt-4">
                    <Card.CardContent className="p-3">
                        <Box className={'scroll-arrow-container'}>
                            <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} isPagination>
                                <Tablenization.TableRowHeader headerRow={rowsLabel} />
                                <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={sourceData} />
                            </Tablenization>
                            <ScrollerArrowButton parentRef={scrollerArrowRef} />
                        </Box>
                    </Card.CardContent>
                </Card>
            )}
        </React.Fragment>
    );
};

export default RolePermissionTable;
