import React, { useImperativeHandle, useState, useRef } from 'react';
import { AutocompleteField, Button, Card, MenuItem, SelectField, TextField, Typography } from '../../../components';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { refIsRequiredError } from '../../../util/utils';
import usePeopleApi, { peopleType } from '../../../apis/usePeopleApi';

// 會員
export const FieldByMember = React.forwardRef((props, ref) => {
    const { defaultValue, readOnly } = props;
    const { people: memberOptions = [] } = usePeopleApi(peopleType.all);
    const memberRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    receiverID: memberRef.current.getResult().id,
                };
            },
            isError: () => refIsRequiredError(memberRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={5} item>
                <AutocompleteField
                    key={memberOptions}
                    className={'flex-auto'}
                    defaultValue={defaultValue}
                    label={'姓名/手機號碼/會員編號(擇一即可)'}
                    ref={memberRef}
                    options={memberOptions}
                    optionKeys={['name', 'nickName', 'mobile', 'no']}
                    optionMaskKeys={['maskName', 'nickName', 'maskMobile', 'no']}
                    primaryKey={'id'}
                    InputProps={{
                        disabled: readOnly,
                    }}
                    fullWidth
                    required
                />
            </Grid>
        </React.Fragment>
    );
});

// 非會員
const FieldByNonMember = React.forwardRef((props, ref) => {
    const { readOnly } = props;
    const nameRef = useRef();
    const telRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    receiverName: nameRef.current.getResult(),
                    receiverPhone: telRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(nameRef, telRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <TextField label={'姓名'} ref={nameRef} disabled={readOnly} fullWidth required />
            </Grid>
            <Grid xs={3} item>
                <TextField type={'tel'} label={'手機號碼'} ref={telRef} maskType={'MOB'} disabled={readOnly} fullWidth required />
            </Grid>
        </React.Fragment>
    );
});

// 跨店會員
const FieldByOtherClubMember = React.forwardRef((props, ref) => {
    const { clubOptions, readOnly } = props;
    const clubRef = useRef();
    const nameRef = useRef();
    const telRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (clubRef.current && clubRef.current.getResult) result.clubID = clubRef.current.getResult().value;
                if (nameRef.current && nameRef.current.getResult) result.receiverName = nameRef.current.getResult();
                if (telRef.current && telRef.current.getResult) result.receiverPhone = telRef.current.getResult();
                return result;
            },
            isError: () => refIsRequiredError(clubRef, nameRef, telRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <AutocompleteField
                    label={'分店'}
                    ref={clubRef}
                    options={clubOptions}
                    optionKeys={['text']}
                    primaryKey={'id'}
                    InputProps={{
                        disabled: readOnly,
                    }}
                    fullWidth
                    required
                />
            </Grid>
            <Grid xs={3} item>
                <TextField label={'姓名'} ref={nameRef} disabled={readOnly} fullWidth required />
            </Grid>
            <Grid xs={3} item>
                <TextField type={'tel'} label={'手機號碼'} ref={telRef} maskType={'MOB'} disabled={readOnly} fullWidth required />
            </Grid>
        </React.Fragment>
    );
});

// 教練
const FieldByEmployee = React.forwardRef((props, ref) => {
    const { employeeOptions, readOnly } = props;
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    receiverID: employeeRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(employeeRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={5} item>
                <SelectField label={'姓名'} ref={employeeRef} disabled={readOnly} fullWidth required>
                    {Array.isArray(employeeOptions) &&
                        employeeOptions
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

// 店舖
const FieldByClub = React.forwardRef((props, ref) => {
    const { clubOptions, readOnly } = props;
    const clubRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (clubRef.current && clubRef.current.getResult) result.targetClubID = clubRef.current.getResult().value;
                return result;
            },
            isError: () => refIsRequiredError(clubRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <AutocompleteField
                    label={'店舖名稱'}
                    ref={clubRef}
                    options={clubOptions}
                    optionKeys={['text']}
                    primaryKey={'id'}
                    InputProps={{
                        disabled: readOnly,
                    }}
                    fullWidth
                    required
                />
            </Grid>
        </React.Fragment>
    );
});

const SetTargetSearchBar = React.forwardRef((props, ref) => {
    const { className, title, resource = {}, isShowContent, setShowContent, doConfirmRemitApi, doConfirmMemberOrderApi } = props;
    const [receiver, setReceiver] = useState(0);
    const { receiverOptions = [], clubOptions = [], coachOptions: employeeOptions = [] } = resource;
    const receiverRef = useRef();
    const fieldRef = useRef();
    let isOtherClubMember = receiver === 3;

    const isError = (fieldRef, receiverRef) => refIsRequiredError(fieldRef, receiverRef);

    const handleReceiverChange = (e, n, v) => {
        setReceiver(parseInt(v, 10));
    };

    const handleClickConfirm = () => {
        if (!isShowContent) {
            if (!isError(fieldRef, receiverRef)) {
                if (receiver === 1) {
                    let memberID = fieldRef.current.getResult()?.receiverID;
                    doConfirmMemberOrderApi(memberID);
                } else if (receiver === 5) {
                    let clubID = fieldRef.current.getResult().targetClubID;
                    doConfirmRemitApi(clubID);
                } else if (receiver === 6) {
                    doConfirmRemitApi(0);
                }
                setShowContent(true);
            }
        } else {
            setShowContent(false);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (receiverRef.current) result.receiverType = receiverRef.current.getResult();
                if (fieldRef.current) Object.assign(result, fieldRef.current.getResult());
                return result;
            },
            isError: () => isError(fieldRef, receiverRef),
            getReceiver: () => receiver,
        }),
        // eslint-disable-next-line
        [receiver]
    );

    return (
        <Card className={clsx(className)}>
            <Card.CardContent className="px-4 pt-2 pb-3">
                <Typography className={'mb-1'} variant="h6" as={'h3'}>
                    {title}
                </Typography>
                <Grid spacing={3} container>
                    <Grid xs={3} item>
                        <SelectField label={'對象'} ref={receiverRef} onChange={handleReceiverChange} fullWidth disabled={isShowContent} required>
                            {Array.isArray(receiverOptions) &&
                                receiverOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    {receiver === 1 && <FieldByMember ref={fieldRef} readOnly={isShowContent} />}
                    {receiver === 2 && <FieldByNonMember ref={fieldRef} readOnly={isShowContent} />}
                    {receiver === 3 && <FieldByOtherClubMember ref={fieldRef} clubOptions={clubOptions} readOnly={isShowContent} />}
                    {receiver === 4 && <FieldByEmployee ref={fieldRef} employeeOptions={employeeOptions} readOnly={isShowContent} />}
                    {receiver === 5 && <FieldByClub ref={fieldRef} clubOptions={clubOptions} readOnly={isShowContent} />}
                    <Grid
                        className={clsx({
                            'ml-auto': isOtherClubMember,
                            'mt-4': !isOtherClubMember,
                        })}
                        xs={1}
                        item
                    >
                        <Button className={'white-space-nowrap mb-1'} variant={'contained'} onClick={handleClickConfirm}>
                            {isShowContent ? '重設' : '設定'}
                        </Button>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
});

export default SetTargetSearchBar;
