import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStepperContext } from '../stepper/StepperContext';
import { useStepContext } from '../step/StepContext';
import StepIcon from '../stepIcon/StepIcon';

const StepLabel = forwardRef((props, ref) => {
    const {
        children,
        className,
        componentsProps = {},
        error = false,
        icon: iconProp,
        optional,
        slotProps = {},
        StepIconComponent: StepIconComponentProp,
        StepIconProps = {},
        ...rest
    } = props;

    const { alternativeLabel, orientation } = useStepperContext();
    const { active, disabled, completed, icon: iconContext } = useStepContext();
    const icon = iconProp || iconContext;

    let StepIconComponent = StepIconComponentProp;

    if (icon && !StepIconComponent) {
        StepIconComponent = StepIcon;
    }

    const labelSlotProps = slotProps?.label ?? componentsProps.label;

    const rootClasses = clsx('step-label', {
        'step-label--alternative-label': alternativeLabel,
        'step-label--disabled': disabled,
        'step-label--vertical': orientation === 'vertical',
    });

    const iconContainerClasses = clsx('step-label__icon-container', {
        'step-label__icon-container--alternative-label': alternativeLabel,
    });

    const labelContainerClasses = clsx('step-label__label-container', {
        'step-label__label-container--alternative-label': alternativeLabel,
    });

    const labelClasses = clsx(
        'step-label__label',
        {
            'step-label__label--active': active,
            'step-label__label--completed': completed,
            'step-label__label--error': error,
            'step-label__label--alternative-label': alternativeLabel,
        },
        labelSlotProps?.className,
    );

    return (
        <span className={rootClasses} ref={ref} {...rest}>
            {icon || StepIconComponent ? (
                <span className={iconContainerClasses}>
                    <StepIconComponent completed={completed} active={active} error={error} icon={icon} {...StepIconProps} />
                </span>
            ) : null}
            <span className={labelContainerClasses}>
                <span className={labelClasses}>{children}</span>
            </span>
        </span>
    );
});

StepLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    componentsProps: PropTypes.shape({
        label: PropTypes.object,
    }),
    error: PropTypes.bool,
    icon: PropTypes.node,
    optional: PropTypes.node,
    slotProps: PropTypes.shape({
        label: PropTypes.object,
    }),
    StepIconComponent: PropTypes.elementType,
    StepIconProps: PropTypes.object,
};

export default StepLabel;
