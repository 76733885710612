import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const ListCheckUl = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1.875,14.375A1.875,1.875,0,1,0,3.75,16.25a1.875,1.875,0,0,0-1.875-1.875Zm17.5.625H6.875a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h12.5A.625.625,0,0,0,20,16.875v-1.25A.625.625,0,0,0,19.375,15Zm0-12.5H6.875a.625.625,0,0,0-.625.625v1.25A.625.625,0,0,0,6.875,5h12.5A.625.625,0,0,0,20,4.375V3.125A.625.625,0,0,0,19.375,2.5Zm0,6.25H6.875a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h12.5A.625.625,0,0,0,20,10.625V9.375A.625.625,0,0,0,19.375,8.75Z"
                transform="translate(2 2.125)"
            />
            <path
                d="M3.5,6l3-3c0.075-0.075,0.225-0.075,0.3,0l0.825,0.825c0.075,0.075,0.075,0.225,0,0.3l-3.975,3.975l0,0l0,0l0,0l0,0h0l0,0l0,0l0,0 l0,0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0h-0.075h0h0h0l0,0h0l0,0h0l0,0h0l0,0h0 l0,0l0,0l0,0c0,0,0,0-0.075,0c-3.45-3.45,1.125,1.125-2.4-2.4c-0.075-0.075-0.075-0.225,0-0.3l0.825-0.825c0.075-0.075,0.225-0.075,0.3,0 L3.5,6z"></path>
            <path
                d="M3.5,12l3-3c0.075-0.075,0.225-0.075,0.3,0l0.825,0.825c0.075,0.075,0.075,0.225,0,0.3l-3.975,3.975l0,0l0,0l0,0l0,0h0l0,0l0,0l0,0 l0,0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0h0h-0.075h0h0h0l0,0h0l0,0h0l0,0h0l0,0h0 l0,0l0,0l0,0c0,0,0,0-0.075,0c-3.45-3.45,1.125,1.125-2.4-2.4c-0.075-0.075-0.075-0.225,0-0.3l0.825-0.825c0.075-0.075,0.225-0.075,0.3,0 L3.5,12z"></path>
        </SvgIcon>
    );
});

export default ListCheckUl;
