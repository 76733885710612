import React from 'react';
import { Box, Button } from '@common/components/';
import PropTypes from 'prop-types';
import CommonTabs from '@icoach/components/CommonTabs';

const TabsWithActions = ({ tabs, activeTab, onTabChange, actions }) => {
    return (
        <Box className="page-tabs tabs-bottom-line mb-4">
            <CommonTabs tabs={tabs} onTabChange={onTabChange} activeTab={activeTab} />
            {actions && (
                <Box className="btn-group">
                    {actions.map(({ label, variant, onClick }) => (
                        <Button key={label} variant={variant} onClick={onClick}>
                            {label}
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
};

TabsWithActions.prototype = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
            icon: PropTypes.element,
            component: PropTypes.elementType,
            to: PropTypes.string,
        }),
    ).isRequired,
    activeTab: PropTypes.string,
    onTabChange: PropTypes.func,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
            onClick: PropTypes.func.isRequired,
        }),
    ),
};

export default TabsWithActions;
