import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import OverviewListTable from '@icoach/components/overviewList/OverviewListTable';
import ProductCard from '@icoach/components/product/ProductCard';
import useInspectionOrderApi from '@apis/useInspectionOrderApi';
import { generateSummaryRow } from '@util/generateSummaryRow';
import { getSummaryRowConfig } from '@util/getSummaryRowConfig';
import { isEmpty } from '@util/utils';

const PurchaseReceiptStorageRecordDialog = ({ className, open, sourceData = {}, onClose, refresh }) => {
    const { inspectionOrderID } = sourceData || {};
    const { getInspectionOrderApi } = useInspectionOrderApi();

    const [inspectionOrderData, setInspectionOrderData] = useState({});
    const { items = [], employeeName, inspectionDate } = inspectionOrderData || {};
    const inspectionOrderWithSummary = useMemo(
        () => {
            const sumFields = ['quantity', 'inspectionQuantity'];

            let summaryRowData = [];
            if (isEmpty(items)) return summaryRowData;
            else summaryRowData = generateSummaryRow({ sumFields, list: items, summaryKey: 'productName' });

            return [...items, ...summaryRowData];
        },
        // eslint-disable-next-line
        [items],
    );

    const bodyRowClass = useMemo(
        getSummaryRowConfig,
        // eslint-disable-next-line
        [],
    );

    const headerRowData = useMemo(
        () => [
            {
                cellKey: 'productName',
                headerLabel: '商品清單',
                formatCell: (productName, { sku, variantName, productQuantitySummaryRef }) => {
                    if (productQuantitySummaryRef?.current) return <span className={'font-weight-bold'}>{productName}</span>;
                    return <ProductCard productName={productName} sku={sku} spec={variantName} />;
                },
            },
            {
                cellKey: 'quantity',
                headerLabel: '訂購',
                align: 'center',
                formatCell: (value) => {
                    return value;
                },
            },
            {
                cellKey: 'inspectionQuantity',
                headerLabel: '入庫',
                align: 'center',
                formatCell: (value) => {
                    return <span className={'font-weight-bold text-success'}>{value}</span>;
                },
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const doInspectionOrderApi = async (inspectionOrderID) => {
        const resp = await getInspectionOrderApi(inspectionOrderID);
        if (resp) {
            setInspectionOrderData(resp);
        }
    };

    const handleSubmit = () => {};

    useEffect(() => {
        if (open) {
            doInspectionOrderApi(inspectionOrderID);
        }
        return () => {
            setInspectionOrderData({});
        };
    },
        // eslint-disable-next-line
        [open]);

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>進貨單入庫紀錄</GradientColorHeader>
            <DialogContent>
                <Box className={'mb-3'}>
                    <Grid container spacing={2}>
                        <Grid xs={6} item>
                            <ReadTextField label={'入庫時間'}>{parseDate(inspectionDate, DateTimeStandard.DisplayFullBySlash)}</ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <ReadTextField label={'擔當'}>{employeeName}</ReadTextField>
                        </Grid>
                    </Grid>
                </Box>
                <OverviewListTable headerRow={headerRowData} list={inspectionOrderWithSummary} bodyRowClass={bodyRowClass} isGray />
            </DialogContent>
        </Dialog>
    );
};

PurchaseReceiptStorageRecordDialog.propTypes = {};

export default PurchaseReceiptStorageRecordDialog;
