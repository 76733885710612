/** █ 網站首頁起始點 **/
export const ROOT_ROUTER = `/club`; // 不需要斜線
export const ROOT__ROUTER = `/club/`;
export const ROOT_LOGIN_ROUTER = `/club/login`; // 不需要斜線
export const ROOT_LOGIN__ROUTER = `/club/login/`;
export const ROOT_LOGIN_RESET_PASSWORD_TOKEN_ROUTER = `/login/club/reset-password/:token/`;
export const ROOT_LOGIN_RESET_PASSWORD_TOKEN_PATH = (token) => `/login/club/reset-password/${token}/`;

/** █ 登入 **/
export const LOGIN_ROUTER = `/login/`;
export const LOGIN_COACH_ROUTER = `/login/coach/`;
export const LOGIN_COACH_RESET_PASSWORD_TOKEN_ROUTER = `/login/coach/reset-password/:token/`;
export const LOGIN_COACH_RESET_PASSWORD_TOKEN_PATH = (token) => `/login/coach/reset-password/${token}/`;

/** █ 首頁來電通知 **/
export const HOME_ARRIVE_TABS_ROUTER = '/club/home/:tabs(arrive)/';
export const HOME_ARRIVE_ROUTER = '/club/home/arrive/';

/** █ 通知 **/
export const ROOT_NOTIFICATIONS_ROUTER = `/club-notifications/`;
export const NOTIFICATIONS_ENTIRE_ROUTER = `/club-notifications/entire/`;
export const NOTIFICATIONS_DOC_TRANSFERIN_ROUTER = `/club-notifications/doc-transferin/`;
export const NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER = `/club-notifications/doc-transferout/`;
export const NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER = `/club-notifications/doc-membership-expire/`;
export const NOTIFICATIONS_INVOICE_ROUTER = `/club-notifications/invoice/`;
export const NOTIFICATIONS_UPDATE_VERSION_ROUTER = `/club-notifications/update-version/`;
export const NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER = `/club-notifications/doc-temporariness/`;
export const NOTIFICATIONS_CYCLE_PURCHASE_ROUTER = `/club-notifications/cycle-purchase/`;

/** █ 預約單 **/
export const APPOINTMENT_APPOINTMENTID_INFORMATIO_ROUTER = `/club/appointment/:appointmentID/information/`;
export const APPOINTMENT_APPOINTMENTID_INFORMATIO_PATH = (appointmentID) => `/club/appointment/${appointmentID}/information/`;

/** █ 營運報表 **/
export const DATA_ANALYSIS_ROUTER = '/club/data-analysis/';
export const DATA_ANALYSIS_TABS_ROUTER = '/club/data-analysis/:tabs(goal|membership|service|guest-production|sales|employees|task-matter)?/';
export const DATA_ANALYSIS_GOAL_ROUTER = `/club/data-analysis/:tabs(goal)/`;
export const DATA_ANALYSIS_GOAL_URL_ROUTER = `/club/data-analysis/goal/`;
export const DATA_ANALYSIS_MEMBERSHIP_ROUTER = `/club/data-analysis/:tabs(membership)/`;
export const DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER = `/club/data-analysis/membership/`;
export const DATA_ANALYSIS_SERVICE_ROUTER = `/club/data-analysis/:tabs(service)/`;
export const DATA_ANALYSIS_SERVICE_URL_ROUTER = `/club/data-analysis/service/`;
export const DATA_ANALYSIS_GUEST_PRODUCTION_ROUTER = `/club/data-analysis/:tabs(guest-production)/`;
export const DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER = `/club/data-analysis/guest-production/`;
export const DATA_ANALYSIS_SALES_ROUTER = `/club/data-analysis/:tabs(sales)/`;
export const DATA_ANALYSIS_SALES_URL_ROUTER = `/club/data-analysis/sales/`;
export const DATA_ANALYSIS_EMPLOYEES_ROUTER = `/club/data-analysis/:tabs(employees)/`;
export const DATA_ANALYSIS_EMPLOYEES_URL_ROUTER = `/club/data-analysis/employees/`;
export const DATA_ANALYSIS_TASK_MATTER_ROUTER = `/club/data-analysis/:tabs(task-matter)/`;
export const DATA_ANALYSIS_TASK_MATTER_URL_ROUTER = `/club/data-analysis/task-matter/`;

/** █ 量身管理 **/
export const INBODY_RECORD_ROUTER = `/club/inbody/history/`;
export const INBODY_RECORD_PATH = ({ referenceType, referenceID }) => `/club/inbody/history/${referenceType}/${referenceID}/`;
export const INBODY_INTERVIEW_ROUTER = `/club/inbody/interview/`;
export const INBODY_INTERVIEW_ID_TABS_ROUTER = `/club/inbody/interview/:id/:tabs(summary|measure|notes)/`;
export const INBODY_INTERVIEW_SUMMARY_PATH = (id) => `/club/inbody/interview/${id}/summary/`;
export const INBODY_INTERVIEW_COMPARE_PATH = (id) => `/club/inbody/interview/${id}/compare/`;
export const INBODY_INTERVIEW_MEASURE_PATH = (id) => `/club/inbody/interview/${id}/measure/`;
export const INBODY_INTERVIEW_NOTES_PATH = (id) => `/club/inbody/interview/${id}/notes/`;
export const INBODY_OVERVIEW_FILTER_NUM_PATH = (num) => `/club/inbody/overview/filter/${num}/`;

/** █ 教練量身 **/
export const INBODY_EMPLOYEE_INTERVIEW_ROUTER = `/club/inbody/employee-interview/`;
export const INBODY_EMPLOYEE_INTERVIEW_COMPARE_PATH = (id) => `/club/inbody/employee-interview/${id}/compare/`;
export const INBODY_EMPLOYEE_INTERVIEW_MEASURE_PATH = (id) => `/club/inbody/employee-interview/${id}/measure/`;

/** █ 會籍文書相關 **/
export const DOCUMENTS_ROUTER = '/club/documents/';

/** ▌會籍申請 **/
export const DOCUMENTS_MEMBERSHIP_ROUTER = '/club/documents/membership/';
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = '/club/documents/membership/:peopleType/:referenceID/history/';
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER = '/club/documents/membership/:peopleType/:referenceID/contract/:contractID/';
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER =
    '/club/documents/membership/:peopleType/:referenceID/readonly-contract/:contractID/';
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH = (peopleType, referenceID) =>
    `/club/documents/membership/${peopleType}/${referenceID}/history/`;
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membership/${peopleType}/${referenceID}/contract/${contractID}/`;
export const DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membership/${peopleType}/${referenceID}/readonly-contract/${contractID}/`;

/** ▌會籍暫停 **/
export const DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER = '/club/documents/membershipsuspension/';
export const DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = '/club/documents/membershipsuspension/:peopleType/:referenceID/history/';
export const DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER =
    '/club/documents/membershipsuspension/:peopleType/:referenceID/contract/:contractID/';
export const DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER =
    '/club/documents/membershipsuspension/:peopleType/:referenceID/readonly-contract/:contractID/';
export const DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH = (peopleType, referenceID) =>
    `/club/documents/membershipsuspension/${peopleType}/${referenceID}/history/`;

/** ▌會籍轉讓 **/
export const DOCUMENTS_MEMBERSHIPTRANSFEROVER_ROUTER = '/club/documents/membershiptransferover/';
export const DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER =
    '/club/documents/membershiptransferover/:peopleType/:referenceID/history/';
export const DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER =
    '/club/documents/membershiptransferover/:peopleType/:referenceID/contract/:contractID/';
export const DOCUMENTS_MEMBERSHIPTRANSFEROVER_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER =
    '/club/documents/membershiptransferover/:peopleType/:referenceID/readonly-contract/:contractID/';

/** ▌移入申請書 - 國內移入 **/
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ROUTER = '/club/documents/membershiptransferin/';
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH = (enteredType) => `/club/documents/membershiptransferin/${enteredType}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_PEOPLETYPE_ROUTER_PATH = (enteredType, peopleType) =>
    `/club/documents/membershiptransferin/${enteredType}/${peopleType}/`;

/** ▌移入申請書 - 尚未移入/已移入 **/
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = `/club/documents/membershiptransferin/:enteredType(unentered|entered)/:peopleType/:referenceID/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER = `/club/documents/membershiptransferin/unentered/:peopleType/:referenceID/contract/:contractID/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER = `/club/documents/membershiptransferin/entered/:peopleType/:referenceID/contract/:contractID/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER = `/club/documents/membershiptransferin/entered/:peopleType/:referenceID/readonly-contract/:contractID/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_ROUTER_PATH = (peopleType, referenceID) =>
    `/club/documents/membershiptransferin/unentered/${peopleType}/${referenceID}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH = (peopleType, referenceID) =>
    `/club/documents/membershiptransferin/unentered/${peopleType}/${referenceID}/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH = (enteredType, peopleType, referenceID) =>
    `/club/documents/membershiptransferin/${enteredType}/${peopleType}/${referenceID}/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferin/unentered/${peopleType}/${referenceID}/contract/${contractID}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferin/entered/${peopleType}/${referenceID}/contract/${contractID}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTERED_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferin/entered/${peopleType}/${referenceID}/readonly-contract/${contractID}/`;

/** ▌移出申請書 - 國內移出 **/
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_PAGE_ROUTER = `/club/documents/membershiptransferout/:moveoutType(unmoveout|moveout)?/:peopleType?/:referenceID?/:page(history|contract|readonly-contract)?/:contractID?/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = `/club/documents/membershiptransferout/:moveoutType(unmoveout|moveout)/:peopleType/:referenceID/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_ROUTER = `/club/documents/membershiptransferout/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = `/club/documents/membershiptransferout/unmoveout/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = `/club/documents/membershiptransferout/moveout/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PATH = (moveoutType) => `/club/documents/membershiptransferout/${moveoutType}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_PATH = (moveoutType, peopleType) =>
    `/club/documents/membershiptransferout/${moveoutType}/${peopleType}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER = `/club/documents/membershiptransferout/unmoveout/:peopleType/:referenceID/contract/:contractID/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER = `/club/documents/membershiptransferout/unmoveout/:peopleType/:referenceID/readonly-contract/:contractID/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER = `/club/documents/membershiptransferout/moveout/:peopleType/:referenceID/readonly-contract/:contractID/`;

export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_PATH = (peopleType, referenceID) =>
    `/club/documents/membershiptransferout/moveout/${peopleType}/${referenceID}/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUTTYPE_PEOPLETYPE_REFERENCEID_HISTORY_PATH = (moveoutType, peopleType, referenceID) =>
    `/club/documents/membershiptransferout/${moveoutType}/${peopleType}/${referenceID}/history/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_CONTRACT_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferout/unmoveout/${peopleType}/${referenceID}/contract/${contractID}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferout/unmoveout/${peopleType}/${referenceID}/readonly-contract/${contractID}/`;
export const DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/membershiptransferout/moveout/${peopleType}/${referenceID}/readonly-contract/${contractID}/`;

/** ▌健康申告書 **/
export const DOCUMENTS_HEALTHDECLARATION_ROUTER = '/club/documents/healthdeclaration/';
export const DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = '/club/documents/healthdeclaration/:peopleType/:referenceID/history/';
export const DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER =
    '/club/documents/healthdeclaration/:peopleType/:referenceID/contract/:contractID/';
export const DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER =
    '/club/documents/healthdeclaration/:peopleType/:referenceID/readonly-contract/:contractID/';

export const DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/healthdeclaration/${peopleType}/${referenceID}/contract/${contractID}/`;
export const DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH = (peopleType, referenceID, contractID) =>
    `/club/documents/healthdeclaration/${peopleType}/${referenceID}/readonly-contract/${contractID}/`;

/** ▌循環訂單 **/
export const DOCUMENT_CYCLE_PURCHASE_ROUTER = '/club/documents/cycle-purchase/';
export const DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_ROUTER = '/club/documents/cycle-purchase/:memberID/:cycleOrderID/:tabs(main|history|future)';
export const DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_MAIN_ROUTER = '/club/documents/cycle-purchase/:memberID/:cycleOrderID/:tabs(main)';
export const DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_HISTORY_ROUTER = '/club/documents/cycle-purchase/:memberID/:cycleOrderID/:tabs(history)';
export const DOCUMENT_CYCLE_PURCHASE_MEMBERID_CYCLEORDERID_FUTURE_ROUTER = '/club/documents/cycle-purchase/:memberID/:cycleOrderID/:tabs(future)';
export const DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER = '/club/documents/cycle-purchase/create/';
export const DOCUMENT_CYCLE_PURCHASE_EFFECT_ROUTER = `/club/documents/cycle-purchase/effect/:memberID/:cycleOrderID/:effectID/`;
export const DOCUMENT_CYCLE_PURCHASE_PATH = (memberID, cycleOrderID, tabName = 'main') =>
    `/club/documents/cycle-purchase/${memberID}/${cycleOrderID}/${tabName}/`;
export const DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH = (memberID, cycleOrderID, effectID = 0) =>
    `/club/documents/cycle-purchase/effect/${memberID}/${cycleOrderID}/${effectID}/`;

/** ▌終止申請書 **/
export const DOCUMENTS_MEMBERSHIP_QUIT_ROUTER = '/club/documents/membership-quit/';
export const DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER = '/club/documents/membership-quit/:peopleType/:referenceID/history/';
export const DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER =
    '/club/documents/membership-quit/:peopleType/:referenceID/contract/:contractID/';
export const DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER =
    '/club/documents/membership-quit/:peopleType/:referenceID/readonly-contract/:contractID/';
export const DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH = (peopleType, referenceID) =>
    `/club/documents/membership-quit/${peopleType}/${referenceID}/history/`;

/** █ 點數管理 **/
export const POINTS_POINTS_REDEEM_OVERVIEW_ROUTER = `/club/points/points-redeem/overview/`;
export const POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER = `/club/points/points-product-gift/overview/`;

/** █ 排行榜 **/
export const MEASURE_HEADERBOARD_ROUTER = `/club/measure/headerboard/`;

/** █ 進貨管理 **/
export const PURCHASE_RECEIPT_OVERVIEW_ROUTER = `/club/purchase-receipt/overview/`;
export const PURCHASE_RECEIPT_ORDER_ROUTER = `/club/purchase-receipt/order/:purchaseReceiptID/:tabs(product-list|stock-in-records|stock-in-error-records)/`;
export const PURCHASE_RECEIPT_ORDER_PATH = (purchaseReceiptID, tabs = 'product-list') => `/club/purchase-receipt/order/${purchaseReceiptID}/${tabs}`;

/** █ 店鋪管理 **/
export const HUMAN_RESOURCES_TABS = `/club/human-resources/:tabs(employees-overview|roles-permissions-overview)/`;
export const HUMAN_RESOURCES_EMPLOYEES_OVERVIEW = `/club/human-resources/employees-overview/`;
export const HUMAN_RESOURCES_ROLES_PERMISSION_OVERVIEW = `/club/human-resources/roles-permissions-overview/`;

export const HUMAN_RESOURCES_ADD = `/club/human-resources/employees/add/`;
export const HUMAN_RESOURCES_INFORMATION_EMPLOYEEID = `/club/human-resources/employees/information/:employeeID/`;
export const HUMAN_RESOURCES_INFORMATION_EMPLOYEEID_PATH = (employeeID) => `/club/human-resources/employees/information/${employeeID}/`;

export const SYSTEM_CONFIGURATION_TABS = `/club/system-configuration/:tabs(basic|others)/`;
export const SYSTEM_CONFIGURATION_BASIC = `/club/system-configuration/basic/`;
export const SYSTEM_CONFIGURATION_OTHERS = `/club/system-configuration/others/`;
export const CLUB_CLOSED_CONFIGURATION = `/club/club-closed/configuration/`;


// /** █ 營收管理 **/ Allen To do
// export const ACCOUNT_BOOK_ORDERS_OVERVIEW = `/club/account-book/:tabs(orders)/overview/`;
// export const ACCOUNT_BOOK_ORDER_CREATE = `/club/account-book/orders/create/`;
// export const ACCOUNT_BOOK_ORDERS_OVERVIEW_URL = `/club/account-book/orders/overview/`;

// export const ACCOUNT_BOOK_REMIT_CREATE = `/club/account-book/remit/create/`;


// export const ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW = `/club/account-book/:tabs(remit-orders)/overview/`;
// export const ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL = `/club/account-book/remit-orders/overview/`;
// export const ACCOUNT_BOOK_REMIT_ALLOWANCE = `/club/account-book/remit/:remittanceID/allowance-history/`;
// export const ACCOUNT_BOOK_REMIT_ALLOWANCE_URL = (remittanceID) => `/club/account-book/remit/${remittanceID}/allowance-history/`;
// export const ACCOUNT_BOOK_SITE_ORDER_STAT = `/club/account-book/:tabs(site-order-stat)/overview/`;
// export const ACCOUNT_BOOK_SITE_ORDER_STAT_URL = '/club/account-book/site-order-stat/overview/';
// export const ACCOUNT_BOOK_ALL_ORDER_STAT = `/club/account-book/:tabs(all-order-stat)/overview/`;
// export const ACCOUNT_BOOK_ALL_ORDER_STAT_URL = `/club/account-book/all-order-stat/overview/`;
// export const ACCOUNT_BOOK_BULK_DEBIT = `/club/account-book/:tabs(bulk-debit)/`;
// export const ACCOUNT_BOOK_BULK_DEBIT_URL = `/club/account-book/bulk-debit/`;

// export const ACCOUNT_BOOK_ORDERS_ORDER = `/club/account-book/orders/:orderID/`;
// export const ACCOUNT_BOOK_ORDERS_ORDER_URL = (orderID) => `/club/account-book/orders/${orderID}/`;
// export const ACCOUNT_BOOK_ORDERS_ALLOWANCE = `/club/account-book/orders/:orderID/allowance-history/`;
// export const ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL = (orderID) => `/club/account-book/orders/${orderID}/allowance-history/`;

// export const ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE = `/club/account-book/settle-cash-price/:date/`;
// export const ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL = (date) => `/club/account-book/settle-cash-price/${date}/`;

// export const ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL = `/club/account-book/:tabs(bulk-debit)/billing/:billingID`;
// export const ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL_URL = (billingID) => `/club/account-book/bulk-debit/billing/${billingID}`;

// // 批次-循環訂單
// export const ACCOUNT_BOOK_BULK_DEBIT_CYCLE_ORDER = `/club/account-book/:tabs(bulk-debit)/cycle-order/:date/`;
// export const ACCOUNT_BOOK_BULK_DEBIT_CYCLE_ORDER_URL = (date) => `/club/account-book/bulk-debit/cycle-order/${date}/`;
// // 批次-會籍月費
// export const ACCOUNT_BOOK_BULK_DEBIT_MEMBERSHIP = `/club/account-book/:tabs(bulk-debit)/membership/:date/`;
// export const ACCOUNT_BOOK_BULK_DEBIT_MEMBERSHIP_URL = (date) => `/club/account-book/bulk-debit/membership/${date}/`;
// // 批次-租櫃
// export const ACCOUNT_BOOK_BULK_DEBIT_RENT_CABINET = `/club/account-book/:tabs(bulk-debit)/rent-cabinet/:date/`;
// export const ACCOUNT_BOOK_BULK_DEBIT_RENT_CABINET_URL = (date) => `/club/account-book/bulk-debit/rent-cabinet/${date}/`;

// export const ACCOUNT_BOOK_OVERVIEW_FILTER = `/club/account-book/overview/filter/`;