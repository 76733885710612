import React, {
    useCallback,
    // useCallback,
    useMemo,
} from 'react';
import clsx from 'clsx';
import { PROSPECT_ROUTER, CUSTOMER_ROUTER } from '@icoach/nonMember/staticData';
import MoreLabel from '@icoach/nonMember/components/MoreLabel';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import Tablenization from '@icoach/components/Tablenization';
import { FormControlLabel, Checkbox, Tooltip } from '@common/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import usePrivacyToggle, { securityMaskFilterTypeMap } from '@util/hook/usePrivacyToggle';
import { getCustomerRowsLabel, getProspectRowsLabel } from '@icoach/nonMember/tableColumnsConfig';
import { PrivacyDataToggleButton } from '@icoach/components';

const TemporariesInfoTable = (props) => {
    const { setBookmarkNum } = useNonMember();
    const { sourceData = {}, type: propsType, refresh } = props;
    let { pageIndex: page = 1, totalPage: count = 1, list: rowsData = [] } = sourceData;
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(rowsData);
    const { isRevealed, toggle } = usePrivacyToggle();

    // todo
    const handleToggle = useCallback(() => {
        const apiParams = {
            filterType: securityMaskFilterTypeMap.ClubCustomerTemporary,
            totalCount: rowsData.length,
        };
        toggle(apiParams);
    }, [rowsData.length, toggle]);

    const tableTitleLabel = useMemo(
        () => {
            const getMoreLabel = () => ({
                cellKey: 'more',
                headerLabel: '',
                align: 'right',
                isIcon: true,
                formatCell: (cellValue, rowData) => {
                    return <MoreLabel moreActionArray={['removetemp']} sourceData={rowData} {...(rowData || {})} />;
                },
            });
            const overviewLabelMap = {
                [PROSPECT_ROUTER]: getProspectRowsLabel(isRevealed),
                [CUSTOMER_ROUTER]: getCustomerRowsLabel(isRevealed),
            };
            let targetLabel = [...overviewLabelMap[propsType], getMoreLabel()];
            return targetLabel;
        },
        // eslint-disable-next-line
        [propsType, isRevealed, toggle],
    );

    const handlePageChange = (e, num) => {
        setBookmarkNum(num);
    };

    return (
        <div className="customer-table">
            <div className="customer-table-action">
                <div className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader viewIconArray={['removetemp']} selectedRef={selectedOptRef} rowsData={rowsData} refresh={refresh.current} />
                    <Tooltip title={isRevealed ? '隱蔽資料' : '顯示完整資料'}>
                        <PrivacyDataToggleButton className={'has-divider'} isRevealed={isRevealed} onToggle={handleToggle} />
                    </Tooltip>
                </div>
            </div>
            <div>
                <Tablenization isPagination page={page} count={count} onChange={handlePageChange}>
                    <Tablenization.TableRowHeader headerRow={tableTitleLabel} />
                    <Tablenization.TableRowBody headerRow={tableTitleLabel} rowsData={rowsData} chkRef={selectedOptRef} checkedOnChange={handleCheckedChange} />
                </Tablenization>
            </div>
        </div>
    );
};

export default TemporariesInfoTable;
