import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Box } from '@common/components/';
import InteractiveIcon from '@icoach/components/InteractiveIcon';

const InteractiveLabel = ({
    label,
    justifyContent = 'start',
    alignItems = 'center',
    labelProps,
    icon: CustomIcon,
    iconSize,
    iconProps = {},
    isIcon = false,
    ...rest
}) => {
    const renderLabel = () => {
        if (React.isValidElement(label)) {
            return React.cloneElement(label, labelProps);
        }
        return label ? <Box>{label}</Box> : null;
    };

    return (
        <Stack alignItems={alignItems} justifyContent={justifyContent} {...rest}>
            {renderLabel()}
            {isIcon && <InteractiveIcon {...iconProps} icon={CustomIcon} iconSize={iconSize} />}
        </Stack>
    );
};

InteractiveLabel.propTypes = {
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    fontSize: PropTypes.string,
    iconProps: PropTypes.object,
    labelProps: PropTypes.object,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    isIcon: PropTypes.bool,
};

export default InteractiveLabel;
