import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { GET_PURCHASE_RECEIPT_ORDER_API, GET_PURCHASE_RECEIPT_OPTIONS_API, POST_PURCHASE_RECEIPT_OVERVIEW_API } from '@apis/apiPath';

const UsePurchaseReceiptApi = (props) => {
    const Ajax = useAjax();

    const getPurchaseReceiptOptionsApi = useCallback(
        async (skipAll) => await Ajax.get(GET_PURCHASE_RECEIPT_OPTIONS_API(skipAll)),
        // eslint-disable-next-line
        [],
    );

    const getPurchaseReceiptOrderApi = useCallback(
        async (purchaseOrderID) => await Ajax.get(GET_PURCHASE_RECEIPT_ORDER_API(purchaseOrderID)),
        // eslint-disable-next-line
        [],
    );

    const postPurchaseReceiptOverviewApi = useCallback(
        async (params) => await Ajax.post(POST_PURCHASE_RECEIPT_OVERVIEW_API, params),
        // eslint-disable-next-line
        [],
    );

    return {
        getPurchaseReceiptOptionsApi,
        getPurchaseReceiptOrderApi,
        postPurchaseReceiptOverviewApi,
    };
};

export default UsePurchaseReceiptApi;
