import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import { MorePageBox } from '@icoach/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import clsx from 'clsx';

const OverviewListTable = ({
    className,
    list = [],
    headerRow = [],
    refresh,
    bodyRowClass,
    onChangePage,
    pageIndex,
    totalPage,
    isPagination = false,
    isMasonryPagination = false,
    isGray = false,
    tableRowHeaderProps = {},
    tableRowBodyProps = {},
    ...rest
}) => {
    const scrollerArrowRef = useRef(null);

    return (
        <Box className={clsx('scroll-arrow-container', className)} {...rest}>
            <Tablenization
                cardProps={{ className: clsx('overview', { 'theme-gray': isGray }) }}
                page={parseInt(pageIndex)}
                count={totalPage}
                onChange={onChangePage}
                tableContainerRef={scrollerArrowRef}
                Pagination={isMasonryPagination && MorePageBox}
                isPagination={isPagination}
            >
                <Tablenization.TableRowHeader headerRow={headerRow} {...tableRowHeaderProps} />
                <Tablenization.TableRowBody headerRow={headerRow} rowsData={list} refresh={refresh} bodyRowClass={bodyRowClass} {...tableRowBodyProps} />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

OverviewListTable.prototype = {
    className: PropTypes.string,
    list: PropTypes.array,
    headerRow: PropTypes.arrayOf(
        PropTypes.shape({
            cellKey: PropTypes.string.isRequired,
            headerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
            formatCell: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
            align: PropTypes.string,
        }),
    ).isRequired,
    refresh: PropTypes.func,
    bodyRowClass: PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        className: PropTypes.string.isRequired,
    }),
    onChangePage: PropTypes.func,
    pageIndex: PropTypes.number,
    totalPage: PropTypes.number,
    isPagination: PropTypes.bool,
    isMasonryPagination: PropTypes.bool,
    isGray: PropTypes.bool,
    tableRowHeaderProps: PropTypes.object,
    tableRowBodyProps: PropTypes.object,
};

export default OverviewListTable;
