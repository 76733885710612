import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

/**
 * 判斷是否為空值。
 * 支援檢查 `null`、`undefined`、空字串、空陣列及空物件。
 *
 * @param {*} _value - 要檢查的值，可以是任意類型。
 * @returns {boolean} - 如果值為空，返回 `true`；否則返回 `false`。
 *
 * @example
 * isEmpty([]); // 返回: true
 * isEmpty({}); // 返回: true
 * isEmpty(''); // 返回: true
 * isEmpty(null); // 返回: true
 * isEmpty(undefined); // 返回: true
 * isEmpty(0); // 返回: false
 * isEmpty('value'); // 返回: false
 * isEmpty([1, 2, 3]); // 返回: false
 */
export const isEmpty = (_value) => {
    // 判斷是否為陣列，且長度為 0
    if (Array.isArray(_value)) {
        return !_value.length;
    }
    // 判斷是否為物件，且為空物件
    if (Object.prototype.toString.call(_value) === '[object Object]') {
        return Object.keys(_value).length === 0;
    }
    // 判斷是否為 `null` 或 `undefined` 或空字串
    return _value === null || _value === undefined || _value === '';
};

/**
 * 判斷值是否為無效值 (`undefined` 或 `null`)。
 *
 * @param {*} _value - 要檢查的值，可以是任意類型。
 * @returns {boolean} - 如果值為無效 (`undefined` 或 `null`)，返回 `true`；否則返回 `false`。
 *
 * @example
 * isInvalid(undefined); // 返回: true
 * isInvalid(null); // 返回: true
 * isInvalid(''); // 返回: false
 * isInvalid(0); // 返回: false
 * isInvalid(false); // 返回: false
 */
export const isInvalid = (_value) => _value === undefined || _value === null;

/**
 * 判斷值是否為有效值。
 * 值為有效的條件是：非 `null`、非 `undefined`，且非 `NaN`。
 *
 * @param {*} _value - 要檢查的值，可以是任意類型。
 * @returns {boolean} - 如果值為有效，返回 `true`；否則返回 `false`。
 *
 * @example
 * // 無效值
 * isValid(null); // 返回: false
 * isValid(undefined); // 返回: false
 * isValid(NaN); // 返回: false
 *
 * // 有效值
 * isValid(''); // 返回: true
 * isValid(0); // 返回: true
 * isValid(false); // 返回: true
 * isValid([]); // 返回: true
 * isValid({}); // 返回: true
 */
export const isValid = (_value) => {
    if (_value === null) return false; // 無效值 null
    if (_value === undefined) return false; // 無效值 undefined
    if (typeof _value === 'number' && Number.isNaN(_value)) return false; // 無效值 NaN
    return true; // 其他皆為有效值
};

/**
 * 檢查數值是否為數字
 * @param {*} _value
 * @returns {Boolean}
 */
export const isNumber = (_value) => !(parseFloat(_value).toString() === 'NaN');

/**
 * 判斷是否為整數
 * @param {*} _n
 * @returns {boolean}
 */
export const isInt = (_n) => {
    return Number(_n) === _n && _n % 1 === 0;
};

/**
 * 判斷是否為小數
 * @param {*} _n
 * @returns {boolean}
 */
export const isFloat = (_n) => {
    return Number(_n) === _n && _n % 1 !== 0;
};

/**
 * 設置React.ref 的值
 * @param {any} ref
 * @param {any} value
 */
export const setRef = (ref, value) => {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
};

/**
 * 檢查ref內是否存在
 * @param {String} key
 * @param  {...React.MutableRefObject} objectRefs
 * @return {Boolean}
 */
export const checkRefFuncExists = (key, ...objectRefs) => {
    let isExists = true;
    const arrayRef = Array.from(objectRefs);
    if (arrayRef.length === 0 || String(key) === '') return false;
    arrayRef.forEach((_r) => {
        if (!(_r.current && typeof _r.current[key] === 'function')) {
            isExists = false;
        }
    });
    return isExists;
};

/**
 * 判斷是否為特定類型的 React 元素。
 *
 * @param {React.ReactNode} elem - 要檢查的 React 元素。
 * @param {string[]} curNames - 包含目標 `curName` 的字符串數組，用於匹配 `elem.type.curName`。
 * @returns {boolean} - 如果 `elem` 是有效的 React 元素，且 `elem.type.curName` 存在於 `curNames` 中，返回 `true`；否則返回 `false`。
 */
export const isCurElement = (elem, curNames) => React.isValidElement(elem) && curNames.includes(elem.type?.['curName']);

/*
 * 判斷一個值是否為有效值。
 * 支援判斷 `null`、`undefined` 和空陣列為無效值，
 * 而空字串（`''`）和數字（包括 0）則被認為是有效值。
 *
 * 該函數常用於表單處理中，用於判斷受控元件是否有值。
 *
 * @see https://react.dev/learn/controlled-components
 * @param {*} value - 要判斷的值。
 * @returns {boolean} - 如果值有效，返回 `true`；否則返回 `false`。
 *
 * @example
 * hasValue(null); // 返回: false
 * hasValue('');   // 返回: true
 * hasValue([]);   // 返回: false
 * hasValue(0);    // 返回: true
 **/
export const hasValue = (value) => {
    return value != null && !(Array.isArray(value) && value.length === 0);
};

/**
 * Determine if field is empty or filled.
 * Response determines if label is presented above field or as placeholder.
 * @param obj
 * @param SSR
 * @returns {boolean} False when not present or empty string.
 *                    True when any number or string with length.
 */
export const isFilled = (obj, SSR = false) => {
    return obj && ((hasValue(obj.value) && obj.value !== '') || (SSR && hasValue(obj.defaultValue) && obj.defaultValue !== ''));
};

/**
 * Determine if an Input is adorned on start.
 * It's corresponding to the left with LTR.
 * @param obj
 * @returns {boolean} False when no adornments.
 *                    True when adorned at the start.
 */
export const isAdornedStart = (obj) => {
    return obj.startAdornment;
};

/**
 * Determines if a given element is a DOM element name (i.e. not a React component).
 */
export const isHostComponent = (element) => {
    return typeof element === 'string';
};

function isObject(item) {
    return item !== null && typeof item === 'object' && !Array.isArray(item) && !(item instanceof Date) && !(item instanceof RegExp);
}

/**
 * 深層 Merge
 * 遞歸地將多個來源物件的屬性合併到目標物件中。
 * @param {Object} target - 目標物件，將被合併的對象。
 * @param {...Object} sources - 一個或多個來源物件，屬性將合併到目標物件中。
 * @returns {Object} - 返回合併後的目標物件。
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

/**
 * Safe chained function.
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 */
export const createChainedFunction = (...funcs) => {
    return funcs.reduce(
        (acc, func) => {
            if (func == null) {
                return acc;
            }
            return (...args) => {
                acc.apply(this, args);
                func.apply(this, args);
            };
        },

        () => {}
    );
};

/**
 * Corresponds to 10 frames at 60 Hz.
 * A few bytes payload overhead when lodash/debounce is ~3 kB and debounce ~300 B.
 */
export const debounce = (func, wait = 166) => {
    let timeout;
    const debounced = (...args) => {
        const later = () => {
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };

    debounced.clear = () => {
        clearTimeout(timeout);
    };
    return debounced;
};

/**
 * 獲取給定節點的文檔對象（`document`）。
 * 如果傳入的節點存在且有 `ownerDocument`，則返回該節點的 `ownerDocument`，
 * 否則返回當前的全局 `document`。
 *
 * @param {Node|null} node - 要檢查的節點，可以是 DOM 節點或 null。
 * @returns {Document} - 該節點的文檔對象（`ownerDocument`），或默認的全局 `document`。
 */
export const ownerDocument = (node) => {
    return (node && node.ownerDocument) || document;
};

/**
 * 獲取給定節點的窗口對象（`window`）。
 * 如果傳入的節點存在，則返回其文檔（`ownerDocument`）的 `defaultView`，
 * 否則返回全局的 `window`。
 *
 * @param {Node|null} node - 要檢查的節點，可以是 DOM 節點或 null。
 * @returns {Window} - 該節點的窗口對象（`defaultView`），或默認的全局 `window`。
 */
export const ownerWindow = (node) => {
    const doc = ownerDocument(node);
    return doc.defaultView || window;
};

export const getOffsetTop = (rect, vertical) => {
    let offset = 0;

    if (typeof vertical === 'number') {
        offset = vertical;
    } else if (vertical === 'center') {
        offset = rect.height / 2;
    } else if (vertical === 'bottom') {
        offset = rect.height;
    }
    return offset;
};

export const getOffsetLeft = (rect, horizontal) => {
    let offset = 0;

    if (typeof horizontal === 'number') {
        offset = horizontal;
    } else if (horizontal === 'center') {
        offset = rect.width / 2;
    } else if (horizontal === 'right') {
        offset = rect.width;
    }

    return offset;
};

/**
 * A change of the browser zoom change the scrollbar size.
 * Credit https://github.com/twbs/bootstrap/blob/488fd8afc535ca3a6ad4dc581f5e89217b6a36ac/js/src/util/scrollbar.js#L14-L18
 */
export const getScrollbarSize = (doc) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
    const documentWidth = doc.documentElement.clientWidth;
    return Math.abs(window.innerWidth - documentWidth);
};

/**
 * PropType 驗證函數，檢查指定的屬性是否為有效的 HTMLElement。
 *
 * @param {Object} props - 組件的所有屬性對象。
 * @param {string} propName - 要檢查的屬性名稱。
 * @param {string} componentName - 組件名稱，用於錯誤信息中。
 * @param {string} location - 屬性的位置（如 "prop", "context", "state"）。
 * @param {string} [propFullName] - 完整的屬性名稱，用於更具描述性的錯誤信息。
 * @returns {null|Error} - 如果驗證成功，返回 null；否則返回一個 Error 對象。
 */
export const HTMLElementType = (props, propName, componentName, location, propFullName) => {
    const propValue = props[propName];
    const safePropName = propFullName || propName;

    // 如果屬性為 null 或 undefined，驗證通過
    if (propValue == null) return null;

    // 驗證是否為有效的 HTMLElement
    if (propValue && (propValue?.nodeType ?? -1) !== 1) {
        return new Error(`Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. Expected an HTMLElement.`);
    }

    return null; // 驗證通過
};

/**
 * localStorage
 */
export const localUser = {
    get(target = 'user') {
        return JSON.parse(localStorage.getItem(target ? target : 'user') || null);
    },
    set(target) {
        return localStorage.setItem('user', JSON.stringify(target));
    },
    setEmployeeData(target) {
        return localStorage.setItem('employee', JSON.stringify(target));
    },
    setClubData(target) {
        return localStorage.setItem('user-club', JSON.stringify(target));
    },
    hasData() {
        return !!localStorage.getItem('user');
    },
    updateAttr(attr, value) {
        let user = this.get();
        user[attr] = value;
        this.set(user);
    },
    deleteEmployee() {
        localStorage.removeItem('employee');
    },
    logout() {
        localStorage.clear(); // 全部刪除
    },
};

/**
 * 解析 query string 並返回指定的參數值或所有參數對象。
 *
 * @param {string | string[]} name - 要提取的參數名稱，可以是單個參數名稱的字符串，或參數名稱的數組。
 * @param {string} url - 包含查詢字符串的 URL，例如 'google.com.tw/page?p1=111&p2=333' 或 '?p1=111&p2=333'。
 * @returns {object} - 返回包含指定參數值的對象，如果未指定參數名稱，則返回整個查詢字符串的對象。
 *
 * @example
 * // 提取單個參數
 * getUrlParameter('p1', '?p1=111&p2=333');
 * // 返回: { p1: '111' }
 * // 提取多個參數
 * getUrlParameter(['p1', 'p2'], '?p1=111&p2=333');
 * // 返回: { p1: '111', p2: '333' }
 * // 提取所有參數
 * getUrlParameter([], '?p1=111&p2=333');
 * // 返回: { p1: '111', p2: '333' }
 */
export const getUrlParameter = (name, url) => {
    let namePack = [];
    let queryObject = {};
    let regex = /[?&]([\w]+)=([^&#]*)/g;
    let i;

    // 確保 name 是字符串或字符串數組
    if (typeof name === 'string') {
        namePack.push(name);
    } else if (Array.isArray(name)) {
        namePack = [...name];
    } else {
        console.error(`First Argument can't be resolved. Please use a string or an array of strings.`);
        return {};
    }

    // 解析 URL 中的查詢字符串
    while ((i = regex.exec(url)) !== null) {
        queryObject[i[1]] = i[2];
    }

    // 返回指定參數或所有參數
    if (namePack.length !== 0) {
        return namePack.reduce(
            (acc, cur) => ({
                ...acc,
                [cur]: queryObject[cur],
            }),
            {}
        );
    } else {
        return queryObject;
    }
};

/**
 * 將物件轉換為 URL 查詢字符串 (query string)。
 *
 * @param {object} queryObject - 要轉換為查詢字符串的物件，其中鍵值對將被編碼為 `key=value` 格式。
 * @returns {string} - 返回一個查詢字符串，以 `?` 開頭，包含編碼後的鍵值對。
 *
 * @example
 * // 單個鍵值對
 * objectToQueryString({ key1: 'value1' });
 * // 返回: "?key1=value1"
 * // 多個鍵值對
 * objectToQueryString({ key1: 'value1', key2: 'value2' });
 * // 返回: "?key1=value1&key2=value2"
 * // 特殊字符的鍵值對
 * objectToQueryString({ name: 'John Doe', age: 30 });
 * // 返回: "?name=John%20Doe&age=30"
 */
export const objectToQueryString = (queryObject) => {
    return '?' + new URLSearchParams(queryObject).toString();
};

/**
 * 將查詢字符串 (query string) 轉換為物件。
 * 如果指定了數字型鍵值 (numKeys)，會將對應的值轉換為數字類型。
 *
 * @param {string} params - 包含查詢字符串的輸入，例如 "?key1=value1&key2=value2" 或 "key1=value1&key2=value2"。
 * @param {string[]} [numKeys=[]] - 一個包含需要轉為數字型的鍵名的陣列，例如 ["key1", "key2"]。
 * @returns {object} - 返回轉換後的物件，包含查詢字符串中的所有鍵值對。
 *
 * @example
 * // 基本使用
 * paramsToObject('?key1=value1&key2=value2');
 * // 返回: { key1: 'value1', key2: 'value2' }
 * // 帶有數字型鍵值的情況
 * paramsToObject('?key1=123&key2=value2', ['key1']);
 * // 返回: { key1: 123, key2: 'value2' }
 * // 無需 "?" 開頭的查詢字符串
 * paramsToObject('key1=value1&key2=456', ['key2']);
 * // 返回: { key1: 'value1', key2: 456 }
 */
export const paramsToObject = (params, numKeys = []) => {
    const entries = new URLSearchParams(params); // 解析查詢字符串
    const result = {};

    // 遍歷查詢字符串中的每個鍵值對
    for (const [key, value] of entries) {
        result[key] = numKeys.includes(key) && isNumber(value) ? parseInt(value, 10) : value;
    }

    return result; // 返回轉換後的結果
};

/**
 * 解析逗號分隔的字串(多選)為陣列。
 * @param {string} queryString - 要解析的輸入字串。
 * @returns {Array<string>} - 包含解析值的陣列。
 * @example
 * // 基本解析
 * parseQueryStringToArray("value1,value2,value3");
 * // 返回: ["value1", "value2", "value3"]
 * // 含有多餘空白
 * parseQueryStringToArray(" value1 , value2 ,value3 ");
 * // 返回: ["value1", "value2", "value3"]
 * // 空字串
 * parseQueryStringToArray("");
 * // 返回: []
 * // 無效輸入
 * parseQueryStringToArray(null);
 * // 返回: []
 */
export const parseQueryStringToArray = (queryString) => {
    if (!queryString || typeof queryString !== 'string') {
        return []; // 如果輸入無效，返回空陣列
    }
    return queryString
        .split(',') // 使用逗號分割字串
        .map((item) => item.trim()) // 去除每個元素的多餘空白
        .filter((item) => item); // 過濾掉空值
};

/**
 * 將數字格式化為金錢顯示方式。
 * 將數字轉換為千分位分隔的格式，並可選擇添加貨幣符號或指定小數位數。
 *
 * @param {number|string} target - 要格式化的數字，支持數字或可解析為數字的字符串。
 * @param {object} [options={}] - 可選參數，用於指定格式化選項。
 * @param {string} [options.symbol=''] - 金錢符號，會加在格式化數字的前面，例如 `$` 或 `€`。
 * @param {number} [options.point=0] - 小數位數，指定要保留的小數位。
 * @returns {string} - 格式化後的金錢字符串，或者空字符串（如果輸入無效）。
 *
 * @example
 * // 基本格式化
 * formatCurrencyFn(1000000);
 * // 格式化帶小數位數
 * formatCurrencyFn(12345.678, { point: 2 });
 * // 返回: "12,345.68"
 * formatCurrencyFn(1000, { symbol: '$' });
 * // 返回: "$1,000"
 * formatCurrencyFn('invalid number');
 * // 返回: ""
 */
export const formatCurrencyFn = (target, { symbol = '', point = 0 } = {}) => {
    // 嘗試將輸入轉換為數字
    let num = typeof target === 'number' ? target : Number(target);

    // 檢查輸入是否為有效數字
    if (Number.isNaN(num)) {
        return ''; // 返回空字符串表示無效輸入
    }

    // 修正 -0 的情況，統一為正 0
    if (Object.is(num, -0)) {
        num = 0;
    }

    // 格式化為千分位並處理小數位數
    const formattedNumber = num.toLocaleString('en-US', {
        minimumFractionDigits: point,
        maximumFractionDigits: point,
    });

    // 添加金錢符號（如果指定）
    return symbol ? `${symbol}${formattedNumber}` : formattedNumber;
};

/**
 * 檢查是否為必填，拋出必填訊息
 * @params {...ReactRef}
 * @reutrns {void}
 */
export const refIsRequiredError = (...args) => {
    const chkReq = args.reduce((acc, cur) => {
        let newAcc = acc;
        if (cur && cur.current && cur.current.isError) {
            const err = cur.current.isError();
            if (!newAcc) newAcc = err;
        } else if (cur && cur.isError) {
            const err = cur.isError();
            if (!newAcc) newAcc = err;
        } else if (cur && cur.current) {
            const err = refIsRequiredError(...Object.values(cur.current));
            if (!newAcc) newAcc = err;
        }
        return newAcc;
    }, false);
    return chkReq;
};

/**
 * 將 `ownerState` 對象附加到 `existingProps`，如果已存在，則進行合併。
 * @param {string|React.ComponentType} elementType - 元素的類型。如果是 DOM 節點，`ownerState` 不會被應用。
 * @param {object} existingProps - 元素的現有屬性對象。
 * @param {object} ownerState - 要附加到屬性中的狀態對象。
 * @returns {object} - 合併後的屬性對象。如果是 DOM 節點，則返回原始的 `existingProps`。
 */
export const appendOwnerState = (elementType, existingProps, ownerState) => {
    if (isHostComponent(elementType)) return existingProps;

    return {
        ...existingProps,
        ownerState: { ...existingProps.ownerState, ...ownerState },
    };
};

/**
 * 比較兩個陣列，找到匹配的對象並提取指定的值。
 * 根據 `sourceArray` 中對象的 `_getValue` 屬性，與 `_corrArray` 的值進行比較，
 * 如果匹配成功，提取對應的 `_getKey` 屬性值，並以逗號分隔的字符串形式返回。
 * @param {Array} _corrArray - 比較的目標陣列，其中的值將與 `sourceArray` 中的 `_getValue` 屬性匹配。
 * @param {Array} sourceArray - 包含對象的參考陣列，用於查找和匹配值。
 * @param {string} _getValue - 要匹配的屬性名稱（key），用於對比 `sourceArray` 對象的值。
 * @param {string} _getKey - 要提取的屬性名稱（key），匹配成功後提取其值。
 * @returns {string} - 匹配的 `_getKey` 屬性值組成的逗號分隔字符串。如果無匹配，返回空字符串。
 *
 * @example
 * // 假設 sourceArray 為以下結構：
 * const sourceArray = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 3, name: 'Charlie' },
 * ];
 *
 * const _corrArray = [1, 3];
 *
 * arrayIntersection(_corrArray, sourceArray, 'id', 'name');
 * // 返回: "Alice,Charlie"
 *
 * arrayIntersection([], sourceArray, 'id', 'name');
 * // 返回: ""
 */
export const arrayIntersection = (_corrArray = [], sourceArray = [], _getValue, _getKey) => {
    const cloneSource = _.cloneDeep(sourceArray);

    return cloneSource
        .reduce((acc, cur) => {
            if (_corrArray.some((v) => String(v) === String(cur[_getValue]))) {
                acc.push(cur[_getKey]);
            }
            return acc;
        }, [])
        .join(',');
};

/**
 * @param {string} targetString
 * @returns {array}
 */
export const stringToArray = (targetString) => {
    if (typeof targetString === 'string') return [targetString];
    else return targetString;
};

/**
 * 找出val在targetArray裡位於第幾個索引
 * @param {array} targetArray
 * @param {string} targetKey
 * @param {string} targetValue
 * @returns {number}
 */
export const findIndexFn = (targetArray = [], targetKey, targetValue) => {
    if (!Array.isArray(targetArray)) {
        console.error('Your first value is not Array');
        return -1;
    }
    return targetArray.findIndex((targetItem) => targetItem && String(targetItem[targetKey]) === String(targetValue));
};

/**
 * 顯示 option text
 * @param {[{ value: string}] | array} options
 * @param {string} value
 * @param {string} defaultVal
 * @param {string} keyName
 * @returns String
 */
export const matchOptionsText = (options = [], value = '', defaultVal = '查無資料', keyName = 'text') => {
    return options.find((item) => String(item.value) === String(value))?.[keyName] ?? defaultVal;
};

/**
 * 複製文字
 */
export const textCopypaste = (_text) => {
    const selection = window.getSelection();
    const _span = document.createElement('span');
    const range = document.createRange();
    selection.removeAllRanges();
    _span.innerHTML = _text;
    document.body.appendChild(_span);
    range.selectNodeContents(_span);
    selection.addRange(range);
    document.execCommand('Copy');
    selection.removeAllRanges();
    document.body.removeChild(_span);
};

/**
 * 彈出連結下載
 */
export const linkDownload = (_href) => {
    window.open(_href);
};

/**
 * 物件 轉 QueryString
 */
export const serialize = (target, uesURLSearchParams = true) => {
    if (uesURLSearchParams && URLSearchParams) return new URLSearchParams(target).toString();
    let strAry = [];
    for (let _i in target) {
        if (target.hasOwnProperty(_i)) {
            if (Array.isArray(target[_i])) {
                target[_i].forEach(value => strAry.push(encodeURIComponent(_i) + '=' + encodeURIComponent(value)));
            } else {
                strAry.push(encodeURIComponent(_i) + '=' + encodeURIComponent(target[_i]));
            }
        }
    }

    return strAry.join('&');
};

/**
 * 合併兩個陣列，將次要陣列的屬性欄位新增到主要陣列的對應項目中。
 *
 * @param {Array<object>} mainArray - 主要陣列。
 * @param {Array<object>} minorArray - 次要陣列。
 * @param {string|[string, string]} equalValue - 用於匹配主要和次要陣列的鍵名。如果是數組，第一個為主要鍵名，第二個為次要鍵名。
 * @param {string|string[]} addMinorKeyNames - 從次要陣列中要合併的鍵名，可以是單個鍵名或鍵名的數組。
 * @returns {Array<object>} - 合併後的主要陣列。
 *
 * @example
 * const mainArray = [{ id: 1, name: 'A' }, { id: 2, name: 'B' }];
 * const minorArray = [{ refId: 1, age: 30 }, { refId: 2, age: 25 }];
 * const result = mergeArrayByProperty(mainArray, minorArray, ['id', 'refId'], ['age']);
 * // 返回: [{ id: 1, name: 'A', age: 30 }, { id: 2, name: 'B', age: 25 }]
 */
export const mergeArrayByProperty = (mainArray = [], minorArray = [], equalValue, addMinorKeyNames) => {
    // 確保 addMinorKeyNames 為數組
    const keyNames = Array.isArray(addMinorKeyNames) ? addMinorKeyNames : [addMinorKeyNames];

    // 如果 equalValue 是單個鍵名，將其轉換為數組結構
    const [mainKey, minorKey] = Array.isArray(equalValue) ? equalValue : [equalValue, equalValue];

    // 合併陣列
    return mainArray.map((mainItem) => {
        // 在次要陣列中找到匹配的項目
        const matchingMinor = minorArray.find((minorItem) => String(mainItem[mainKey]) === String(minorItem[minorKey]));

        // 如果匹配成功，提取次要陣列的屬性並合併
        if (matchingMinor) {
            const newProperties = keyNames.reduce((acc, key) => {
                acc[key] = matchingMinor[key];
                return acc;
            }, {});
            return { ...mainItem, ...newProperties };
        }

        // 沒有匹配時，返回原始主要項目
        return mainItem;
    });
};

/**
 * 打平巢狀陣列 (多維度打成一維度)，擷取所有與 `selectKey` 相關的索引巢狀資料。
 *
 * @param {Array<object>} sourceList - 要處理的巢狀陣列。
 * @param {string} [selectKey='children'] - 用於標識子陣列的鍵名，預設為 'children'。
 * @returns {Array<object>} - 打平後的一維陣列，包含所有巢狀結構中的物件。
 *
 * @example
 * const data = [
 *   { id: 1, children: [{ id: 2, children: [{ id: 3 }] }] },
 *   { id: 4 },
 * ];
 * flatDeepArray(data);
 * // 返回: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
 * const data = [
 *   { id: 'a', nested: [{ id: 'b', nested: [{ id: 'c' }] }] },
 * ];
 * flatDeepArray(data, 'nested');
 * // 返回: [{ id: 'a' }, { id: 'b' }, { id: 'c' }]
 */
export const flatDeepArray = (sourceList, selectKey = 'children') => {
    // 定義遞歸展平函數
    const deepArrayFn = (item) => {
        const { [selectKey]: children, ...rest } = item; // 拆解出子陣列與其他屬性
        return children && Array.isArray(children)
            ? [rest, ...children.flatMap(deepArrayFn)] // 有子陣列時遞歸展開
            : [rest]; // 沒有子陣列直接返回
    };

    // 使用 flatMap 展開頂層陣列，並遞歸處理
    return sourceList.flatMap(deepArrayFn);
};

export const getSelectOptions = (options, key, values) => {
    let valuePack;
    if (!values) return options;
    if (typeof values === 'string') valuePack = [values];
    else valuePack = [...values];
    return options.filter((target) => {
        return valuePack.indexOf(target[key]) !== -1;
    });
};

/**
 * 標準化字符處理
 * @param {string} str
 */
function stripDiacritics(str) {
    return typeof str.normalize !== 'undefined' ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : str;
}

/**
 * AutoComplete Filter use
 */
export const createFilterOptions = (config = {}) => {
    const { ignoreAccents = true, ignoreCase = true, limit, matchFrom = 'any', stringify, trim = false } = config;

    return (options, { inputValue, getOptionLabel }) => {
        let input = trim ? inputValue.trim() : inputValue;
        if (ignoreCase) {
            input = input.toLowerCase();
        }
        if (ignoreAccents) {
            input = stripDiacritics(input);
        }

        const filteredOptions = options.filter((option) => {
            let candidate = (stringify || getOptionLabel)(option);
            if (ignoreCase) {
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                candidate = stripDiacritics(candidate);
            }

            return matchFrom === 'start' ? candidate.indexOf(input) === 0 : candidate.indexOf(input) > -1;
        });

        return typeof limit === 'number' ? filteredOptions.slice(0, limit) : filteredOptions;
    };
};

/**
 * 顯示累積月/日 文字
 * @param {string | number} month
 * @param {string | number} days
 * @param {string} defaultText
 * @return {string}
 * example (1,6) -> 1個月6天
 */
export const displayDateLength = (month, days, defaultText = '無相關資料') => {
    if (!month && !days) return '';
    let text = '';
    if (month) text = text + month + '個月';
    if (month && days) text = text + '又';
    if (days) text = text + days + '天';
    if (!month && !days) text = defaultText;
    return text;
};

/**
 * 匹配router內的參數,引用第二參數內的key取代掉為value
 * @param {string} pathStr
 * @param {object} targetData
 * @returns {string}
 * example
 *  first Params '/doc/membershipt/:people?/:memberID/overview'
 *  second Params { people:10, memberID:2 }
 *  result -> '/doc/membershipt/10/2/overview'
 */
export const pathRouterShit = (pathStr, targetData) => {
    const replaceKeyToValue = (targetString, source) => {
        const targetKeys = Object.keys(source);
        const newValue = targetKeys.reduce((acc, cur) => {
            let newTargetData = { ...acc };
            if (targetString.indexOf(`:${cur}`) !== -1) {
                newTargetData = { value: source[cur] };
            }
            return newTargetData;
        }, {});
        return newValue['value'];
    };

    let newPath = pathStr;
    newPath = newPath.split('/').map((s) => {
        return s.charAt() !== ':' ? s : replaceKeyToValue(s, targetData);
    });
    return newPath.join('/');
};

/**
 * 轉換字串為布林值
 * @param {string} targetString
 * @return {boolean}
 */
export const parseBoolean = (targetString) => {
    if (!targetString) return !targetString;
    else {
        const tmpStr = String(targetString);
        if (tmpStr === 'true') return true;
        else if (tmpStr === 'false') return false;
        else return !!tmpStr;
    }
};

/**
 * 提取radio獨立值
 * @param {array} radioResultOptions
 * @param {string} targetKey
 * @param {any} defaultValue
 * @returns {any}
 */
export const getRadioFieldResult = (radioResultOptions, targetKey, defaultValue = 0) => {
    return radioResultOptions.find(({ checked }) => checked)?.[targetKey] ?? defaultValue;
};

/**
 * 取得小數後幾位
 * @param {number} num
 * @param {number} fixed
 * @returns {number}
 */

export const toFixed = (num, fixed = 1) => {
    if (!isNaN(num)) return Math.round(num * 10 ** fixed) / 10 ** fixed;
    return num;
};

/**
 * 小數轉百分比
 * @param {number} num
 * @returns {number}
 */
export const floatToPercentage = (num, config = {}) => {
    const { fixed = 1 } = config;
    if (!isNaN(num)) return toFixed(num * 100, fixed);
    return num;
};

/**
 * 百分比轉小數
 * @param {number} num
 * @returns {number}
 */

export const percentageToFloat = (num, config = {}) => {
    const { fixed = 4 } = config;
    let _fixed = fixed - 2;
    if (!isNaN(num)) return toFixed(num, _fixed) / 100;
    return num;
};

/**
 * 處理Component裡的錯誤訊息
 * @param {object} target
 * @param {string} keyName
 * @returns {object}
 */
export const getErrorModuleStatus = (target, keyName) => {
    let targetKey;
    if (_.isEmpty(target) || isEmpty(keyName)) {
        // console.error('Your Object is empty');
        return {};
    } else {
        targetKey = target[keyName];
    }

    if (!targetKey) return {};

    const errorProperty = {
        isError: !!targetKey,
        helperText: !!targetKey && (target[keyName]?.message ?? target[keyName]),
    };

    return errorProperty;
};

/**
 * 合併聯繫與簡訊清單資料
 * @param {array} contactList
 * @param {array} smsList
 * @returns {array}
 */
export const mergeContactAndSMSData = (contactList = [], smsList = []) => {
    let targetList = [
        ...contactList.map((p) => {
            p.key = p.contactID;
            p.sortNum = moment(p.contactDate);
            return p;
        }),
        ...smsList.map((p) => {
            p.isSMS = true;
            p.key = p.smsID;
            p.sortNum = moment(p.sendDate);
            return p;
        }),
    ];
    return _.orderBy(targetList, ['sortNum'], ['desc']);
};

/**
 * 計算字數, 點數, 判斷長短簡訊
 * @param {messageText} text
 * @returns {{ remainder: number , point: number }}
 */
export const messageCounts = (messageText = '') => {
    let isLongAuthority = 'oooxxxxxxxxxxxxxxxxxxxxoxxxxxxxx'.substring(0, 1) === 'o' ? true : false;
    let message = _.trim(messageText);
    var isChinese = checkChinese(message);
    let beforeReplaceAll = message;
    let afterReplaceAll = beforeReplaceAll.replace(/\r\n/g, '\n');
    let limit = 0;
    let remainder;
    let point = 1;

    isChinese ? (limit = 70) : (limit = 160);

    if (isLongAuthority) {
        //計算長度
        remainder = afterReplaceAll.length - stringFilterTrim(afterReplaceAll);
        point = smsCounts(afterReplaceAll.substring(0, afterReplaceAll.length - stringFilterTrim(afterReplaceAll)));
    } else {
        //計算長度
        remainder = limit - (afterReplaceAll.length - stringFilterTrim(afterReplaceAll));
    }
    return { remainder, point };
};

/**
 * 計算簡訊點數及判斷長短簡訊
 * @param {string} text
 * @returns {number}
 */
export const smsCounts = (text) => {
    // let isLongAuthority = 'oooxxxxxxxxxxxxxxxxxxxxoxxxxxxxx'.substring(0, 1) === 'o' ? true : false;
    let counts = 0;
    let xLen = 0;
    let xWide = '';
    let longMsg = false;
    xLen = text.length;

    //當含有中文字且文字長度超過70為長簡訊 ,非中文長度超過160為長簡訊
    if (xLen > 0) {
        if (checkChinese(text) && xLen > 70) {
            longMsg = true;
        } else if (!checkChinese(xWide) && xLen > 160) {
            longMsg = true;
        } else {
            longMsg = false;
        }
    }

    //長簡訊判斷
    if (text.length <= 70) {
        counts = 1;
    } else if (checkChinese(text) && text.length > 70) {
        while (xLen > 0) {
            if (longMsg) {
                xWide = text.substring(0, 67);
            } else {
                xWide = text.substring(0, 160);
            }

            while (xWide.length > 67) {
                if (checkChinese(xWide)) {
                    //是中文字且文字長度是68,69,70則不刪字(非長簡訊)
                    if (!longMsg && xWide.length() > 67 && xWide.length() < 71) {
                        break;
                    } else if (longMsg) {
                        xWide = xWide.substring(0, xWide.length() - 1);
                    }
                }
            }
            if (xWide.length > 0) {
                text = text.substring(xWide.length);
                counts++;
            }
            xLen = text.length;
        }
    } else if (!checkChinese(text)) {
        while (xLen > 0) {
            if (longMsg) {
                xWide = text.substring(0, 153);
            } else {
                xWide = text.substring(0, 160);
            }

            while (xWide.length > 160) {
                if (!checkChinese(xWide)) {
                    //是純英數字且文字長度是154~160則不刪字(非長簡訊)
                    if (!longMsg && xWide.length() > 153 && xWide.length() < 161) {
                        break;
                    } else if (longMsg) {
                        xWide = xWide.substring(0, xWide.length() - 1);
                    }
                }
            }
            if (xWide.length > 0) {
                text = text.substring(xWide.length);
                counts++;
            }
            xLen = text.length;
        }
    }
    return counts;
};

/**
 * 判斷是否有中文
 * @param {string} text
 * @returns boolean
 */
export const checkChinese = (text) => {
    let textLength = text.length;
    text = encodeURIComponent(text);
    const chineseLength = text.replace(/%[A-F\d]{2}/g, 'U').length;

    if (textLength !== chineseLength) {
        return true;
    }

    return false;
};

/**
 * 過濾訊息結尾的空白與斷行，不予計算字數。
 * @param {string} text
 * @returns number
 */
export const stringFilterTrim = (text) => {
    let deductNum = 0;
    for (let i = text.length - 1; i >= 0; i--) {
        if (text.charAt(i) === '\u0020' || text.charAt(i) === '\n') {
            deductNum++;
        } else {
            break;
        }
    }
    return deductNum;
};

/**
 * 判斷是否為安著
 * @returns {boolean}
 */
export const getIsAndroid = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isMobile = /Mobile/i.test(navigator.userAgent);
    return isAndroid || isMobile;
};

/**
 * 如果是安著系統,使用google去開啟PDF
 * @param {string} url
 * @returns {string}
 */
export const pdfPath = (url) => {
    const googleUrl = 'https://docs.google.com/viewerng/viewer?url=';
    let path = url;
    if (getIsAndroid()) {
        if (/^Http/i.test(url)) {
            path = `${googleUrl}${url}`;
        } else {
            const { origin } = window.location;
            path = `${googleUrl}${origin}${url}`;
        }
    }
    return path;
};

/**
 * 隨機亂碼
 * @param {number} max
 * @returns {number}
 */
export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};

/**
 * 隨機亂碼字串
 * @param {number} length
 * @returns {string}
 */
export const generateRandomString = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(array[i] % charactersLength);
    }

    return result;
};

/**
 * 檢查elemet是否有勾選
 * @param {object} targetArray
 * @param {object} targetData
 * @returns {array}
 */
export const inputCheckedAction = (targetArray, targetData, filterKey) =>
    targetArray.reduce((acc, cur) => {
        const _index = findIndexFn(targetData, filterKey, cur.value); // todo
        let newAcc = [...acc];
        if (cur.checked && _index !== -1) newAcc.push(targetData[_index]);
        return newAcc;
    }, []);

/**
 * 是否為正確的信用卡卡號
 * @param {string} value
 * @returns {boolean}
 */
export const isCorrectCardNo = (value) => {
    // ^(?:4[0-9]{12}(?:[0-9]{3})?          # Visa
    //  |  5[1-5][0-9]{14}                  # MasterCard
    //  |  (?:2131|1800|35\d{3})\d{11}      # JCB
    // )$
    const reg = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(?:2131|1800|35\d{3})\d{11})$/gm;
    return reg.test(value);
};

/**
 * 是否為正確的手機格式 (09開頭)
 * @param {string} value
 * @returns {boolean}
 */
export const isMobileNumber = (value) => {
    const reg = /^(09)[0-9]{8}$/gm;
    return reg.test(value);
};

/**
 * 是否為統編長度 8碼數字
 * @param {string} value
 * @returns {boolean}
 */
export const isTaxIDLength = (value) => {
    const reg = /^[0-9]{8}$/gm;
    return reg.test(value);
};

/**
 * 是否為統編規則
 * @param {string} taxID
 * @returns {boolean}
 */
export const isCorrectTaxID = (taxID) => {
    const logicalMultiplier = [1, 2, 1, 2, 1, 2, 4, 1]; // 邏輯乘績
    const _tax = taxID.split('');
    const length = 8,
        checkNumber = 5;
    let sum = 0;

    for (let i = 0; i < length; i++) {
        let p = logicalMultiplier[i] * parseInt(_tax[i], 10);
        let s = p / 10 + (p % 10);
        sum += s;
    }

    // 當統一編號第7位數為7時，乘積之和最後第二位數取0，或1均可，其中之一 『和』能被整除。
    return sum % checkNumber === 0 || (sum + (1 % checkNumber) === 0 && String(_tax[6]) === '7');
};

/**
 * 是否為自然人憑證 (2碼英文字母加上14碼數字)
 * @param {string} value
 * @returns {boolean}
 */
export const isCitizenDigitalCertificate = (value) => {
    const reg = /^[a-z][A-Z]{2}[0-9]{14}$/gm;
    return reg.test(value);
};

/**
 * 是否為手機條碼 (斜線(/)加上7碼數字或大寫字母)
 * @param {string} value
 * @returns {boolean}
 */
export const isMobileBarcode = (value) => {
    // 第1碼為【/】;
    // 其餘7碼則由數字【0-9】大寫英文【A-Z】與特殊符號【+】【-】【.】
    const reg = /^\/[0-9A-Za-z.+-]{7}$/gm;
    return reg.test(value);
};

/**
 * 檢查值是否為一般物件。
 * @param {*} value - 要檢查的值。
 * @returns {boolean} - 如果值為一般物件則為 true，否則為 false。
 */
export const isPlainObject = (value) => {
    return typeof value === 'object' && value !== null && value.constructor === Object;
};

/**
 * 檢查值是否為一般物件。
 * @param {array} targetArray
 * @param {string} keyName
 * @param {string} valueName
 * @returns {Object} - 如果值為一般物件則為 true，否則為 false。
 */
export const toHash = (targetArray, keyName, valueName) => {
    if (!Array.isArray(targetArray)) return {};
    return targetArray.reduce(function (dictionary, next) {
        dictionary[next[keyName]] = next[valueName];
        return dictionary;
    }, {});
};
