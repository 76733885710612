import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@common/components/';
import InteractiveContentWrapper from '@icoach/components/InteractiveContentWrapper';

export const TitleByTooltip = ({ title, tip }) => {
    const Title = ({ title }) => <Box className="counter-box-title">{title}</Box>;
    if (Boolean(tip)) return <InteractiveContentWrapper tip={tip} alignItems={'start'} label={<Title title={title} />} iconSize={'20px'} />;
    return <Title title={title} />;
};

const CounterBox = (props) => {
    const { className, title, counter = 0, tip, onClick, children } = props;
    return (
        <div className={clsx('counter-box', className)} onClick={onClick}>
            <TitleByTooltip title={title} tip={tip} />
            <Typography variant="h6" className="counter-box-number">
                {counter}
            </Typography>
            {children}
        </div>
    );
};

export default CounterBox;
