import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router';
import { find } from 'lodash';
import { Grid } from '@mui/material';
import { Button, DateField, Form, MenuItem, SelectField, Card } from '@common/components/';
import { SetTargetSearchBar } from '@icoach/accountBook/components/';
import { InvoiceByTriplicateUniform, InvoiceByEmail } from '@icoach/components/Invoice';
import CheckoutRemitCart from '@icoach/accountBook/components/CheckoutRemitCart';
import useClubRemittanceApi from '@apis/useClubRemittanceApi';
import { refIsRequiredError } from '@util/utils';
import { getToday } from '@util/moment';
import useMessageDialog from '@util/hook/useMessageDialog';
import { ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import { checkoutDateMessageConfig, isBeforeOfCurrentMonth, receiverTypeMap } from '@icoach/accountBook/staticData';
import PageLayout from '@icoach/components/layout/PageLayout';

const CheckOutContent = React.forwardRef((props, ref) => {
    const { resource, getReceiver } = props;
    const { taxTitle, taxID, issuingEmail } = resource || {};
    const history = useHistory();
    const taxRef = useRef(null);
    const checkoutRemitCartRef = useRef(null);

    const handleCancel = () => {
        history.push(ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL);
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let result = {};

            if (checkoutRemitCartRef && checkoutRemitCartRef.current && checkoutRemitCartRef.current.getResult) {
                result = Object.assign(result, checkoutRemitCartRef.current.getResult());
            }

            if (taxRef && taxRef.current && taxRef.current.getResult) {
                result = Object.assign(result, taxRef.current.getResult());
            }

            return result;
        },
        isError: () => refIsRequiredError(checkoutRemitCartRef),
    }));

    return (
        <React.Fragment>
            <Grid xs={12} item>
                <CheckoutRemitCart className={'mb-1'} ref={checkoutRemitCartRef} resource={resource} getReceiver={getReceiver} />
            </Grid>
            <Grid xs={6} item>
                <Card>
                    <Card.CardContent className="p-4">
                        <InvoiceByTriplicateUniform ref={taxRef} sourceData={{ taxTitle: taxTitle, taxID: taxID }} />
                        <Grid item xs={12}>
                            <InvoiceByEmail defaultValue={issuingEmail} />
                        </Grid>
                    </Card.CardContent>
                </Card>
            </Grid>
            <Grid xs={6} item>
                <div className="btn-group text-right mt-4">
                    <Button variant={'outlined'} onClick={handleCancel}>
                        取消，回列表
                    </Button>
                    <Button type={'submit'} variant={'contained'}>
                        確認結帳
                    </Button>
                </div>
            </Grid>
        </React.Fragment>
    );
});

// 新增匯款
const AccountBookCreateRemitPage = () => {
    const { getRemittanceOptionsApi, postRemittanceByClubApi, postRemittanceByHeadquarterApi } = useClubRemittanceApi();
    const history = useHistory();
    const setMessageDialog = useMessageDialog();
    const [resource, setResource] = useState({});
    const [invoiceMemo, setInvoiceMemo] = useState({});
    const { coachOptions: employeeOptions = [] } = resource;
    const [isShowContent, setShowContent] = useState(false);
    const employeeRef = useRef(null);
    const orderDateRef = useRef(null);
    const searchBarRef = useRef(null);
    const checkoutRef = useRef(null);
    const today = getToday();

    const getParams = () => {
        let params = {
            employeeID: employeeRef.current.getResult(),
            orderDate: orderDateRef.current.getResult(),
            ...searchBarRef.current.getResult(),
            ...checkoutRef.current.getResult(),
        };
        return params;
    };

    const checkError = () => {
        let isError = false;
        // 檢核建立日期是否在當月之前
        if (orderDateRef.current && orderDateRef.current.getResult) {
            let orderDate = orderDateRef.current.getResult();
            if (!isError) {
                isError = isBeforeOfCurrentMonth(orderDate);
                if (isError) setMessageDialog(checkoutDateMessageConfig);
            }
        }

        if (!isError) {
            isError = refIsRequiredError(employeeRef, orderDateRef, employeeRef, searchBarRef, checkoutRef);
        }
        return isError;
    };

    const handleClickCheckout = () => {
        let params = getParams();
        let isError = checkError();
        if (!isError) {
            doRemittanceApi(params);
        }
    };

    const handleChangeDate = (d, v) => {
        if (isBeforeOfCurrentMonth(v)) {
            setMessageDialog(checkoutDateMessageConfig);
        }
    };

    const doOptionsApi = async (setResource) => {
        let resp = await getRemittanceOptionsApi();
        if (resp) {
            setResource(resp);
        }
    };

    const doRemittanceApi = async (params) => {
        let receiver = searchBarRef.current.getReceiver();
        let resp;
        if (String(receiver) === receiverTypeMap.club) {
            resp = await postRemittanceByClubApi(params);
        } else if (String(receiver) === receiverTypeMap.headquarters) {
            resp = await postRemittanceByHeadquarterApi(params);
        }
        resp && history.replace(ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL);
    };

    const doConfirmRemitApi = async (clubID) => {
        const { clubTaxOptions } = resource;
        const clubTaxInfo = find(clubTaxOptions, ['clubID', parseInt(clubID)]);
        if (clubTaxInfo) {
            setInvoiceMemo(clubTaxInfo);
        }
    };

    useEffect(
        () => {
            doOptionsApi(setResource);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <PageLayout>
            <PageLayout.Header title={'新增匯款'} />
            <Form onSubmit={handleClickCheckout}>
                <Grid spacing={3} container>
                    <Grid xs={3} item>
                        <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                            {Array.isArray(employeeOptions) &&
                                employeeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={3} item>
                        <DateField label={'建立日期'} ref={orderDateRef} defaultValue={today} onChange={handleChangeDate} fullWidth />
                    </Grid>
                    <Grid xs={12} item>
                        <SetTargetSearchBar
                            className={'mb-1'}
                            title={'匯款對象'}
                            ref={searchBarRef}
                            resource={resource}
                            isShowContent={isShowContent}
                            setShowContent={setShowContent}
                            doConfirmRemitApi={doConfirmRemitApi}
                        />
                    </Grid>
                    {isShowContent && (
                        <CheckOutContent ref={checkoutRef} resource={Object.assign({}, resource, invoiceMemo)} getReceiver={searchBarRef.current.getReceiver} />
                    )}
                </Grid>
            </Form>
        </PageLayout>
    );
};

export default AccountBookCreateRemitPage;
