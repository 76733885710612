import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@common/components/';
import { isNumber } from '@util/utils';

const ProductDetails = ({
    className,
    productName,
    sku = '',
    spec = '',
    // specStatus, // 未設想多規格的狀況
    stock,
    price, // 商品定價
    salePrice, // 商品售價
    note,
    ...rest
}) => {
    return (
        <Box className={clsx('product-details', className)} {...rest}>
            {sku && <Box className="product-details__sku">{sku}</Box>}
            <Box className="product-details__name">{productName}</Box>
            {spec && <Box className="product-details__spec">規格：{spec}</Box>}
            {isNumber(stock) && <Box className="product-details__stock">庫存：{stock}</Box>}
            {note && <Box className="product-details__note">{note}</Box>}
            {isNumber(salePrice) && (
                <Box className="product-details__price">
                    <Box className="product-details__sale-price">
                        <span className={'symbol'}>$</span>
                        {salePrice}
                    </Box>
                    {isNumber(price) && <Box className="product-details__price--original">{`定價：$${price}`}</Box>}
                </Box>
            )}
        </Box>
    );
};

ProductDetails.prototype = {
    className: PropTypes.string,
    productName: PropTypes.string.isRequired,
    sku: PropTypes.string,
    spec: PropTypes.string,
    // specStatus: PropTypes.string,
    stock: PropTypes.number,
    price: PropTypes.number,
    salePrice: PropTypes.number,
    note: PropTypes.string,
};

export default ProductDetails;
