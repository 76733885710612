import React, { createContext, useContext } from 'react';

const OverviewContext = createContext(undefined);

export const OverviewProvider = ({ children, value }) => {
    return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>;
};

export const useOverviewContext = () => {
    const context = useContext(OverviewContext);
    if (context === undefined) {
        throw new Error('useOverviewContext 必須在 OverviewProvider 中使用');
    }
    return context;
};
