import { combineReducers } from 'redux';
import { ENABLE_INACTIVITY_TIMER, DISABLE_INACTIVITY_TIMER } from './types';

const initialState = {
    isEnabled: true, // 預設啟用計時器
};

export const InactivityTimerData = (state = initialState, action) => {
    switch (action.type) {
        case ENABLE_INACTIVITY_TIMER:
            return { ...state, isEnabled: true };
        case DISABLE_INACTIVITY_TIMER:
            return { ...state, isEnabled: false };
        default:
            return state;
    }
};

const Reducer = combineReducers({
    InactivityTimerData,
});

export default Reducer;
