import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import { getMemberSheet, rowLabelCheckboxSheet } from '@icoach/components/';
import { DateField, SelectField, MenuItem } from '@common/components/';
import { paramsToObject, refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import { useHistory } from 'react-router-dom';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';

// 會員成果查詢
export const FilterMemberComparisonSearch = React.forwardRef((props, ref) => {
    const { resource, paramsData } = props;
    const { measureResultTypeOption = [] } = resource || {};
    const { firstDate, secondDate, measureType } = paramsData || {};
    const date1Ref = useRef(null);
    const date2Ref = useRef(null);
    const inbodyItemRef = useRef(null);

    const getResult = () => {
        let result = {};

        if (date1Ref.current && date1Ref.current.getResult) {
            Object.assign(result, { firstDate: parseDate(date1Ref.current.getResult(), 'YYYY-MM') });
        }

        if (date2Ref.current && date2Ref.current.getResult) {
            Object.assign(result, { secondDate: parseDate(date2Ref.current.getResult(), 'YYYY-MM') });
        }

        if (inbodyItemRef.current && inbodyItemRef.current.getResult) {
            Object.assign(result, { measureType: inbodyItemRef.current.getResult() });
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(date1Ref, date2Ref),
                getResult,
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <DateField
                    ref={date1Ref}
                    defaultValue={firstDate}
                    label={'月份1'}
                    views={['year', 'month']}
                    openTo={'month'}
                    format={'YYYY/MM'}
                    mask={'____/__'}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <DateField
                    ref={date2Ref}
                    defaultValue={secondDate}
                    label={'月份2'}
                    views={['year', 'month']}
                    openTo={'month'}
                    format={'YYYY/MM'}
                    mask={'____/__'}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <SelectField ref={inbodyItemRef} label={'量身項目'} defaultValue={measureType} required fullWidth>
                    {measureResultTypeOption.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

const MeasureTypeResultLabel = (params) => {
    const { resource } = useInBodyContext();
    const { measureResultTypeOption = [] } = resource || {};
    const history = useHistory();
    const search = history.location.search;
    const { measureType } = paramsToObject(search);
    const measureResultTypeData = measureResultTypeOption.find((item) => String(item.value) === String(measureType));

    let label = '成果';
    if (measureResultTypeData) {
        label = measureResultTypeData.text + label;
    }
    return label;
};
// 會員成果查詢
export const getFilterMemberComparisonLabel = (isRevealed) => {
    return [
        {
            ...rowLabelCheckboxSheet,
            cellKey: 'memberID',
        },
        { ...getMemberSheet(isRevealed), isFixed: true, isFixedLeft: true },
        {
            cellKey: 'measureTypeResultForFirstDate',
            headerLabel: '月份1',
            align: 'center',
            formatCell: (value) => {
                if (!value) return '無';
                return value;
            },
        },
        {
            cellKey: 'measureTypeResultForSecondDate',
            headerLabel: '月份2',
            align: 'center',
            formatCell: (value) => {
                if (!value) return '無';
                return value;
            },
        },
        {
            cellKey: 'measureTypeResult',
            headerLabel: () => {
                return <MeasureTypeResultLabel />;
            },
            align: 'center',
            formatCell: (value) => {
                if (typeof value !== 'number' && !value) return '無';
                return value;
            },
        },
    ];
};
