/**
 * 獲取總計行的配置，並通過 overviewList 的 table 組件的 props 中的 bodyRowClass 屬性應用到總計行樣式。
 *
 * @returns {Object} config - 總計行的配置對象
 * @property {string} key - 元件 Tablenization 的 bodyRowClass 屬性
 * @property {boolean} value - 元件 Tablenization 的 bodyRowClass 屬性
 * @property {string} className - 元件 Tablenization 的 bodyRowClass 屬性
 *
 */
export const getSummaryRowConfig = () => {
    return {
        key: 'isSummaryRow', // 標記行為總計行的鍵
        value: true, // 總計行固定的識別值
        className: 'summary-row', // 標示總計行樣式的 CSS 類名
    };
};
