import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { Alert, Box, Chip, Pagination, Stack } from '@common/components/';
import { InfoCircle as InfoCircleIcon } from '@common/SvgIcon/';
import { MoreLabelBox } from '@icoach/components';
import { formatCurrencyFn, objectToQueryString } from '@util/utils';
import { displayDateText, parseDate } from '@util/moment';
import useCabinetRentalApi from '../../../apis/useCabinetRentalApi';
import {
    cabinetPaymentCycleMap,
    cabinetRentalStatusMap,
    getRentCabinetMoreActionArray,
    initBookmark,
    RENT_CABINET_STATUS_COLOR_MATCH,
} from '@icoach/rentCabinet/staticData';
import { RENT_CABINET_DOCUMENT_CREATE_URL, RENT_CABINET_DOCUMENT_ID_URL, RENT_CABINET_DOCUMENT_PREVIEW_URL } from '@icoach/router/MembersRouter';
import locker_png from '../../assets/img/locker.png';
import RentCabinetDialogBox from '@icoach/rentCabinet/dialog/RentCabinetDialogBox';
import InteractiveContentWrapper from '@icoach/components/InteractiveContentWrapper';

const RentCabinetMoreLabel = (props) => {
    const { data = {}, dialogRef, refresh } = props;
    const { cabinetRentalID } = data;
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => RENT_CABINET_DOCUMENT_PREVIEW_URL(cabinetRentalID),
        },
        edit: {
            text: '編輯',
            to: () => RENT_CABINET_DOCUMENT_ID_URL(cabinetRentalID),
        },
        renew: {
            text: '續約',
            to: () => RENT_CABINET_DOCUMENT_CREATE_URL + objectToQueryString({ renewCabinetRentalID: cabinetRentalID }),
        },
        quit: {
            text: '退租申請',
            onClickEvent: () => dialogRef.current && dialogRef.current.openQuitRentCabinetDialog({ cabinetRentalID }, refresh),
        },
        deleteQuit: {
            text: '刪除退租申請',
            onClickEvent: () => dialogRef.current && dialogRef.current.openDeleteQuitRentCabinetDialog({ cabinetRentalID }, refresh),
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} />;
};

const RentCabinetCard = (props) => {
    const { className, data = {}, dialogRef, refresh } = props;
    const {
        cabinetRentalNo = 'A000C0017',
        cabinetRentalStatus = 1,
        cabinetRentalStatusText = '有效',
        cabinetName = '置物櫃名稱',
        memo = '置物櫃備註',
        monthlyFee = 400,
        firstMonthFee,
        rentalMemo = '租櫃備註',
        keyStatusText = '店舖代管',
        startDate = parseDate(),
        endDate,
        paymentCycle,
        paymentCycleText = '每月扣款',
    } = data;

    const Title = ({ cabinetName }) => <Box className={'title mr-1'}>{cabinetName}</Box>;
    return (
        <Box className={clsx('rent-cabinet-card', { quit: cabinetRentalStatus === cabinetRentalStatusMap.quit }, className)}>
            <RentCabinetMoreLabel moreActionArray={getRentCabinetMoreActionArray(data)} dialogRef={dialogRef} data={data} refresh={refresh} />
            <Box className={'rent-cabinet-card-top'}>
                <Stack spacing={2}>
                    <Stack direction={'column'} alignItems={'center'}>
                        <Box component={'img'} src={locker_png} />
                        <Chip
                            className={'rent-cabinet-card-state'}
                            label={cabinetRentalStatusText}
                            color={RENT_CABINET_STATUS_COLOR_MATCH.get(cabinetRentalStatus)}
                        />
                    </Stack>
                    <Box className={'w-100'}>
                        <Box className={'title-wrap'}>
                            {Boolean(memo) ? (
                                <InteractiveContentWrapper
                                    label={<Title cabinetName={cabinetName} />}
                                    tip={memo}
                                    icon={<InfoCircleIcon />}
                                    iconSize={'25px'}
                                    alignItems={'start'}
                                />
                            ) : (
                                <Title cabinetName={cabinetName} />
                            )}
                            {String(paymentCycle) === cabinetPaymentCycleMap.payOff ? (
                                <Box component={'h4'} className={'price'}>
                                    <Box component={'span'}>{formatCurrencyFn(firstMonthFee, { symbol: '$' })}</Box>
                                </Box>
                            ) : (
                                <Box component={'h4'} className={'price'}>
                                    <Box component={'span'}>{formatCurrencyFn(monthlyFee, { symbol: '$' })}</Box>
                                    <Box component={'span'} className={'unit'}>
                                        /月
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box className={'desc'}>{rentalMemo}</Box>
                    </Box>
                </Stack>
            </Box>
            <Box className={'rent-cabinet-card-bottom'}>
                <Grid spacing={3} container>
                    <Grid xs={6} xl={3} item>
                        <Box className={'info-item'}>
                            <Box component={'h6'}>租約編號</Box>
                            <Box component={'p'}>{cabinetRentalNo}</Box>
                        </Box>
                    </Grid>
                    <Grid xs={3} xl={3} item>
                        <Box className={'info-item'}>
                            <Box component={'h6'}>付款週期</Box>
                            <Box component={'p'}>{paymentCycleText}</Box>
                        </Box>
                    </Grid>
                    <Grid xs={3} xl={3} item>
                        <Box className={'info-item'}>
                            <Box component={'h6'}>鑰匙</Box>
                            <Box component={'p'}>{keyStatusText}</Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} xl={3} item>
                        <Box className={'info-item'}>
                            <Box component={'h6'}>租約期間</Box>
                            <Box component={'p'}>{displayDateText(startDate, endDate)}</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
const MemberDetailRentCabinetContentDocument = (props) => {
    const { memberID } = props;
    const { postRentCabinetListApi } = useCabinetRentalApi();
    const [bookmark, setBookmark] = useState(initBookmark);
    const [tableData, setTableData] = useState({});
    const { list = [], totalPage, pageIndex = bookmark.pageIndex } = tableData;
    const preload = useRef(false);
    const dialogRef = useRef(null);

    const doRentCabinetListApi = async (params) => {
        let res = await postRentCabinetListApi(params);
        if (res) {
            setTableData(res);
        }
        preload.current = true;
    };

    const getParams = (targetPage) => {
        let result = {
            cabinetRentalStatus: cabinetRentalStatusMap.all,
        };

        if (!isEmpty(targetPage)) {
            result = Object.assign(result, targetPage);
        }

        if (memberID) {
            result = Object.assign(result, { memberID });
        }

        return result;
    };
    const setBookmarkNum = (num) => setBookmark((pre) => ({ ...pre, pageIndex: num }));
    const handlePageChange = (e, num) => setBookmarkNum(num);

    const refreshFn = () => {
        doRentCabinetListApi(getParams(bookmark));
    };

    useEffect(() => {
        if (preload.current) {
            doRentCabinetListApi(getParams(bookmark));
        } else {
            preload.current = true;
        }
        // eslint-disable-next-line
    }, [bookmark.pageIndex]);

    useEffect(() => {
        doRentCabinetListApi(getParams(bookmark));
        // eslint-disable-next-line
    }, []);

    return (
        <Box>
            {!isEmpty(list) ? (
                <React.Fragment>
                    {list.map((item) => {
                        return <RentCabinetCard key={item.cabinetRentalID} className={'mb-4'} data={item} dialogRef={dialogRef} refresh={refreshFn} />;
                    })}
                    <Stack direction="row-reverse" className="mt-4">
                        <Pagination count={totalPage} page={pageIndex} color="secondary" shape="rounded" onChange={handlePageChange} />
                    </Stack>
                </React.Fragment>
            ) : (
                <Alert className={'mb-4'} color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
            <RentCabinetDialogBox ref={dialogRef} />
        </Box>
    );
};

export default MemberDetailRentCabinetContentDocument;
