import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Card, Button, Form, TextField, DateIntervalField } from '@common/components/';
import { MenuItem, SelectField } from '@common/components/';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';
import { MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL } from '@icoach/router/MembersRouter';
import { useGiftContext } from '@icoach/gift/components/GiftContext';
import { initBookmark } from '@icoach/gift/staticData';
import useGiftItemRecordApi from '@apis/useGiftItemRecordApi';

const GiftDispatchRecordSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { setSourceData, setSortOption, bookmark, setBookmarkNum, setRefresh } = useGiftContext();
    const { getGiftItemRecordOptionsApi, getGiftItemRecordOverviewApi } = useGiftItemRecordApi();
    const [paramsData, setParamsData] = useState({});
    const [options, setOptions] = useState({});
    const { giftActivityOption = [], giftClaimStatusOption = [] } = options;
    const memberRef = useRef(null);
    const giftNameRef = useRef(null);
    const eventNameRef = useRef(null);
    const periodRef = useRef(null);
    const claimStatusRef = useRef(null);
    const preloadRef = useRef(false);

    const getParams = (bookmark) => {
        const result = {};

        if (bookmark) {
            Object.assign(result, bookmark);
        }

        if (memberRef.current && memberRef.current.getResult) {
            Object.assign(result, { member: memberRef.current.getResult() });
        }

        if (giftNameRef.current && giftNameRef.current.getResult) {
            Object.assign(result, { giftName: giftNameRef.current.getResult() });
        }

        if (eventNameRef.current && eventNameRef.current.getResult) {
            Object.assign(result, { activityID: eventNameRef.current.getResult() });
        }

        if (periodRef.current && periodRef.current.getResult) {
            Object.assign(result, periodRef.current.getResult());
        }

        if (claimStatusRef.current && claimStatusRef.current.getResult) {
            Object.assign(result, { claimStatus: claimStatusRef.current.getResult() });
        }

        return result;
    };

    /** 更新網址上的搜尋條件 **/
    const updateUrlWithSearchQuery = (params) => {
        let url = MEMBERS_TABS_GIFT_DISPATCH_RECORD_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOverviewApi = async (params) => {
        let resp = await getGiftItemRecordOverviewApi(params);
        if (resp) {
            let { list, pageIndex, ...other } = resp;
            let props;
            if (pageIndex === 1) {
                props = resp;
            } else {
                props = (prev) => {
                    const prevList = prev.list;
                    let targetList = list;

                    if (Array.isArray(prevList)) {
                        targetList = prevList.concat(targetList);
                    }

                    return {
                        list: targetList,
                        pageIndex,
                        ...other,
                    };
                };
            }
            setSourceData(props);
        }
        updateUrlWithSearchQuery(params);
    };

    const getOptionsApi = async () => {
        const params = {
            isAll: true,
        };
        let resp = await getGiftItemRecordOptionsApi(params);
        if (resp) {
            const { orderByOption } = resp;
            setOptions(resp);
            setSortOption(orderByOption);
            const { location } = history;
            const search = location.search;
            if (search) {
                const params = paramsToObject(search);
                if (!isEmpty(params)) {
                    setParamsData(params);
                }
                getOverviewApi(params);
            }

        }
    };

    const handleSearchOnClick = () => {
        const params = getParams(initBookmark(1));
        getOverviewApi(params);
        setBookmarkNum(1);
    };

    useEffect(
        () => {
            if (!preloadRef.current) return;
            if (String(bookmark.pageIndex) === '1') return;
            const params = getParams(bookmark);
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(
        () => {
            if (!preloadRef.current) return;
            const params = getParams(bookmark);
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.sortBy],
    );

    useEffect(() => {
        if (preloadRef.current) return;
        preloadRef.current = true;
        getOptionsApi();
        setRefresh(handleSearchOnClick);

        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar-light p-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchOnClick}>
                    <Grid spacing={2} container>
                        <Grid xs={4} item>
                            <TextField
                                key={paramsData.member}
                                label={'受贈人'}
                                ref={memberRef}
                                defaultValue={paramsData.member}
                                InputProps={{ placeholder: '輸入會員姓名或編號' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <TextField
                                key={paramsData.giftName}
                                label={'贈品名稱'}
                                ref={giftNameRef}
                                defaultValue={paramsData.giftName}
                                InputProps={{ placeholder: '輸入贈品名稱' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField
                                key={paramsData.activityID || 99}
                                label={'活動名稱'}
                                ref={eventNameRef}
                                defaultValue={paramsData.activityID || 99}
                                fullWidth
                                required
                            >
                                {giftActivityOption.map(({ value, text }) => (
                                    <MenuItem key={value} value={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={8} item>
                            <DateIntervalField
                                key={`${paramsData.startDate}-${paramsData.endDate}`}
                                ref={periodRef}
                                label={'發送期間'}
                                symbol={'~'}
                                startDateProps={{
                                    defaultValue: paramsData.startDate,
                                }}
                                endDateProps={{
                                    defaultValue: paramsData.endDate,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField
                                key={paramsData.claimStatus || 99}
                                ref={claimStatusRef}
                                label={'領取狀態'}
                                defaultValue={paramsData.claimStatus || 99}
                                fullWidth
                                required
                            >
                                {giftClaimStatusOption.map(({ value, text }) => (
                                    <MenuItem key={value} value={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid className={'text-right'} xs={12} item>
                            <Button className={'white-space-nowrap'} type={'submit'} variant={'contained'}>
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default GiftDispatchRecordSearchBar;
