// 進貨單狀態 1 訂單成立, 2 付款處理中, 3 待驗收, 4 開立發票中, 5 訂單完成, 8 訂單取消, 9 訂單異常, 10 拆單
export const purchaseReceiptOrderStatusColorMap = new Map([
    [1, 'info'], //
    [2, 'info'],
    [3, 'info'],
    [4, 'info'],
    [5, 'success'],
    [8, 'grey'],
    [9, 'danger'],
    [10, 'info'],
]);

export const initBookmark = (sortBy) => ({
    pageIndex: 1,
    pageSize: 50,
    sortBy: sortBy || 1,
});
